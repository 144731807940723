import ConfigProvider from "antd/lib/config-provider";
import Table, { TableProps } from "antd/lib/table";
import cc from "classcat";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Category, CategoryContext } from "../../../context/CategoryProvider";
import { Keyword, KeywordContext } from "../../../context/KeywordProvider";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import { FormTable } from "../../../utils/types";
import RetailSearchBar from "../../Bar/RetailSearchBar";
import Empty from "../../Empty";
import CategorySelect from "../../Select/CategorySelect";
import RetailCategorySuggestionTable from "./RetailCategorySuggestionTable";
import RetailKeywordSuggestionTable from "./RetailKeywordSuggestionTable";
import cm from "./style.module.scss";
import RetailPaginationButtons from "../../Button/RetailPaginationButtons";
import useCampaignFetch from "../../../hooks/useCampaignFetch";

export interface RetailFormTableProps extends TableProps<any> {
  type: FormTable;
  error: boolean | undefined;
  suggest?: boolean;
  alreadySelectedData?: any;
}

const RetailFormTable = ({
  error,
  suggest = false,
  alreadySelectedData,
  type,
  ...tableProps
}: RetailFormTableProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  const [visible, setVisible] = useState(false);

  const { data } = useCampaignFetch();

  const { options, search, setSearch, handleScroll } = useInfiniteScroll(
    "categories",
    type.includes("CATEGORIES"),
    true,
    data?.data?.ad_type
  );

  const {
    keywords,
    handleKeywordAdd,
    negativeKeywords,
    handleNegativeKeywordAdd,
  } = useContext(KeywordContext) as Keyword;

  const {
    categories,
    handleCategoryAdd,
    negativeCategories,
    handleNegativeCategoryAdd,
  } = useContext(CategoryContext) as Category;

  const switchData = () => {
    switch (type) {
      case "KEYWORDS":
        return keywords;
      case "NEGATIVE_KEYWORDS":
        return negativeKeywords;
      case "CATEGORIES":
        return categories;
      case "NEGATIVE_CATEGORIES":
        return negativeCategories;
      default:
        break;
    }
  };

  const switchAddFunctions = () => {
    switch (type) {
      case "KEYWORDS":
        return handleKeywordAdd(value, data?.data?.ad_type);
      case "NEGATIVE_KEYWORDS":
        return handleNegativeKeywordAdd(value, data?.data?.ad_type);
      case "CATEGORIES":
        return handleCategoryAdd(value);
      case "NEGATIVE_CATEGORIES":
        return handleNegativeCategoryAdd(value);
      default:
        break;
    }
  };

  const add = async () => {
    switchAddFunctions();
    setValue("");
  };

  const customizeRenderEmpty = () => (
    <Empty type={type.toLowerCase()} isError={error} />
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (type.toLowerCase().includes("keyword")) setValue(target.value);
    else setSearch(target.value);
  };

  const openCategorySelect = () => setVisible(true);

  const containerVisibility =
    visible && type.toLowerCase().includes("categories");

  const switchSuggestions = () => {
    switch (type) {
      case "KEYWORDS":
        return <RetailKeywordSuggestionTable />;
      case "CATEGORIES":
        return <RetailCategorySuggestionTable />;
      default:
        break;
    }
  };

  const close = () => {
    setSearch("");
    setVisible(false);
  };

  const categorySelectData = {
    options: options,
    type: type,
    close: close,
    handleScroll: handleScroll,
  };

  return (
    <>
      <div className={cc([cm.container, cm.fullWidth])}>
        <RetailSearchBar
          prefix={false}
          placeholder={t(`components.searchBar.${type.toLowerCase()}`)}
          shadow
          addonAfter={!type.toLowerCase().includes("categories")}
          handleClick={add}
          value={type.toLowerCase().includes("keyword") ? value : search}
          onChange={handleChange}
          dataTest={`campaign-form-${type}-input-add`}
          onClick={openCategorySelect}
        />
        {containerVisibility && (
          <CategorySelect
            categoryData={categorySelectData}
            alreadySelectedData={alreadySelectedData}
          />
        )}
      </div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          dataSource={switchData()}
          data-cy="category-table"
          rowKey={(records) => records.id}
          className={cc([
            cm.keyTable,
            switchData() !== undefined && switchData().length > 1
              ? cm.more
              : "",
          ])}
          pagination={{
            pageSize: 4,
            itemRender(page, type, element) {
              return <RetailPaginationButtons type={type} element={element} />;
            },
          }}
          {...tableProps}
        />
      </ConfigProvider>
      {suggest && switchSuggestions()}
    </>
  );
};

export default RetailFormTable;
