import { ReactComponent as BarsFilled } from "../../../../../assets/icons/menu/barsFilled.svg";
import { ReactComponent as BarsOutlined } from "../../../../../assets/icons/menu/barsOutlined.svg";
import { ReactComponent as CampaignFilled } from "../../../../../assets/icons/menu/campaignFilled.svg";
import { ReactComponent as CampaignOutlined } from "../../../../../assets/icons/menu/campaignOutlined.svg";
import { ReactComponent as FileFilled } from "../../../../../assets/icons/menu/fileFilled.svg";
import { ReactComponent as FileOutlined } from "../../../../../assets/icons/menu/fileOutlined.svg";
import { ReactComponent as GradFilled } from "../../../../../assets/icons/menu/gradFilled.svg";
import { ReactComponent as GradOutlined } from "../../../../../assets/icons/menu/gradOutlined.svg";

export const retailUserRouteObject = [
  {
    link: "/campaigns",
    key: "campaign",
    iconFilled: <CampaignFilled />,
    iconOutlined: <CampaignOutlined />
  },
  {
    link: "/reports",
    key: "report",
    iconFilled: <BarsFilled />,
    iconOutlined: <BarsOutlined />
  },
  {
    link: "/invoices",
    key: "invoices",
    iconFilled: <FileFilled />,
    iconOutlined: <FileOutlined />
  },
  {
    link: "/academy",
    key: "academy",
    iconFilled: <GradFilled />,
    iconOutlined: <GradOutlined />
  },
]