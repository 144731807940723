import Form, { FormItemProps } from "antd/lib/form";
import cc from "classcat";
import React from "react";

import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailFormItemProps extends FormItemProps {
  isFocused: boolean;
  subText?: string;
}

const RetailFormInput: React.FC<RetailFormItemProps> = ({
  children,
  isFocused,
  subText,
  className,
  validateStatus,
  ...formItemProps
}) => {
  return (
    <>
    <Form.Item
      className={cc([isFocused ? cm.labelUp : cm.label, className || ""])}
      validateStatus={validateStatus}
      colon={false}
      {...formItemProps}
    >
      {children}
    </Form.Item>
    {subText && (
      <RetailText size="xxs" family="poppins" className={validateStatus === "error" ? cm.errorText: cm.normalText}>{subText}</RetailText>
    )}
    </>
  );
};

export default RetailFormInput;
