import Spin from "antd/lib/spin";
import cc from "classcat";

import cm from "./style.module.scss";

export interface RetailSettingsLayoutProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  spinning?: boolean;
}

const RetailSettingsLayout = ({
  children,
  spinning = false,
  className,
  ...htmlDivElement
}: RetailSettingsLayoutProps) => {
  return (
    <div
      className={cc([cm.pageContainer, className || ""])}
      {...htmlDivElement}
    >
      <Spin spinning={spinning} wrapperClassName={cm.container}>
        {children}
      </Spin>
    </div>
  );
};

export default RetailSettingsLayout;
