import { useTranslation } from "react-i18next";
import cm from "./style.module.scss";

interface RetailDataSchemaItemProps {
  selected: string;
  item: { field: string; type: string; required: boolean };
  mode?: "form" | "drawer";
}

const RetailDataSchemaItem = ({
  selected,
  item,
  mode = "drawer",
}: RetailDataSchemaItemProps) => {
  const { t } = useTranslation();

  const languageKey = `components.datasource.${selected}Schema.${item.field}`;

  const { field, type, required } = item;

  return (
    <div className={`${cm.fieldItemContainer} ${cm[mode]}`}>
      <p>
        <span className={cm.field}>{field}</span>
        <span className={cm.type}>{type}</span>
        {required && (
          <span className={cm.required}>
            {t("components.datasource.required")}
          </span>
        )}
      </p>
      <p className={cm.description}>{t(languageKey)}</p>
    </div>
  );
};

export default RetailDataSchemaItem;
