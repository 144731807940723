import { useTranslation } from "react-i18next";

import { ReactComponent as BlockFilled } from "../../../assets/icons/blockFilled.svg";
import { prefix } from "../../../utils/helpers";
import RetailTooltip from "../RetailTooltip";

export interface RetailBidTooltipProps {
  value: number;
  type: "general" | "number" | "percent";
}

const RetailBidTooltip = ({ value, type }: RetailBidTooltipProps) => {
  const { t } = useTranslation();

  const title = () => {
    switch (type) {
      case "general":
        return t("components.table.keywords.warning", {
          value: `${prefix()}${value}`,
        });
      case "number":
        return t("pages.admin.campaignSettings.inputErrorZero");
      case "percent":
        return t("pages.admin.campaignSettings.inputErrorAcos");
      default:
        break;
    }
  };
  return (
    <RetailTooltip title={title()} color="var(--red-5)">
      <BlockFilled />
    </RetailTooltip>
  );
};

export default RetailBidTooltip;
