import TeknosaLandingFooter from "../TeknosaLayout/TeknosaLandingFooter";
import TeknosaLandingNavbar from "../TeknosaLayout/TeknosaLandingNavbar";
import TeknosaLandingHeader from "./TeknosaLandingHeader";
import TeknosaLandingInfo from "./TeknosaLandingInfo";
import TeknosaLandingMid from "./TeknosaLandingMid";
import TeknosaLandingTarget from "./TeknosaLandingTarget";

const TeknosaLanding = () => {
  return (
    <>
      <TeknosaLandingNavbar />
      <TeknosaLandingHeader />
      <main>
        <TeknosaLandingMid />
        <TeknosaLandingInfo />
        <TeknosaLandingTarget />
      </main>
      <TeknosaLandingFooter />
    </>
  );
};

export default TeknosaLanding;
