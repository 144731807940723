import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { Product, ProductContext } from "../../../../context/ProductProvider";
import { productFilters } from "../../../../utils/filters";
import useApi from "../../../../api";

import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStateColumn from "../../../../components/Column/RetailStateColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import RetailTable from "../../../../components/Table/RetailTable";
import SelectProductsTable from "../../../../components/Table/SelectProductsTable";
import RetailNotification from "../../../../components/Notification";

export interface ProductTableProps {
  ad_type: "PRODUCT" | "STORE" | "DISPLAY";
}

const ProductTable = ({ ad_type }: ProductTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const [isVisible, setIsVisible] = useState(false);

  const { selectedProducts, handleDeleteAllProducts } = useContext(
    ProductContext
  ) as Product;

  const queryClient = useQueryClient();

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType="PRODUCTS"
          />
        );
      case "state":
        return <RetailStateColumn value={value} type="products" />;
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
      case "direct_sale":
      case "indirect_sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
      case "CVR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
      case "direct_sale_amount":
      case "indirect_sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      case "buybox":
        return value
          ? t("pages.acc.campaignDetails.winner")
          : t("pages.acc.campaignDetails.loser");
      default:
        return value ? value : "-";
    }
  };

  const reset = () => {
    setIsVisible(false);
    handleDeleteAllProducts();
  };

  const updateValue = async () => {
    const config = {
      products: selectedProducts.map((product) => product.id),
    };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addProduct = async () => {
    setIsVisible(false);
    try {
      await mutateAsync();
      await new Promise((resolve) => setTimeout(resolve, 250));
      await queryClient.refetchQueries("table");
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    } finally {
      handleDeleteAllProducts();
    }
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "PRODUCTS",
    filters: productFilters(t),
    renderColumns,
  };

  return (
    <>
      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.productsBtn"),
          onClick: () => setIsVisible(true),
        }}
        placeholder={t("pages.acc.campaignDetails.productsPlaceholder")}
        tableConfig={tableConfig}
      />
      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t("pages.acc.campaignDetails.productsSub")}
        onCancel={reset}
        onOk={addProduct}
        type="PRODUCTS"
      >
        <SelectProductsTable ad_type={ad_type} fetchAll={true} />
      </CampaignDetailsModal>
    </>
  );
};

export default ProductTable;
