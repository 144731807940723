import cc from "classcat";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import sanityClient from "../../../client";
import { marketplaceName } from "../../../utils/defaults";
import RetailAdvertiserViewLink from "../../Link/RetailAdvertiserViewLink";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailAcademyHeaderProps {
  data?: { title: string; description: string };
}

const RetailAcademyHeader = ({ data }: RetailAcademyHeaderProps) => {
  const { t, i18n } = useTranslation();

  const [highlightedPost, setHighlightedPost] = useState<any | null>(null);

  const fetchPost = async () => {
    sanityClient
      .fetch(
        `*[highlighted == true && language == "${i18n.language}"]{
          title,
          description,
          slug,
          categories[]->{
             slug }
      }`
      )
      .then((data) => setHighlightedPost(data[0]))
      .catch(console.error);
  };

  useEffect(() => {
    data === undefined ? fetchPost() : setHighlightedPost([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <header className={cc([cm.header, data ? cm.padding : ""])}>
      <section>
        <article className={cc(["flex"])}>
          {data?.title ? (
            <RetailTitle level={3} className={cm.title}>
              {data.title}
            </RetailTitle>
          ) : (
            <>
              <RetailTitle level={3} className={cm.title}>
                {t("sider.user.academy")}
              </RetailTitle>
              {/* <RetailText className={cm.academy} size="lg" weight="bold">
                {t("sider.user.academy")}
              </RetailText> */}
            </>
          )}
        </article>
        <RetailText size="xs" weight="medium" className={cm.headerText}>
          {data?.description ||
            t("pages.acc.academy.description", { value: marketplaceName })}
        </RetailText>
      </section>
      {data === undefined && highlightedPost !== null && (
        <div className={cm.postContainer}>
          <h3>{t("pages.acc.academy.highlights")}</h3>
          <h4>{highlightedPost?.title}</h4>
          <RetailText family="poppins" size="xxxs" className={cm.description}>
            {highlightedPost?.description}
          </RetailText>
          <RetailAdvertiserViewLink
            to={`/academy/blog/${highlightedPost?.categories[0].slug.current}/${highlightedPost?.slug.current}`}
            state={{
              breadcrumb: true,
              breadcrumbType: "academy",
              value: {
                value: highlightedPost?.title,
                category: highlightedPost?.categories[0].slug.current,
              },
            }}
          >
            {t("pages.acc.academy.read")}
          </RetailAdvertiserViewLink>
        </div>
      )}
    </header>
  );
};

export default RetailAcademyHeader;
