import {
	Form,
	Radio,
} from "antd";
import Input from "antd/lib/input/Input";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { validateNumber } from "../../../../../utils/helpers";
import RetailSuggestedTag from "../../../../Tag/RetailSuggestedTag";
import RetailText from "../../../../Typography/RetailText";
import RetailTitle from "../../../../Typography/RetailTitle";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormInput from "../../../RetailFormInput";
import RetailFormRow from "../../../RetailFormRow";
import cm from "./style.module.scss";

export interface SpendingDistributionProps {
  fields: {
    spending_type: string;
    front_loaded_percentage: string;
  };
  // Not needed rn, but can be useful in the future
  error: boolean;
  handleFieldChange(e: any): void;
}

const SpendingDistribution = ({
  fields: { spending_type, front_loaded_percentage },
  handleFieldChange,
}: SpendingDistributionProps) => {
  const { t } = useTranslation();

  const isError =
    spending_type === "FRONT_LOADED" && front_loaded_percentage === "";

  return (
    <RetailFormRow className={cc(["form-row"])}>
      <RetailFormInfo
        column={{
          number: "4",
          title: t("components.campaignForm.firstStep.spendingTitle"),
          text: t("components.campaignForm.firstStep.spendingText"),
        }}
      />

      <Form.Item name="spending_type" className="flex reversed">
        <Radio.Group name="spending_type" onChange={handleFieldChange}>
          <Radio value="EVENLY" className={cc(["form-radio", cm.radio])}>
            <RetailTitle level={5} className={cm.title} noMargin>
              {t("components.campaignForm.firstStep.evenly")}
              <RetailSuggestedTag className={cm.suggestedTag} />
            </RetailTitle>
            <RetailText size="xxxs" weight="medium" className={cm.text}>
              {t("components.campaignForm.firstStep.evenlyText")}
            </RetailText>
          </Radio>
          <Radio value="ASAP" className={cc(["form-radio", cm.radio])}>
            <RetailTitle level={5} noMargin>
              {t("components.campaignForm.firstStep.asap")}
            </RetailTitle>
            <RetailText size="xxxs" weight="medium" className={cm.text}>
              {t("components.campaignForm.firstStep.asapText")}
            </RetailText>
          </Radio>
          <Radio
            value="FRONT_LOADED"
            className={cc(["form-radio", cm.radio, isError ? cm.error : ""])}
          >
            <RetailTitle level={5} noMargin>
              {t("components.campaignForm.firstStep.front_loaded")}
            </RetailTitle>
            <RetailText size="xxxs" weight="medium" className={cm.text}>
              {t("components.campaignForm.firstStep.front_loadedText")}
            </RetailText>
            {spending_type === "FRONT_LOADED" && (
              <RetailFormInput
                isFocused={front_loaded_percentage !== ""}
                label={t("components.campaignForm.firstStep.frontPercentage")}
                name="front_loaded_percentage"
                rules={[
                  {
                    required: true,
                    pattern: /^([1-9]|[1-9][0-9]|100)$/,
                    message: t(
                      "components.campaignForm.firstStep.errorStates.frontPercentage"
                    ),
                  },
                ]}
                className={cc(["floating", cm.spendingInput])}
              >
                <Input
                  className={cc([
                    "number-input floating",
                    front_loaded_percentage === ""
                      ? cm.hidePrefix
                      : cm.showPrefix,
                  ])}
                  prefix="%"
                  name="front_loaded_percentage"
                  value={front_loaded_percentage}
                  onChange={handleFieldChange}
                  onKeyDownCapture={(e) => validateNumber(e)}
                />
              </RetailFormInput>
            )}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </RetailFormRow>
  );
};

export default SpendingDistribution;
