const koctas_en = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "You can set your targeting strategy for your campaign.",
    campaign_form_budget_text:
      "Select the budget type of the campaign and set the budget. Once your spend reaches the budget, the campaign will automatically stop.",
    campaign_form_creative_text:
      "Advertising visuals are prepared by our expert design team. All materials will be provided by Koçtaş. The visuals will be prepared and delivered to you within 3 business days. You are required to use only the visuals we provide in your campaigns. If you use a different visual, your advertisement will not be published.",
    invoice_modal_help_text:
      "If you continue by clicking the Choose a payment method button, you are deemed to have confirmed that the amount to be collected will not be refunded.",
    invoice_general_error_modal_title: "Failed to Upload Balance!",
    invoice_general_error_modal_text:
      "Your account doesn't have enough balance.",
  },
};

export default koctas_en;
