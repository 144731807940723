import { Input } from "antd";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormInput from "../../../RetailFormInput";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

interface RedirectURLInputProps {
  targeting: string;
  redirect_url: string;
  redirectURLError: boolean;
  handleFieldChange(e: any): void;
}

const RedirectURLInput = ({
  targeting,
  redirect_url,
  redirectURLError,
  handleFieldChange,
}: RedirectURLInputProps) => {
  const { t } = useTranslation();
  return (
    <RetailFormRow className={cc(["form-row"])}>
      <RetailFormInfo
        column={{
          number: targeting === "AUTO_TARGETING" ? "8" : "9",
          title: t("components.campaignForm.firstStep.urlTitle"),
          text: t("components.campaignForm.firstStep.urlText"),
        }}
      />

      <RetailFormInput
        isFocused={redirect_url !== ""}
        name="url"
        label={t("components.campaignForm.firstStep.urlTitle")}
        rules={[
          {
            required: true,
            message: t("components.campaignForm.firstStep.urlError"),
            pattern:
              /^(https?:\/\/)?([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/,
          },
        ]}
        className="floating"
      >
        <Input.TextArea
          className={cc([
            "floating",
            cm.textarea,
            redirectURLError && redirect_url === "" ? cm.errorBorder : "",
          ])}
          data-test="campaign-form-redirect-url"
          onChange={handleFieldChange}
          name="redirect_url"
        />
      </RetailFormInput>
    </RetailFormRow>
  );
};

export default RedirectURLInput;
