import { useTranslation } from "react-i18next";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import Modal from "antd/lib/modal";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailErrorModalProps {
  type: "invoice" | "craftgate" | "file" | "xml";
  visible: boolean;
  setVisible(visible: boolean): void;
  onClick?(): void;
  selectedPaymentType?: string;
  errorMsg?: string | null;
}

const RetailErrorModal = ({
  type,
  visible,
  setVisible,
  onClick,
  selectedPaymentType,
  errorMsg,
}: RetailErrorModalProps) => {
  const { t } = useTranslation();

  const close = () => {
    setVisible(false);
    onClick && onClick();
  };

  const generateTotersErrorMsg = () => {
    if (errorMsg?.includes("product id")) return "product_id";
    if (errorMsg?.includes("signature verification failed")) return "signature";
    if (errorMsg?.includes("the transaction id")) return "transaction";
    if (errorMsg?.includes("store does not belong")) return "store";
    if (errorMsg?.includes("enough balance")) return "balance";
    else return "unknown";
  };

  const generateErrorMsg = () => {
    switch (selectedPaymentType) {
      case "toters_milestone_payment":
        return generateTotersErrorMsg();
      default:
        return "general";
    }
  };

  const errorTitle = () => {
    if (type === "craftgate")
      return t("components.modal.error.craftgate_title");
    if (type === "file") return t("components.modal.error.file_title");
    if (type === "xml") return t("components.modal.error.xml_title");
    else return t(`marketplaceDependent.${type}_general_error_modal_title`);
  };

  const errorText = () => {
    if (type === "craftgate") return t("components.modal.error.craftgate_text");
    if (type === "file") return t("components.modal.error.file_text");
    if (type === "xml") return t("components.modal.error.xml_text");
    else
      return t(
        `marketplaceDependent.${type}_${generateErrorMsg()}_error_modal_text`
      );
  };

  return (
    <Modal
      className={cm.errorModal}
      visible={visible}
      closable={false}
      centered
      footer={[
        <RetailMainButton
          hasBackground={true}
          onClick={close}
          className={cm.ok}
        >
          {t("common.okay")}
        </RetailMainButton>,
      ]}
    >
      <section className={cm.errorWrapper}>
        <div className={cc(["flex", cm.errorIcon])}>
          <CloseOutlined />
        </div>
        <RetailTitle className={cm.errorTitle} noMargin>
          {errorTitle()}
        </RetailTitle>
        <RetailText size="xs" weight="medium" className={cm.errorText}>
          {errorText()}
        </RetailText>
      </section>
    </Modal>
  );
};

export default RetailErrorModal;
