import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Radio, RadioChangeEvent } from "antd";
import { EntityStates } from "../../../../utils/types";
import { ReactComponent as PlayButtonOutlined } from "../../../../assets/icons/playButtonOutlined.svg";
import useApi from "../../../../api";
import RetailDataSchemaItem from "./RetailDataSchemaItem";
import RetailDrawer from "../../../Drawer/RetailDrawer";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";

import cm from "./style.module.scss";
import CreativePreviewModal from "../../../Modal/CreativePreviewModal";

interface RetailEntityDrawersProps {
  entityStates: EntityStates;
  setEntityStates: React.Dispatch<React.SetStateAction<EntityStates>>;
  onOk: () => void;
  onClose: () => void;
  videoVisible: boolean;
  openVideo: () => void;
  closeVideo: () => void;
}

const RetailEntityDrawers = ({
  entityStates,
  setEntityStates,
  onOk,
  onClose,
  videoVisible,
  openVideo,
  closeVideo,
}: RetailEntityDrawersProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { data } = useQuery(
    "entities",
    () => {
      const data = api.get("data_sources/default_system_fields");
      return data;
    },
    {
      retry: false,
      enabled: entityStates.secondaryVisible,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const [entityFieldsForSecondDrawer, setEntityFieldsForSecondDrawer] =
    useState("product");

  const openSecondaryDrawer = (type: string) => {
    setEntityStates((prev) => ({ ...prev, secondaryVisible: true }));
    setEntityFieldsForSecondDrawer(type);
  };

  const openForProduct = () => openSecondaryDrawer("product");
  const openForAdvertiser = () => openSecondaryDrawer("advertiser");

  const closeSecondaryDrawer = () =>
    setEntityStates((prev) => ({ ...prev, secondaryVisible: false }));

  const handleFieldChange = (e: RadioChangeEvent) => {
    setEntityStates((prev) => ({ ...prev, selected: e.target.value }));
  };

  return (
    <>
      {/* Main Drawer */}
      <RetailDrawer
        title={t("components.datasource.selectEntity")}
        visible={entityStates.visible}
        onOk={onOk}
        onClose={onClose}
        disableOk={entityStates.selected === null}
        width={615}
      >
        <p className={cm.info}>
          {t("components.datasource.selectEntityDescription")}
        </p>

        <Radio.Group onChange={handleFieldChange} value={entityStates.selected}>
          <Radio value="product" className={`form-radio ${cm.radioLabel}`}>
            <section className={cm.radio}>
              <div className={cm.video} onClick={openVideo}>
                <div className={cm.videoBtn}>
                  <PlayButtonOutlined />
                  <RetailText size="xxxs" weight="medium">
                    {t("components.datasource.how")}
                  </RetailText>
                </div>
              </div>
              <div className={cm.content}>
                <RetailTitle level={5} noMargin>
                  {t("components.datasource.product")}
                </RetailTitle>
                <RetailText
                  size="xxxs"
                  weight="medium"
                  className={cm.contentText}
                >
                  {t("components.datasource.productText")}
                </RetailText>
                <RetailMainButton
                  onClick={openForProduct}
                  className={cm.contentBtn}
                >
                  {t("components.datasource.seeProductFields")}
                </RetailMainButton>
              </div>
            </section>
          </Radio>
          <Radio
            value="advertiser"
            className={`form-radio ${cm.radioLabel}`}
            disabled={true}
          >
            <section className={cm.radio}>
              <div className={cm.video}>
                <div className={cm.videoBtn}>
                  <PlayButtonOutlined />
                  <RetailText size="xxxs" weight="medium">
                    {t("components.datasource.how")}
                  </RetailText>
                </div>
              </div>
              <div className={cm.content}>
                <RetailTitle level={5} noMargin>
                  {t("components.datasource.advertiser")}
                  <span className={cm.soon}>
                    {t("components.datasource.soon")}
                  </span>
                </RetailTitle>
                <RetailText
                  size="xxxs"
                  weight="medium"
                  className={cm.contentText}
                >
                  {t("components.datasource.advertiserText")}
                </RetailText>
                <RetailMainButton
                  onClick={openForAdvertiser}
                  className={cm.contentBtn}
                >
                  {t("components.datasource.seeAdvertiserFields")}
                </RetailMainButton>
              </div>
            </section>
          </Radio>
        </Radio.Group>
      </RetailDrawer>

      {/* Secondary Field Drawer */}
      <RetailDrawer
        title={t(`components.datasource.${entityFieldsForSecondDrawer}Title`)}
        visible={entityStates.secondaryVisible}
        onOk={closeSecondaryDrawer}
        onClose={closeSecondaryDrawer}
        footer={null}
        width={520}
      >
        {data?.data[entityFieldsForSecondDrawer]?.map(
          (item: { field: string; type: string; required: boolean }) => (
            <RetailDataSchemaItem
              key={item.field}
              selected={entityFieldsForSecondDrawer}
              item={item}
            />
          )
        )}
      </RetailDrawer>
      <CreativePreviewModal
        type="video"
        url="https://gowit-feature-guide-videos.s3.eu-central-1.amazonaws.com/product_data.mp4"
        visible={videoVisible}
        onCancel={closeVideo}
      />
    </>
  );
};

export default RetailEntityDrawers;
