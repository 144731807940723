import {
	useMutation,
	useQueryClient,
} from "react-query";

import useApi from "../api";

export const useCampaignMutation = (parameter: string, id: any) => {
  const queryClient = useQueryClient();
  const { api } = useApi();

  const updateValue = async (value: any) => {
    const config = { [parameter]: value };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const { mutateAsync, isLoading } = useMutation(updateValue);

  const changeValue = async (value: any) => {
    await mutateAsync(value);
    queryClient.invalidateQueries("single_campaign", id);
  };
  return { changeValue, isLoading };
};
