import moment from "moment";
import { Link } from "react-router-dom";

import { ArrowRightOutlined } from "@ant-design/icons";

import cm from "./style.module.scss";

export interface TeknosaCaseStudyCardsProps {
  cards: any[];
}

const TeknosaCaseStudyCards = ({ cards }: TeknosaCaseStudyCardsProps) => {
  return (
    <section className={cm.container}>
      <div className={cm.textContainer}>
        <div>
          <h2>STORIES</h2>
          <h3>Başarı Hikayeleri</h3>
        </div>
        <p>
          Hedef kitlenizin özelliklerine ve ihtiyaçlarına göre
          kişiselleştirilmiş reklam kampanyalarını keşfedin.
        </p>
      </div>

      <div className={cm.cardContainer}>
        {cards?.map((card) => (
          <div className={cm.card}>
            <div className={cm.cardHeader}>
              <img src={card?.bg} alt="" />
              <div className={cm.cardHeaderText}>
                <p className={cm.cardHeaderTag}>CASE STUDY</p>
                <h4>{card?.title}</h4>
              </div>
            </div>

            <div className={cm.cardInfo}>
              <p className={cm.cardInfoBrand}>{card?.brand}</p>
              <p className={cm.cardInfoText}>
                {moment(card?.publishedAt).format("DD.MM.YYYY")}
              </p>
              <Link
                to={`case-study/${card?.slug?.current}`}
                className={cm.cardInfoLink}
              >
                GİT <ArrowRightOutlined />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TeknosaCaseStudyCards;
