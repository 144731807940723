import { useTranslation } from "react-i18next";

import useApi from "../../../api";
import { ReactComponent as BlockFilled } from "../../../assets/icons/blockFilled.svg";
import { ReactComponent as CopyOutlined } from "../../../assets/icons/copyOutlined.svg";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

const RetailPaymentInfoTag = () => {
  const { t } = useTranslation();

  const { id: advertiser_id } = useApi();

  const id = JSON.parse(localStorage.getItem("advertiser_id")!);

  const copyTextToClipboard = (item: string) =>
    navigator.clipboard.writeText(item);

  return (
    <div className={cm.container}>
      <BlockFilled className={cm.block} />
      <article>
        <div className="flex">
          <RetailText size="xs" weight="medium">
            {t("components.payment.ref")}
          </RetailText>
          <RetailText
            size="xs"
            weight="bold"
            className={cm.copyText}
            onClick={() => copyTextToClipboard(advertiser_id || id.id)}
          >
            {advertiser_id || id.id} <CopyOutlined className={cm.copy} />
          </RetailText>
        </div>
        <RetailText size="xs" weight="medium">
          {t("components.payment.info")}
        </RetailText>
      </article>
    </div>
  );
};

export default RetailPaymentInfoTag;
