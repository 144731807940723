import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DocumentOutlined } from "../../../assets/icons/documentOutlined.svg";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import { bg_mode } from "../../../utils/defaults";
import { SMOContext, SMOContextType } from "../../../context/SMOProvider";

import cm from "./style.module.scss";

const RetailDocsBar = () => {
  const { t } = useTranslation();

  const { setShowDocs } = useContext(SMOContext) as SMOContextType;

  const closeShowDocs = () => {
    setShowDocs(false);
    localStorage.setItem("docs", "false");
  };

  return (
    <section className={`${cm.container} ${cm[bg_mode]}`}>
      <DocumentOutlined />
      <a
        href="https://docs.gowit.com/reference/getting-started-1"
        target="_blank"
        rel="noreferrer"
        className={`${cm.link} ${cm[bg_mode]}`}
      >
        {t("common.docs")}
      </a>
      <CloseOutlined className={cm.closeIcon} onClick={closeShowDocs} />
    </section>
  );
};

export default RetailDocsBar;
