import Input, { InputRef } from "antd/lib/input";
import cc from "classcat";
import {
	RefObject,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import useSelectCloser from "../../../hooks/useSelectCloser";
import RetailText from "../../Typography/RetailText";
import cm from "../style.module.scss";

export interface AdvertiserSelectProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  type: "CAMPAIGN" | "COUPONS" | "BALANCE" | "INVITED";
  inputRef?: RefObject<InputRef>;
}

const AdvertiserSelect = ({
  type,
  onClick,
  inputRef,
}: AdvertiserSelectProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { options, handleScroll, search, setSearch, handleKey } =
    useInfiniteScroll("advertisers", true, type !== "INVITED");

  const [visible, setVisible] = useState(false);

  const open = () => setVisible(true);

  useSelectCloser(ref, setVisible);

  const className =
    type === "CAMPAIGN" || type === "INVITED" ? cm.campaign : cm.financial;

  return (
    <div className={cm.container} ref={ref}>
      <Input
        onChange={handleKey}
        className={cc([cm.input, className])}
        value={search}
        placeholder={t("components.advertiserSelect.placeholder")}
        onClick={open}
        ref={inputRef || undefined}
      />
      <section
        className={cc([visible && options?.length > 0 ? cm.selectBox : ""])}
        onScroll={handleScroll}
      >
        {visible &&
          options?.map((item: any) => (
            <RetailText
              family="poppins"
              size="xs"
              key={item.id}
              onClick={() => {
                setSearch(item.name);
                setVisible(false);
                onClick && onClick(item);
              }}
            >
              {item.name} ({item.id})
            </RetailText>
          ))}
      </section>
    </div>
  );
};

export default AdvertiserSelect;
