import { useEffect, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ReactComponent as ComputerFilled } from "../../../../assets/icons/computerFilled.svg";
import { ReactComponent as WebOutlined } from "../../../../assets/icons/webOutlined.svg";
import logo from "../../../../assets/images/logo.svg";

import cm from "./style.module.scss";

const OnboardingMobileView = () => {
  const { t } = useTranslation();
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        if (!timerRef.current) {
          timerRef.current = window.setTimeout(() => {
            window.location.replace("https://gowit.com/");
          }, 15000);
        }
      } else {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.replace("https://gowit.com/");
  };

  return (
    <section className={cm.mobileWrapper}>
      <div className={`flex ${cm.logo}`}>
        <img src={logo} alt="logo" />
      </div>
      <div className={cm.container}>
        <div className={`flex ${cm.iconContainer}`}>
          <ComputerFilled />
        </div>
        <article className={`flex ${cm.textContainer}`}>
          <Trans>
            <h3 className={cm.title}>
              {t("pages.auth.onboarding.mobile.title")}
            </h3>
          </Trans>
          <Trans>
            <p className={cm.text}>{t("pages.auth.onboarding.mobile.text")}</p>
          </Trans>
        </article>
        <a
          href="https://gowit.com/"
          onClick={handleClick}
          className={`flex ${cm.link}`}
        >
          {t("pages.auth.onboarding.mobile.link")}
          <WebOutlined />
        </a>
      </div>
    </section>
  );
};

export default OnboardingMobileView;
