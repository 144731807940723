import {
	Col,
	Input,
} from "antd";
import cc from "classcat";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormInput from "../../../RetailFormInput";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

export interface NameInputProps {
  name: string;
  handleFieldChange(e: any): void;
}

const NameInput = ({ name, handleFieldChange }: NameInputProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const advertiser = location.state as { id: number; name: string };
  
  return (
    <RetailFormRow
      className={cc(["form-row", advertiser ? cm.advertiser : ""])}
    >
      <RetailFormInfo
        column={{
          number: "1",
          title: t("components.campaignForm.firstStep.campaignInfoTitle"),
          text: t("components.campaignForm.firstStep.campaignInfoText"),
        }}
      />
      <Col>
        <RetailFormInput
          isFocused={name !== ""}
          label={t("components.campaignForm.firstStep.campaignLabel")}
          name="name"
          rules={[{ required: true }]}
          className="floating"
          help={t("components.campaignForm.firstStep.campaignSub")}
        >
          <Input
            onChange={handleFieldChange}
            className="floating"
            name="name"
            value={name}
            data-test="campaign-form-name"
          />
        </RetailFormInput>
      </Col>
    </RetailFormRow>
  );
};

export default NameInput;
