import Row from "antd/lib/row";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import RetailDateColumn from "../../../components/Column/RetailDateColumn";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailInvoiceModal from "../../../components/Modal/RetailInvoiceModal";
import RetailTable from "../../../components/Table/RetailTable";

const CouponsPage = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "transaction_date":
      case "expire_date":
        return <RetailDateColumn value={value} />;
      case "advertiser_name":
        return (
          <RetailNameColumn
            to={`/campaigns?adv=${records.advertiser_id}`}
            value={`${value} (${records.advertiser_id})`}
          />
        );
      case "transaction_type":
        return t(`pages.admin.coupons.${value?.toLowerCase()}`);
      case "description":
        return t(`pages.admin.coupons.${`${value?.toLowerCase()}_desc`}`);
      case "amount":
        return <RetailMoneyColumn value={value} />;
      default:
        return value ? value : "-";
    }
  };

  const open = () => setVisible(true);

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    defaultFilter: [
      {
        key: "transaction_type",
        op: "in",
        value: ["WELCOME_COUPON", "GIFT_COUPON"],
      },
    ],
    renderColumns,
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          tableConfig={tableConfig}
          button={{
            title: t("pages.admin.coupons.add"),
            onClick: open,
          }}
          rowSelection={undefined}
        />
        <RetailInvoiceModal
          type="COUPONS"
          subtitle={t("pages.admin.coupons.subtext")}
          visible={visible}
          setVisible={setVisible}
        />
      </Row>
    </RetailPageContainer>
  );
};

export default CouponsPage;
