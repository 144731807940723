import {
	createContext,
	useEffect,
	useState,
} from "react";

export type Category = {
  // Categories
  categories: any;
  setCategories(category: any): void;
  handleCategoryAdd(value: string): void;
  deleteCategory(id: number): void;

  // Negative Categories
  negativeCategories: any;
  setNegativeCategories(negativeCategory: any): void;
  handleNegativeCategoryAdd(value: string): void;
  deleteNegativeCategory(id: number): void;
};

export const CategoryContext = createContext<Category | null>(null);

export const CategoryProvider: React.FC = ({ children }) => {
  // Category States
  const [currentCategory, setCurrentCategory] = useState<any>();
  const [categories, setCategories] = useState<any[]>([]);

  // Negative Category States
  const [currentNegativeCategory, setCurrentNegativeCategory] = useState<any>();
  const [negativeCategories, setNegativeCategories] = useState<any[]>([]);

  // Function which adds manually typed category to category table
  const handleCategoryAdd = (value: string) => {
    value !== "" && setCurrentCategory(currentCategory);
  };

  // Function which removes category from categories
  const deleteCategory = (id: number) =>
    setCategories(categories.filter((category: any) => id !== category.id));

  // Update categories when current category changes
  useEffect(() => {
    currentCategory !== undefined &&
      setCategories((curr: any) => [...curr, currentCategory]);
  }, [currentCategory]);

  // Function which adds negative category to negative category table
  const handleNegativeCategoryAdd = (value: string) => {
    value !== "" && setCurrentNegativeCategory(currentCategory);
  };

  // Function which removes negative category from negative categories
  const deleteNegativeCategory = (id: number) =>
    setNegativeCategories(
      negativeCategories.filter((category: any) => id !== category.id)
    );

  // Update negative categories when current negative category changes
  useEffect(() => {
    currentNegativeCategory !== undefined &&
      setNegativeCategories((curr: any) => [...curr, currentNegativeCategory]);
  }, [currentNegativeCategory]);

  return (
    <CategoryContext.Provider
      value={{
        categories,
        setCategories,
        handleCategoryAdd,
        deleteCategory,
        negativeCategories,
        setNegativeCategories,
        handleNegativeCategoryAdd,
        deleteNegativeCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
