import auto from "../../../../../assets/images/landing_page/auto.png";
import city from "../../../../../assets/images/landing_page/city.png";
import keyword from "../../../../../assets/images/landing_page/keyword.png";
import cm from "./style.module.scss";

const TeknosaLandingTarget = () => {
  const cardData = [
    {
      title: "Anahtar Kelimeleri Belirleyin",
      text: "veya dilediğiniz kategorilerde hedefleme yaparak, ürününüzle ilgilenme olasılığı en yüksek müşterilere ulaşın.",
      img: keyword,
    },
    {
      title: "Size Uygun Hedeflemeyi Seçin",
      text: "Farklı hedefleme seçeneklerinden yararlanın ve en uygun anahtar kelimeleri seçin. Ürünlerinizi ilgili arama veya kategori sayfalarında gösterin.",
      img: auto,
    },
    {
      title: "Doğru Hedef Kitlesini Bulun",
      text: "Daha çok müşteriye ulaşın ve hedef kitlenizi kişiselleştirin. Reklamınızı en doğru müşteriler ile buluşturun.",
      img: city,
    },
  ];
  return (
    <section className={cm.container}>
      <h5>HEDEFLEME</h5>
      <h6>Birkaç adımda etkili reklam kampanyaları oluşturmaya başlayın.</h6>
      <div>
        {cardData.map((card, index) => (
          <article key={index} className={cm.card}>
            <h6>{card.title}</h6>
            <p>{card.text}</p>
            <img src={card.img} alt="" className={cm.mainImg} />
          </article>
        ))}
      </div>
    </section>
  );
};

export default TeknosaLandingTarget;
