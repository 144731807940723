const appier_tr = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/what-is-manual-targeting-how-is-it-done",
      bid_link:
        "/academy/blog/management/what-is-bidding-how-to-determine-your-bid",
      frequency_link:
        "/academy/blog/measuring/what-are-the-reporting-types-how-to-create-a-scheduled-report",
      report_type_link:
        "/academy/blog/measuring/what-are-the-predefined-reports-how-to-create-them",
      product_link:
        "/academy/blog/optimization/what-to-consider-when-choosing-a-product",
    },
    campaign_form_targeting_text:
      "Kampanyanızın hedefleme türünü seçin. Kampanyanız hedefleme tercihinize göre müşterilere gösterilir.",
    campaign_form_budget_text:
      "Kampanyanızın bütçe tipini ve miktarını belirleyin. Harcamanız bütçenize ulaştığında kampanya gösterimi durur.",
    campaign_form_creative_text:
      "Kampanyanızda kullanmak istediğiniz kreatifleri yükleyebilirsiniz.",
    invoice_modal_help_text:
      "Ödeme yöntemi seç butonuna tıklayarak devam ettiğiniz takdirde tahsil edilecek tutarın iadesinin yapılmayacağını onaylamış kabul edilirsiniz.",
    invoice_error_modal_title: "Bakiye Yüklenemedi!",
    invoice_error_modal_text: "Bir hata meydana geldi.",
  },
  marketplaceDependentSettings: {
    api_link: "",
    auto_impression_link: "",
    auction_link: "",
    product_link: "",
    stock_link: "",
    targeting_link: "",
    bidding_link: "",
  },
};

export default appier_tr;
