import { useEffect, useState } from "react";

import sanityClient from "../../../../client";
import TeknosaLandingFooter from "../TeknosaLayout/TeknosaLandingFooter";
import TeknosaLandingNavbar from "../TeknosaLayout/TeknosaLandingNavbar";
import TeknosaCaseStudyCards from "./TeknosaCaseStudyCards";
import TeknosaCaseStudyHeader from "./TeknosaCaseStudyHeader";

const TeknosaCaseStudy = () => {
  const [documents, setDocuments] = useState<any[]>([]);

  const fetchDocuments = async () => {
    sanityClient
      .fetch(
        `*[_type == "caseStudy" ]{
          title,
          brand,
          _id,
          slug,
          body,
          description,
          publishedAt,
          bg
      }`
      )
      .then((data) =>
        setDocuments(data.sort((a: any, b: any) => a.sorting - b.sorting))
      )
      .catch(console.error);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      <TeknosaLandingNavbar />
      <TeknosaCaseStudyHeader />
      <main>
        <TeknosaCaseStudyCards cards={documents} />
      </main>
      <TeknosaLandingFooter sectionVisible={false} />
    </>
  );
};

export default TeknosaCaseStudy;
