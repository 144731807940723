import Tag, { TagProps } from "antd/lib/tag";
import cc from "classcat";
import React from "react";
import { useTranslation } from "react-i18next";

import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailSuggestedTagProps extends TagProps {
}

const RetailSuggestedTag: React.FC<RetailSuggestedTagProps> = ({
  className,
	...tagProps
}) => {
  const {t} = useTranslation();
  return(
  <Tag className={cc([cm.tag, className || ""])} {...tagProps}>
    <RetailText weight="bold" size="xxxs">{t("components.suggestedTag.text")}</RetailText>
  </Tag>
)};

export default RetailSuggestedTag;