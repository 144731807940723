import Button from "antd/lib/button";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Dropdown from "antd/lib/dropdown";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import cc from "classcat";
import moment from "moment";
import {
	useContext,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
	CheckOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons/lib/icons";

import { ReactComponent as FilterOutlined } from "../../../assets/icons/filterOutlined.svg";
import FilterContext, { FilterProvider } from "../../../context/FilterProvider";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import {
	advertiserValues,
	advertiserValuesBrand,
	containValues,
	equalityValues,
	isValues,
	reportValuesEq,
	reportValuesIn,
	twoOptionedValues,
} from "../../../utils/equalities";
import {
	datePickerLocale,
	truncate,
} from "../../../utils/helpers";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailSelect from "../../Select/RetailSelect";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "../style.module.scss";

export interface RetailFilterPopoverProps {
  mode?: "reports" | "table" | "advertisers";
  filters?: any;
}

const { Option } = Select;

const RetailFilterPopover = ({ mode, filters }: RetailFilterPopoverProps) => {
  const { t, i18n } = useTranslation();

  const { options, handleScroll, setSearch } =
    useInfiniteScroll("parent_categories");

  const location = useLocation();

  const {
    equality,
    handleChange,
    selectedValue,
    selectedFilter,
    id,
    setTags,
    setAdvertiserTags,
  } = useContext(FilterContext);

  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<any>({ type: "contains" });
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [date, setDate] = useState<any>(null);

  const bidTypes = [
    {
      value: "STANDART",
      name: t("components.campaignForm.firstStep.default"),
    },
    {
      value: "FIXED",
      name: t("pages.acc.campaignDetails.fixed"),
    },
    {
      value: "MAX_ACOS",
      name: t("components.campaignForm.firstStep.advanced"),
    },
  ];

  const toggle = () => setDatePickerOpen((prev) => !prev);

  useEffect(
    () =>
      filters && selectedFilter !== ""
        ? setSelected(
            filters.find((item: any) => item.value === selectedFilter)
          )
        : setSelected({ type: "contains" }),
    [selectedFilter, filters]
  );

  const handleCancel = () => {
    setVisible(false);
    setSelected([]);
    handleChange("", "selectedFilter");
    handleChange("", "equality");
    handleChange("", "selectedValue");
  };

  const handleApply = () => {
    setVisible(false);

    if (mode === "advertisers") {
      setAdvertiserTags((tags: any) => [
        ...tags,
        {
          selectedFilter,
          equality,
          selectedValue: Array.isArray(selectedValue)
            ? selectedValue
            : [selectedValue],
          id,
        },
      ]);
    } else
      setTags((tags: any) => [
        ...tags,
        {
          selectedFilter,
          equality,
          selectedValue: Array.isArray(selectedValue)
            ? selectedValue
            : [selectedValue],
          id,
        },
      ]);
  };

  const mapTheValues = (arr: { name: string; value: string }[]) =>
    arr.map((val) => (
      <Option value={val.value}>{t(`common.equality.${val.name}`)}</Option>
    ));

  const switchEquality = (type: string) => {
    if (selected === undefined) return;
    switch (type) {
      case "equality":
        return mapTheValues(equalityValues);
      case "twoOptioned":
        return mapTheValues(twoOptionedValues);
      case "is":
        return mapTheValues(isValues);
      case "report":
        return mapTheValues(reportValuesIn);
      case "reportEq":
        return mapTheValues(reportValuesEq);
      case "advertiserValues":
        return mapTheValues(advertiserValues);
      case "advertiserValuesBrand":
        return mapTheValues(advertiserValuesBrand);
      default:
        return mapTheValues(containValues);
    }
  };

  const switchOptions = (type: string) => {
    if (type.includes("Status")) {
      return (
        <>
          <Option value="ACTIVE">{t("common.table.active")}</Option>
          <Option value="PAUSED">{t("common.table.stopped")}</Option>
        </>
      );
    } else {
      switch (type) {
        case "campaignBudgetType":
          return (
            <>
              <Option value="DAILY">
                {t("components.campaignForm.firstStep.dailyBudgetFilter")}
              </Option>
              <Option value="TOTAL">
                {t("components.campaignForm.firstStep.totalBudgetFilter")}
              </Option>
            </>
          );
        case "campaignBidType":
          return (
            <>
              {bidTypes.map((type) => {
                return <Option value={type.value}>{type.name}</Option>;
              })}
            </>
          );
        case "keywordMatchType":
        case "negativeKeywordMatchType":
          return (
            <>
              <Option value="EXACT">{t("common.exact")}</Option>
              <Option value="PHRASE">{t("common.phrase")}</Option>
              <Option value="BROAD">{t("common.broad")}</Option>
            </>
          );
        case "reportDeliveryFrequency":
          return (
            <>
              <Option value="DAILY">{t("components.reportForm.daily")}</Option>
              <Option value="WEEKLY">
                {t("components.reportForm.weekly")}
              </Option>
              <Option value="MONTHLY">
                {t("components.reportForm.monthly")}
              </Option>
            </>
          );
        case "category":
        case "retailer_taxonomy":
          return (
            <>
              {options.map((opt: any, index: number) => {
                return (
                  <Option value={opt.text} key={index}>
                    {opt.text ? truncate(opt.text) : ""}
                  </Option>
                );
              })}
            </>
          );
      }
    }
  };

  useEffect(() => {
    setTags([]);
    setAdvertiserTags([]);
    handleCancel();
  }, [location.pathname]);

  useEffect(() => {
    handleChange("", "equality");
    handleChange("", "selectedValue");
    setDate(null);
  }, [selectedFilter]);

  const disabledFilter =
    selectedFilter === "" || selectedValue === "" || equality === "";

  const disabledInput = selectedFilter === "" || equality === "";

  const isSelectInput =
    selected.type === "twoOptioned" ||
    selected.type === "is" ||
    selected.type === "report" ||
    selected.value === "category" ||
    selected.value === "retailer_taxonomy";

  const content = (
    <Row
      className={cc([
        cm.popover,
        mode === "advertisers" ? cm.advertiserPopover : "",
      ])}
    >
      <Col span={24} className={cm.filterDropdownInfo}>
        <RetailTitle level={5} className={cm.title}>
          {t("components.selectFilter.title")}
        </RetailTitle>
        {mode === "reports" && (
          <RetailText
            size="xxxs"
            weight="medium"
            className={cm.filterDropdownText}
          >
            {t("components.selectFilter.reportsText")}
          </RetailText>
        )}
      </Col>
      <Col span={24} className={cc(["flex", cm.col])}>
        <RetailSelect
          placeholder={t("components.selectFilter.placeholder")}
          className={cm.filter}
          onChange={(value) => handleChange(value, "selectedFilter")}
        >
          {filters &&
            filters.map((filter: any) => (
              <Option value={filter.value}>{filter.name}</Option>
            ))}
        </RetailSelect>
        <RetailSelect
          placeholder={t("components.selectFilter.placeholderCondition")}
          className={cm.filter}
          onChange={(value) => handleChange(value, "equality")}
          value={equality === "" ? null : equality}
          disabled={selectedFilter === ""}
        >
          {filters && selected !== undefined && switchEquality(selected.type)}
        </RetailSelect>
        {selected !== undefined ? (
          isSelectInput ? (
            <RetailSelect
              onChange={(value) => handleChange(value, "selectedValue")}
              className={cc([
                cm.filter,
                selectedFilter === "campaignBidType" ? cm.value : "",
              ])}
              placeholder={t("components.selectFilter.placeholderValue")}
              mode={
                selected.type === "is" || selected.type === "report"
                  ? "multiple"
                  : undefined
              }
              showSearch={
                selected.value === "category" ||
                selected.value === "retailer_taxonomy"
              }
              onSearch={setSearch}
              suffixIcon={
                selected.type === "is" || selected.type === "report" ? (
                  <CheckOutlined />
                ) : null
              }
              value={selectedValue === "" ? undefined : selectedValue}
              disabled={disabledInput}
              onPopupScroll={
                selected.value === "category" ||
                selected.value === "retailer_taxonomy"
                  ? handleScroll
                  : undefined
              }
              dropdownMatchSelectWidth={
                selected.value === "category" ||
                selected.value === "retailer_taxonomy"
                  ? 400
                  : true
              }
            >
              {switchOptions(selected.value)}
            </RetailSelect>
          ) : selected.value !== undefined &&
            selected.value.includes("Date") ? (
            <DatePicker
              className={cm.datePicker}
              onChange={(value) => {
                handleChange(value!.format(), "selectedValue");
                setDate(value);
              }}
              format="DD/MM/YYYY"
              value={date !== null ? moment(date) : null}
              onClick={selected.value.includes("End") ? toggle : undefined}
              onSelect={() => setDatePickerOpen(false)}
              showToday={selected.value.includes("End") ? false : true}
              locale={datePickerLocale(i18n.language)}
              placeholder={
                date === null && selected.value.includes("End")
                  ? t("components.endDatePicker.button")
                  : t("components.selectFilter.date")
              }
              open={selected.value.includes("End") ? datePickerOpen : undefined}
              renderExtraFooter={
                selected.value.includes("End")
                  ? () => (
                      <Button
                        onClick={() => {
                          handleChange(null, "selectedValue");
                          setDatePickerOpen(false);
                          setDate(null);
                        }}
                      >
                        {t("components.endDatePicker.button")}
                      </Button>
                    )
                  : undefined
              }
              disabled={disabledInput}
            />
          ) : (
            <Input
              className={cc([cm.input, "floating"])}
              onChange={({ target }) => {
                if (equality === "in") {
                  handleChange(
                    target.value.split(",").filter((str) => str !== ""),
                    "selectedValue"
                  );
                } else handleChange(target.value, "selectedValue");
              }}
              placeholder={t("components.selectFilter.enter")}
              disabled={disabledInput}
            />
          )
        ) : null}
      </Col>
      <Col span={24} className={cc(["flex", cm.btnGroup])}>
        <RetailMainButton hasBackground={false} onClick={handleCancel}>
          {t("common.cancellation")}
        </RetailMainButton>
        <RetailMainButton
          hasBackground
          onClick={handleApply}
          disabled={disabledFilter}
        >
          {t("common.filter")}
        </RetailMainButton>
      </Col>
    </Row>
  );

  return (
    <FilterProvider>
      <div
        className={cc([
          cm.overlay,
          cm.overlayFilter,
          visible ? cm.overlayVisible : "",
        ])}
      ></div>
      <Dropdown
        overlay={content}
        placement="topCenter"
        visible={visible}
        destroyPopupOnHide
        trigger={["click"]}
        onVisibleChange={(value: React.SetStateAction<boolean>) => {
          setVisible(value);
          setSelected([]);
          handleChange("", "selectedFilter");
          handleChange("", "equality");
          handleChange("", "selectedValue");
          setDate(null);
        }}
      >
        <RetailMainButton
          hasBackground={mode === "table" ? false : true}
          className={cc([
            "flex",
            mode === "table" ? cm.btn : cm.filterBtn,
            cm[mode!],
          ])}
          icon={mode === "table" ? undefined : <PlusCircleOutlined />}
          onClick={() => {
            setVisible(true);
          }}
        >
          {mode === "table" ? (
            <span className={cm.flex}>
              <FilterOutlined /> {t("common.filter")}
            </span>
          ) : (
            t("components.selectFilter.add")
          )}
        </RetailMainButton>
      </Dropdown>
    </FilterProvider>
  );
};

export default RetailFilterPopover;
