import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import useApi from "../../../../api";
import useTableFetch from "../../../../hooks/useTableFetch";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import RetailTable from "../../../../components/Table/RetailTable";
import SelectNegativeTable from "../../../../components/Table/SelectNegativeTable";
import RetailNotification from "../../../../components/Notification";

import {
  Category,
  CategoryContext,
} from "../../../../context/CategoryProvider";
import { Keyword, KeywordContext } from "../../../../context/KeywordProvider";
import { negativeKeywordFilters } from "../../../../utils/filters";

export interface NegativeKeywordsTableProps {
  type: "NEGATIVE_KEYWORDS" | "NEGATIVE_CATEGORIES";
}

const NegativeKeywordsTable = ({ type }: NegativeKeywordsTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [alreadySelectedData, setAlreadySelectedData] = useState([]);

  const { data } = useTableFetch("campaign", true, {}, "NEGATIVE_CATEGORIES");

  const { negativeKeywords, setNegativeKeywords } = useContext(
    KeywordContext
  ) as Keyword;

  const { negativeCategories, setNegativeCategories } = useContext(
    CategoryContext
  ) as Category;

  const [isVisible, setIsVisible] = useState(false);

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType={type}
          />
        );
      case "match_type":
        return <span>{t(`common.${value.toLowerCase()}`)}</span>;

      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: type,
    id: id ? parseInt(id) : 1,
    filters: negativeKeywordFilters(t),
    renderColumns,
  };

  const reset = () => {
    type === "NEGATIVE_KEYWORDS"
      ? setNegativeKeywords([])
      : setNegativeCategories([]);
    setIsVisible(false);
  };

  const updateValue = async () => {
    const configNegativeKeywords = {
      negative_keywords:
        negativeKeywords.length > 0
          ? negativeKeywords.map((word) => {
              return {
                text: word.text,
                match_type: word.match_type,
              };
            })
          : null,
    };
    const configNegativeCategories = {
      negative_categories:
        negativeCategories.length > 0
          ? negativeCategories.map((category: any) => category.id)
          : null,
    };
    const response = await api.patch(
      `campaigns/${id}`,
      type === "NEGATIVE_KEYWORDS"
        ? configNegativeKeywords
        : configNegativeCategories
    );
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addNegativeKeywords = async () => {
    try {
      await mutateAsync();
      queryClient.refetchQueries("table");
      reset();
    } catch (e) {
      if (e?.request?.responseText?.includes("same keyword in target")) {
        RetailNotification.showNotification(
          "error",
          "",
          t("components.campaignForm.firstStep.errorStates.sameKeyword")
        );
      } else if (e?.request?.responseText?.includes("negative keyword already exists")) {
        RetailNotification.showNotification(
          "error",
          "",
          t("components.campaignForm.firstStep.errorStates.duplicateNegativeKeyword")
        )
      }
    }
  };

  const onOk = () => {
    addNegativeKeywords();
  };

  useEffect(() => {
    setAlreadySelectedData(data?.data.records);
  }, [data]);

  return (
    <>
      <RetailTable
        button={{
          title: t(`pages.acc.campaignDetails.${type.toLowerCase()}_btn`),
          onClick: () => setIsVisible(true),
        }}
        placeholder={t(
          `pages.acc.campaignDetails.${type.toLowerCase()}_placeholder`
        )}
        tableConfig={tableConfig}
      />
      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t(
          `pages.acc.campaignDetails.${type.toLowerCase()}_modal_subtitle`
        )}
        onCancel={reset}
        onOk={onOk}
        type={type}
      >
        <SelectNegativeTable
          type={type}
          alreadySelectedData={alreadySelectedData}
        />
      </CampaignDetailsModal>
    </>
  );
};

export default NegativeKeywordsTable;
