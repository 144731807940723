import {
	Modal,
	ModalProps,
	Row,
} from "antd";
import { useTranslation } from "react-i18next";

import { ReactComponent as WarningBlueFilled } from "../../../assets/icons/blockFilled.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailWarningModalProps extends ModalProps {
  type: "campaign";
  children?: React.ReactNode;
}

const RetailWarningModal = ({
  type,
  children,
  ...modalProps
}: RetailWarningModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      closable={false}
      footer={[
        <Row className={cm.btnContainer}>
          <RetailMainButton
            hasBackground={false}
            onClick={modalProps.onCancel}
            className={cm.cancelBtn}
          >
            {t("common.exit")}
          </RetailMainButton>

          <RetailMainButton
            hasBackground
            onClick={modalProps.onOk}
            className={cm.okBtn}
          >
            {t("common.continue")}
          </RetailMainButton>
        </Row>,
      ]}
      className={`campaign-modal ${cm.warningModal}`}
      {...modalProps}
    >
      <section className={`flex ${cm.warningContainer}`}>
        <WarningBlueFilled className={cm.warningIcon} />

        <RetailTitle className={cm.warningTitle} noMargin>
          {t(`components.modal.warning.${type}_warning_title`)}
        </RetailTitle>
        <RetailText size="xs" weight="medium" className={cm.warningText}>
          {t(`components.modal.warning.${type}_warning_text`)}
        </RetailText>
      </section>
      {children}
    </Modal>
  );
};

export default RetailWarningModal;
