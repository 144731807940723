import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Spin } from "antd";
import { convertEnumToFormattedString } from "../../../utils/helpers";
import { ReactComponent as Error } from "../../../assets/icons/dark/error.svg";
import { ReactComponent as Warning } from "../../../assets/icons/dark/warning.svg";
import { ReactComponent as Info } from "../../../assets/icons/dark/info.svg";
import { ReactComponent as LinkOutlined } from "../../../assets/icons/linkOutlined.svg";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightWhiteOutlined.svg";
import { Log } from "../../../utils/types";
import "highlight.js/styles/vs2015.css";
import Highlight from "react-highlight";
import useApi from "../../../api";
import Empty from "../../../components/Empty";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailTable from "../../../components/Table/RetailTable";
import RetailLinkColumn from "../../../components/Column/RetailLinkColumn";
import RetailText from "../../../components/Typography/RetailText";
import RetailDrawer from "../../../components/Drawer/RetailDrawer";

import cm from "./style.module.scss";

const logTypes: {
  value: "ERROR" | "WARNING" | "INFO";
  icon: JSX.Element;
}[] = [
  {
    value: "ERROR",
    icon: <Error />,
  },
  {
    value: "WARNING",
    icon: <Warning />,
  },
  {
    value: "INFO",
    icon: <Info />,
  },
];

const DeveloperConsolePage = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [dataStates, setDataStates] = useState<{
    type: "ERROR" | "WARNING" | "INFO";
    logs: Log[];
    id: number | null;
    loading: boolean;
  }>({
    type: "INFO",
    logs: [],
    id: null,
    loading: false,
  });

  const [timestampsHistory, setTimestampsHistory] = useState<string[]>([]);

  const [currentTimestamp, setCurrentTimestamp] = useState<string | null>(null);

  const navigate = useNavigate();

  const { api } = useApi();

  const fetchLogs = async (
    type: "ERROR" | "WARNING" | "INFO",
    id: number,
    end?: string | null
  ) => {
    setDataStates((prevState) => ({
      ...prevState,
      type,
      id,
      loading: true,
    }));

    try {
      const config = {
        log_type: type,
        limit: 50,
        ...(end && { end }),
      };

      const response = await api.post(`data_sources/${id}/logs`, config);

      setDataStates((prevState) => ({
        ...prevState,
        logs: response?.data?.records || [],
        loading: false,
      }));

      setCurrentTimestamp(response?.data?.last_timestamp);

      return response?.data;
    } catch (error) {
      console.error("Error fetching logs:", error);
      setDataStates((prevState) => ({
        ...prevState,
        loading: false,
      }));
      return null;
    }
  };

  const handleNextPage = async () => {
    const response: any = await fetchLogs(
      dataStates.type,
      dataStates.id as number,
      currentTimestamp
    );
    if (timestampsHistory.length === 0 && currentTimestamp)
      setTimestampsHistory([currentTimestamp]);

    if (response && response.last_timestamp) {
      setTimestampsHistory((prev) => [...prev, response.last_timestamp]);
      setCurrentTimestamp(response.last_timestamp);
    }
  };

  const handlePreviousPage = async () => {
    if (timestampsHistory.length > 2) {
      const newHistory = timestampsHistory.slice(0, -1);

      const previousTimestamp = newHistory[newHistory.length - 2];

      setTimestampsHistory(newHistory);

      await fetchLogs(
        dataStates.type,
        dataStates.id as number,
        previousTimestamp
      );
    } else {
      setTimestampsHistory([]);
      setCurrentTimestamp(null);
      await fetchLogs(dataStates.type, dataStates.id as number);
    }
  };

  const openDrawer = () => setVisible(true);

  const closeDrawer = () => {
    setVisible(false);
    setDataStates({
      type: "INFO",
      logs: [],
      id: null,
      loading: false,
    });
    setCurrentTimestamp(null);
    setTimestampsHistory([]);
  };

  const renderColumns = (col: string, value: any) => {
    switch (col) {
      case "entity":
      case "import_method":
        return t(`pages.admin.console.${value?.toLowerCase()}`);
      case "schedule_interval":
        return value
          ? t(`pages.admin.console.${convertEnumToFormattedString(value)}`)
          : "-";
      default:
        return value ? value : "-";
    }
  };

  const columns = () => [
    {
      title: "",
      dataIndex: "name",
      render: (_: string, records: any) => (
        <div className={cm.cols}>
          <div
            className={`flex ${cm.logsCol}`}
            onClick={() => {
              fetchLogs("ERROR", records.id);
              openDrawer();
            }}
          >
            <RetailText
              size="xs"
              weight="medium"
              family="poppins"
              className={cm.logsText}
            >
              {t("pages.admin.console.logs")}
            </RetailText>
            <LinkOutlined />
          </div>
          <div className={`flex ${cm.historyCol}`}>
            <RetailLinkColumn
              value={t("pages.admin.console.history")}
              to={`/admin/console/${records.id}`}
              state={{
                breadcrumb: true,
                breadcrumbType: "console",
                breadcrumbValue: records.name,
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const tableConfig = {
    url: "data_sources",
    isRelation: false,
    renderColumns,
  };

  const navigateToCreateDatasource = () => navigate("/admin/create-datasource");

  const renderLogs = (logs: Log[]) => {
    return logs.map((log, index) => {
      const combinedLog = {
        ...JSON.parse(log.message),
        timestamp: log.timestamp,
      };

      return (
        <section className={cm.log} key={index}>
          <RetailText
            size="xs"
            weight="medium"
            family="poppins"
            className={cm.logText}
          >
            <Highlight className="json">
              {JSON.stringify(combinedLog, null, 2)}
            </Highlight>
          </RetailText>
        </section>
      );
    });
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          tableConfig={tableConfig}
          button={{
            title: t("pages.admin.console.add"),
            onClick: navigateToCreateDatasource,
          }}
          columnsForAdditionalRendering={() => columns()}
          rowSelection={undefined}
        />
      </Row>
      {/* Logs Drawer */}
      <RetailDrawer
        visible={visible}
        title={t("pages.admin.console.drawer")}
        onOk={closeDrawer}
        onClose={closeDrawer}
        width="90%"
        type="dark"
        className={cm.drawer}
        footer={null}
      >
        <section className={cm.logTypeWrapper}>
          {logTypes.map((type) => {
            const active = type.value === dataStates.type;
            return (
              <section
                className={`${active ? cm.active : ""} ${cm.logTypeContainer}`}
                key={type.value}
                onClick={() => {
                  if (active) return;
                  fetchLogs(type.value, dataStates.id as number, null);
                }}
              >
                {type.icon}
                <RetailText
                  size="xs"
                  weight="medium"
                  family="poppins"
                  className={cm.tabText}
                >
                  {t(`pages.admin.console.${type.value.toLowerCase()}`)}
                </RetailText>
              </section>
            );
          })}

          <section
            className={`${cm.prev} ${
              timestampsHistory.length === 0 ? cm.disabled : ""
            }`}
            onClick={handlePreviousPage}
          >
            <LeftOutlined />
            <RetailText
              size="xs"
              weight="medium"
              family="poppins"
              className={cm.tabText}
            >
              {t("pages.admin.console.prev")}
            </RetailText>
          </section>
          <section className={cm.next} onClick={handleNextPage}>
            <RetailText
              size="xs"
              weight="medium"
              family="poppins"
              className={cm.tabText}
            >
              {t("pages.admin.console.next")}
            </RetailText>
            <RightOutlined />
          </section>
        </section>
        <section
          className={
            dataStates.logs.length > 0 && !dataStates.loading
              ? cm.logsWrapper
              : cm.logsWrapperEmpty
          }
        >
          {dataStates.loading ? (
            <Spin spinning={dataStates.loading} />
          ) : dataStates.logs.length > 0 ? (
            renderLogs(dataStates.logs)
          ) : (
            <Empty type="logs" className={cm.empty} />
          )}
        </section>
      </RetailDrawer>
    </RetailPageContainer>
  );
};

export default DeveloperConsolePage;
