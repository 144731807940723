import Upload from "antd/lib/upload";
import {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload/interface";
import cc from "classcat";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useApi from "../../../../../api";
import { ReactComponent as UploadIcon } from "../../../../../assets/icons/draggerOutlined.svg";
import {
  Creative,
  CreativeContext,
} from "../../../../../context/CreativeProvider";
import useTableFetch from "../../../../../hooks/useTableFetch";
import { Img } from "../../../../../utils/types";
import Empty from "../../../../Empty";
import RetailNotification from "../../../../Notification";
import RetailText from "../../../../Typography/RetailText";
import RetailCreativeUploadInfo from "../../../RetailCreativeUploadInfo";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormRow from "../../../RetailFormRow";
import CreativeContainer from "./CreativeContainer";

import cm from "../../style.module.scss";

export interface CreativeStepProps {
  targeting: string;
  error: boolean;
  languages: string[];
}

interface RecordType {
  recommended_size: string;
}

const CreativeStep = ({ error, targeting, languages }: CreativeStepProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { img, setImg } = useContext(CreativeContext) as Creative;

  const { data } = useTableFetch("ad_placements", false, {}, undefined, [
    { key: "status", op: "eq", value: "ACTIVE" },
    {
      key: "ad_format",
      op: "eq",
      value: "DISPLAY",
    },
  ]);

  const [uniqueRecommendedSizes, setUniqueRecommendedSizes] = useState<
    RecordType[]
  >([]);

  useEffect(() => {
    if (data?.data.records) {
      const sizeSet = new Set<string>();
      const uniqueSizes = data.data.records.filter((record: RecordType) => {
        if (!sizeSet.has(record.recommended_size)) {
          sizeSet.add(record.recommended_size);
          return true;
        }
        return false;
      });
      setUniqueRecommendedSizes(uniqueSizes);
    }
  }, [data]);

  const mainImgFile = useRef<File>();

  const props: UploadProps = {
    name: "files",
    multiple: true,
    className: "dragger",
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) => handleFileChange(f),
    accept: ".jpg, .svg, .jpeg, .png, gif",
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;
    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name.replace(/\.[^/.]+$/, "")!);
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
      }),
    };
    try {
      await api
        .post("/creatives", config, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          const img = new Image();

          img.src = response.data.image_url;

          img.onload = () => {
            setImg((prev: Img[]) => [
              ...prev,
              {
                url: img.src,
                size: `${img.width}x${img.height}`,
                creative_name: response.data.creative_name,
                uuid: response.data.id,
                size_valid: response.data.size_valid,
                languages: response.data.languages,
              },
            ]);
          };
        });
    } catch (err) {
      const maxCreatives = err?.request?.responseText?.includes(
        "maximum number of creatives for SMO"
      );
      const maxFileSize = err?.request?.responseText?.includes(
        "file size is too large"
      );
      const errKey = maxCreatives
        ? "creativeLimitErr"
        : maxFileSize
        ? "creativeSizeErr"
        : "generalErr";

      if (errKey) {
        return RetailNotification.showNotification(
          "error",
          "",
          t(`components.campaignForm.firstStep.errorStates.${errKey}`)
        );
      }
    }
  };

  return (
    <div className={cm.creativeFormRow}>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: targeting === "AUTO_TARGETING" ? "6" : "7",
            title: t("components.campaignForm.firstStep.creativeTitle"),
            text: t("marketplaceDependent.campaign_form_creative_text"),
          }}
        />
        <Upload.Dragger {...props}>
          <UploadIcon className={cm.upload} />
          <RetailText size="xs" weight="medium" className={cm.selectedTitle}>
            {t("components.campaignForm.firstStep.draggerTitle")}
          </RetailText>
          <RetailText size="xxxs" weight="medium" className={cm.creativeText}>
            {t("components.campaignForm.firstStep.draggerText")}
          </RetailText>
        </Upload.Dragger>
      </RetailFormRow>
      {img.length === 0 ? (
        <Empty type="creativeForm" className={cm.empty} isError={error}>
          {uniqueRecommendedSizes.map((d: RecordType) => (
            <RetailText
              key={d.recommended_size}
              size="xxxs"
              family="poppins"
              weight="medium"
              className={cm.sizeTag}
            >
              {d.recommended_size}
            </RetailText>
          ))}
        </Empty>
      ) : (
        <div className={cm.creativeSub}>
          {img.length > 0 && (
            <section className={cc(["flex", cm.creatives])}>
              {img.map((i) => (
                <CreativeContainer
                  key={i.uuid}
                  item={i}
                  languages={languages}
                />
              ))}
            </section>
          )}
        </div>
      )}

      <RetailCreativeUploadInfo fullWidth={img.length > 0} />
    </div>
  );
};

export default CreativeStep;
