import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Col, { ColProps } from "antd/lib/col";
import RetailNameColumn from "../../Column/RetailNameColumn";
import RetailNumeric from "../../Typography/RetailNumeric";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailFormInfoProps extends ColProps {
  column: any;
  advertiserStateForLinks?: { id: number; name: string };
  hasBackground?: boolean;
  notMaxed?: boolean;
  article?: { type: string; text: string };
}

const RetailFormInfo = ({
  column,
  hasBackground,
  advertiserStateForLinks,
  notMaxed = false,
  article = { type: "", text: "" },
  className,
  ...colProps
}: RetailFormInfoProps) => {
  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();

  const link = t(`marketplaceDependent.form.${article.type}_link`);

  const path = pathname.includes("admin")
    ? `${link}?adv=${advertiserStateForLinks?.id}`
    : link;

  return (
    <Col {...colProps} className={className || ""}>
      <div className={cm.row}>
        {column.number !== "" && (
          <RetailNumeric colored={hasBackground}>{column.number}</RetailNumeric>
        )}
        <div className={cc([cm.col, notMaxed ? cm.notMaxed : ""])}>
          <RetailTitle className={cm.title}>{column.title}</RetailTitle>
          <RetailText
            size="xs"
            family="poppins"
            type="secondary"
            className={cm.text}
          >
            {column.text}
          </RetailText>
          {i18n.exists(`marketplaceDependent.form.${article.type}_link`) && (
            <RetailNameColumn
              to={path}
              value={article.text}
              state={advertiserStateForLinks}
              isForm
            />
          )}
        </div>
      </div>
    </Col>
  );
};

export default RetailFormInfo;
