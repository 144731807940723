import Checkbox from "antd/lib/checkbox";
import Popover from "antd/lib/popover";
import { useTranslation } from "react-i18next";

import { ReactComponent as LinesOutlined } from "../../../assets/icons/linesOutlined.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import cm from "../style.module.scss";

export interface RetailColumnPopoverProps {
  options: any;
  onChange(checkedValues: any): void;
}
const RetailColumnPopover: React.FC<RetailColumnPopoverProps> = ({
  options,
  onChange,
}) => {
  const { t } = useTranslation();

  const content = (
    <Checkbox.Group
      options={options}
      className={cm.checkbox}
      defaultValue={options.map(
        (opt: any) => opt.defaultChecked === true && opt.value
      )}
      onChange={(values) => onChange(values)}
    />
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      title={t("components.columnPopover.title")}
      content={content}
    >
      <RetailMainButton hasBackground={false} className={cm.btn}>
        <LinesOutlined /> {t("common.column")}
      </RetailMainButton>
    </Popover>
  );
};
export default RetailColumnPopover;
