import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightOutlined.svg";
import React from "react";

export interface RetailPaginationButtonsProps {
  type: "prev" | "next" | string;
  element: React.ReactNode;
}

const RetailPaginationButtons = ({
  type,
  element,
}: RetailPaginationButtonsProps) => {
  const renderButton = (buttonType: "prev" | "next") => {
    const isPrev = buttonType === "prev";

    return (
      <button
        type="button"
        className="ant-pagination-item-link"
        aria-label={isPrev ? "Previous" : "Next"}
      >
        {isPrev ? <LeftOutlined /> : <RightOutlined />}
      </button>
    );
  };

  switch (type) {
    case "prev":
      return renderButton("prev");
    case "next":
      return renderButton("next");
    default:
      return <>{element}</>;
  }
};

export default RetailPaginationButtons;
