import { createContext, useEffect, useState } from "react";
import useApi from "../../api";
import useSettings from "../../hooks/useSettings";

export interface SMOContextType {
  showDocs: boolean;
  setShowDocs: (value: boolean) => void;
}

export const SMOContext = createContext<SMOContextType | null>(null);

export const SMOProvider: React.FC = ({ children }) => {
  const { roleForDashboard } = useApi();

  const { data } = useSettings(roleForDashboard);

  const [showDocs, setShowDocs] = useState(() => {
    const docsFromLocalStorage = localStorage.getItem("docs");
    return docsFromLocalStorage === "true";
  });

  useEffect(() => {
    if (data?.data && localStorage.getItem("docs") === null) {
      const docsValue = data?.data?.is_smo ? "true" : "false";
      localStorage.setItem("docs", docsValue);
      setShowDocs(data?.data?.is_smo);
    }
  }, [data]);

  return (
    <SMOContext.Provider
      value={{
        showDocs,
        setShowDocs,
      }}
    >
      {children}
    </SMOContext.Provider>
  );
};

export default SMOProvider;
