import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import sanityClient from "../client";

const useAcademyCategoryFetch = () => {
  const { i18n } = useTranslation();

  const [category, setCategory] = useState<any | null>(null);

  const slug = useMatch("/academy/blog/:title");

  const fetchCategory = async () => {
    sanityClient
      .fetch(
        `*[_type == "category" && slug.current == "${slug?.params.title}"]{
          "title": title.${i18n.language},
          "description": description.${i18n.language},
          slug
      }`
      )
      .then((data) => setCategory(data[0]))
      .catch(console.error);
  };

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug?.params.title, i18n.language]);

  return { category };
};

export default useAcademyCategoryFetch;
