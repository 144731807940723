import Select from "antd/lib/select";
import {
	useContext,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
	Keyword,
	KeywordContext,
} from "../../../context/KeywordProvider";
import { convertKeywordVolume } from "../../../utils/helpers";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailEditableColumn from "../../Column/RetailEditableColumn";
import RetailMoneyColumn from "../../Column/RetailMoneyColumn";
import RetailBidEditContainer, { Selected } from "../../Container/RetailBidEditContainer";
import RetailSelect from "../../Select/RetailSelect";
import RetailFormTable from "../RetailFormTable";
import cm from "./style.module.scss";

export interface SelectKeywordsTableProps {
  suggestKeywords?: boolean;
  isDynamic?: boolean;
  error?: boolean;
}

const { Option, OptGroup } = Select;

const SelectKeywordsTable = ({
  suggestKeywords = false,
  isDynamic = false,
  error,
}: SelectKeywordsTableProps) => {
  const { t } = useTranslation();

  const { deleteKeyword } = useContext(KeywordContext) as Keyword;

  const [selected, setSelected] = useState<Selected>({
    data: {},
    editing: false,
    value: "",
    editing_field: "",
  });

  const targetingKeywordColumns = [
    {
      title: t("components.table.keywords.keyword"),
      dataIndex: "text",
    },
    {
      title: t("components.table.keywords.matchType"),
      dataIndex: "match_type",
      width: 200,
      render: (value: "EXACT" | "PHRASE" | "BROAD", records: any) => (
        <RetailSelect
          defaultValue={value}
          className={cm.select}
          onChange={(value) => {
            records.match_type = value;
          }}
          dropdownClassName="text-select"
          dropdownMatchSelectWidth={360}
        >
          <Option value="EXACT">{t("common.exact")}</Option>
          <OptGroup label={t("components.table.keywords.exact")} />
          <Option value="PHRASE">{t("common.phrase")}</Option>
          <OptGroup
            data-cy="phrase"
            label={t("components.table.keywords.phrase")}
          ></OptGroup>
          <Option value="BROAD">{t("common.broad")}</Option>
          <OptGroup label={t("components.table.keywords.broad")}></OptGroup>
        </RetailSelect>
      ),
    },
    {
      title: t("components.table.keywords.suggested"),
      dataIndex: "suggested_bid",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.bid"),
      dataIndex: "bid",
      width: 125,
      render: (value: number, records: any) =>
        isDynamic ? (
          selected?.data.id === records.id && selected.editing ? (
            <RetailBidEditContainer
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <RetailEditableColumn
              value={value}
              suggested_bid={records.suggested_bid}
              onClick={() =>
                setSelected({
                  data: records,
                  editing: true,
                  value: value || records.suggested_bid,
                  editing_field: "bid",
                })
              }
            />
          )
        ) : (
          <span>{t("components.table.keywords.dynamic")}</span>
        ),
    },
    {
      title: t("components.table.keywords.volume"),
      dataIndex: "volume",
      render: (value: string) => convertKeywordVolume(value, t),
    },
    //Low variable in localization is changed.
    /* {
      title: t("components.table.keywords.competition"),
      dataIndex: "competition",
      render: (value: string) => (
        <RetailTableTag mode="unfilledGreen">
          <RetailText family="poppins" weight="bold" size="xxxs">
            {t(`components.table.keywords.low`)}
          </RetailText>
        </RetailTableTag>
      ),
    }, */
    {
      key: 7,
      title: "",
      dataIndex: "action",
      render: (value: any, records: any) => (
        <RetailMainButton
          hasBackground={false}
          data-test="campaign-form-remove-keyword"
          onClick={() => {
            deleteKeyword(records.id);
            records.isAdded = false;
          }}
          className={cm.btn}
        >
          {t("common.remove")}
        </RetailMainButton>
      ),
    },
  ];

  return (
    <RetailFormTable
      error={error}
      type="KEYWORDS"
      columns={targetingKeywordColumns}
      suggest={suggestKeywords}
    />
  );
};

export default SelectKeywordsTable;
