import Row from "antd/lib/row";
import moment from "moment";
import { useTranslation } from "react-i18next";

import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailTable from "../../../components/Table/RetailTable";

const SynchronizationHistoryPage = () => {
  const { t } = useTranslation();

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "message":
        return value ? t(`pages.admin.sync.${records.code}`) : "-";
      case "sync_type":
        return value ? t(`pages.admin.sync.${value.toLowerCase()}`) : "-";
      case "created_at":
        return value ? moment(value).format("DD/MM/YYYY HH:mm") : "-";
      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "sync_logs",
    isRelation: false,
    renderColumns,
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          tableConfig={tableConfig}
          addBtnVisible={false}
          rowSelection={undefined}
        />
      </Row>
    </RetailPageContainer>
  );
};

export default SynchronizationHistoryPage;
