import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  Creative,
  CreativeContext,
} from "../../../../context/CreativeProvider";

import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios from "axios";

import useOnboardingNavigate from "../../../../hooks/useOnboardingNavigate";
import useOnboardingApi from "../../../../api/onboarding";
import useOnboardingUser from "../../../../hooks/useOnboardingUser";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import RetailOnboardingCountContainer from "../../../../components/Container/RetailOnboardingCountContainer";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailText from "../../../../components/Typography/RetailText";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import OnboardingUploadContainer from "./OnboardingUploadContainer";
import OnboardingUploadedBanner from "./OnboardingUploadedBanner";
import RetailOnboardingButtonGroup from "../../../../components/Button/RetailOnboardingButtonGroup";
import sideImg from "../../../../assets/images/onboarding/third-step.png";

import { Img } from "../../../../utils/types";

import cm from "./style.module.scss";
import { useOnboardingStepAction } from "../../../../hooks/useOnboardingStepAction";

const OnboardingThirdStep = () => {
  const { t } = useTranslation();

  const { currentStep, goToNextStep, goToPreviousStep } =
    useOnboardingNavigate();

  const { getAction, markStepAsVisited } = useOnboardingStepAction(currentStep);

  const { onboardingApi } = useOnboardingApi();

  const { onboardingUser, loading } = useOnboardingUser();

  const { img, setImg } = useContext(CreativeContext) as Creative;

  const [form] = useForm();

  const [formFields, setFormFields] = useState<{
    is_sample: boolean | null;
    redirect_url: string;
    creative_url: string | null;
  }>({
    is_sample: null,
    redirect_url: "",
    creative_url: null,
  });

  const isValidURL =
    /^(https?:\/\/)?([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/;

  const isButtonDisabled =
    img.length === 0 || !isValidURL.test(formFields.redirect_url);

  const uploadVisibleWithoutCreative =
    !formFields.is_sample && img.length === 0;

  const handleRedirectURL = ({ target }: any) => {
    setFormFields((prev) => ({ ...prev, redirect_url: target.value }));
  };

  const chooseSample = () =>
    setFormFields((prev) => ({ ...prev, is_sample: true }));

  const chooseUpload = () => {
    setFormFields((prev) => ({ ...prev, is_sample: false }));
    setImg([]);
  };

  const createCampaign = async () => {
    const method = getAction();
    try {
      await onboardingApi![method](`/campaigns`, {
        status: "ACTIVE",
        creative: img[img?.length - 1]?.uuid,
        redirect_url: formFields.redirect_url,
      });
      if (method === "post") markStepAsVisited();
      setImg([]);
      goToNextStep();
    } catch (error) {
      console.error(error);
    }
  };

  const switchSample = useCallback(() => {
    switch (onboardingUser?.marketplace_type) {
      case "CLOTHING":
        return "./sample-banner-clothing.png";
      case "TECH":
        return "./sample-banner-tech.png";
      case "GROCERY":
        return "./sample-banner-grocery.png";
      default:
        return "./sample-banner-tech.png";
    }
  }, [onboardingUser]);

  const uploadImage = useCallback(async () => {
    const response = await fetch(switchSample());
    const blob = await response.blob();

    const formData = new FormData();

    formData.append("image", blob);
    formData.append("name", "sample");
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");
    formData.append("is_sample", "true");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        is_sample: formData.get("is_sample"),
      }),
    };

    await axios
      .post(
        `api/advertisers/${onboardingUser?.default_advertiser_id}/creatives`,
        config,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("auth_token") || ""
            )}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const img = new Image();

        img.src = response.data.image_url;

        img.onload = () => {
          setImg((prev: Img[]) => [
            ...prev,
            {
              url: img.src,
              size: `${img.width}x${img.height}`,
              creative_name: response.data.creative_name,
              uuid: response.data.id,
              size_valid: response.data.size_valid,
            },
          ]);
        };
      });
  }, [onboardingUser, setImg, switchSample]);

  useEffect(() => {
    if (!formFields.is_sample || !onboardingUser) return;
    else if (formFields.is_sample === true) uploadImage();
  }, [formFields.is_sample, onboardingUser, uploadImage]);

  const clearSelection = async () => {
    setFormFields((prev) => ({ ...prev, is_sample: !prev.is_sample }));
    setImg([]);
  };

  useEffect(() => {
    setFormFields({
      is_sample: onboardingUser?.is_sample_image ?? null,
      redirect_url: onboardingUser?.redirect_url ?? "",
      creative_url: onboardingUser?.creative_url ?? null,
    });

    form.setFieldsValue({
      redirect_url: onboardingUser?.redirect_url || "",
    });

    if (onboardingUser?.is_sample_image === false) {
      setImg((prev: Img[]) => [
        ...prev,
        {
          url: onboardingUser?.creative_url,
          size: onboardingUser?.creative_size,
          creative_name: onboardingUser?.creative_name,
          uuid: onboardingUser?.creative_id,
          size_valid: true,
        },
      ]);
    }
  }, [onboardingUser, form, setImg]);

  const Divider = () => {
    if (formFields.is_sample === null) {
      return <p className={cm.divider}>{t("common.or")}</p>;
    } else return null;
  };

  const Button = () => {
    if (formFields.is_sample === null) return null;
    else
      return (
        <RetailMainButton className={cm.returnBtn} onClick={clearSelection}>
          {t(
            `pages.auth.onboarding.${
              formFields.is_sample ? "uploadBtn" : "sampleBtn"
            }`
          )}
        </RetailMainButton>
      );
  };

  if (loading) return <Spin size="large" spinning={loading} />;

  return (
    <>
      <section className={`flex ${cm.container}`}>
        <article className={cm.side}>
          <RetailOnboardingCountContainer />
          <RetailTitle className={cm.title} level={3}>
            {t("pages.auth.onboarding.campaignTitle")}
          </RetailTitle>
          <RetailText size="xs" weight="medium" className={cm.mainText}>
            {t("pages.auth.onboarding.campaignText")}
          </RetailText>
          <Form
            form={form}
            requiredMark={false}
            colon={false}
            autoComplete="off"
            className={cm.form}
          >
            <section className={cm.innerWrapper}>
              {(formFields.is_sample === null || formFields.is_sample) && (
                <div
                  className={`${cm.sampleImgContainer} ${
                    formFields.is_sample ? cm.selected : ""
                  }`}
                  onClick={chooseSample}
                >
                  <RetailText
                    size="xs"
                    weight="medium"
                    className={cm.sampleTitle}
                  >
                    {t("pages.auth.onboarding.sampleBanner")}
                  </RetailText>
                  <RetailText
                    size="xxxs"
                    weight="medium"
                    className={cm.sampleText}
                  >
                    {t("pages.auth.onboarding.sampleBannerText")}
                  </RetailText>
                  <div className={`flex ${cm.sampleImg}`}>
                    <img src={switchSample()} alt="sample-banner" />
                  </div>
                </div>
              )}

              <Divider />

              {uploadVisibleWithoutCreative && (
                <OnboardingUploadContainer
                  advertiserID={onboardingUser?.default_advertiser_id}
                  onClick={chooseUpload}
                />
              )}

              {formFields.is_sample === false && img.length > 0 && (
                <OnboardingUploadedBanner img={img[0]} onClick={chooseUpload} />
              )}

              <Button />
            </section>
            <RetailFormInput
              isFocused={formFields.redirect_url !== ""}
              label={t("pages.auth.onboarding.redirectURL")}
              name="redirect_url"
              className="floating"
              help={t("pages.auth.onboarding.redirectHelp")}
              rules={[
                {
                  required: true,
                  pattern: isValidURL,
                },
              ]}
            >
              <Input
                className={`${cm.input} floating`}
                onChange={handleRedirectURL}
                value={formFields.redirect_url}
              />
            </RetailFormInput>
          </Form>

          <RetailOnboardingButtonGroup
            nextText={t("pages.auth.onboarding.create")}
            handleBack={goToPreviousStep}
            handleNext={createCampaign}
            disabled={isButtonDisabled}
          />
        </article>
      </section>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.thirdStepText")}
        sideImg={sideImg}
        step="third"
      />
    </>
  );
};

export default OnboardingThirdStep;
