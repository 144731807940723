import { useLocation } from "react-router-dom";
import RetailText from "../../Typography/RetailText";

import cm from "./style.module.scss";

const RetailOnboardingCountContainer = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const count = searchParams.get("q") || "1";

  return (
    <RetailText size="xxxs" weight="medium" className={cm.count}>
      {count}/4
    </RetailText>
  );
};

export default RetailOnboardingCountContainer;
