import { createContext } from "react";

import useSelectProducts from "../../hooks/useSelectProducts";

export type Product = {
  selectedProducts: any[];
  setSelectedProducts(product: any): void;
  handleAdd(selectedRowKeys: React.Key[], selectedRows: any[]): void;
  handleDelete(selectedRowKeys: React.Key[], selectedRows: any[]): void;
  handleDeleteAllProducts(): void;
};

export const ProductContext = createContext<Product | null>(null);

export const ProductProvider: React.FC = ({ children }) => {
  const {
    selectedProducts,
    setSelectedProducts,
    handleAdd,
    handleDeleteAllProducts,
    handleDelete,
  } = useSelectProducts();

  return (
    <ProductContext.Provider
      value={{
        selectedProducts,
        setSelectedProducts,
        handleAdd,
        handleDelete,
        handleDeleteAllProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
