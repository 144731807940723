import Tabs from "antd/lib/tabs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import RetailSettingsLayout from "../../../components/Layout/RetailSettingsLayout";
import RestrictionsPageCategoryTab from "./RestrictionsPageCategoryTab";
import RestrictionsPageKeywordTab from "./RestrictionsPageKeywordTab";
import cm from "./style.module.scss";

const RestrictionsPage = () => {
  const { t } = useTranslation();
  type ActiveKey = "category" | "keyword";

  const [activeKey, setActiveKey] = useState<ActiveKey>("category");

  const handleActiveKey = (key: ActiveKey | string) =>
    setActiveKey(key as ActiveKey);

  return (
    <>
      <div className={cm.tabHeader}></div>
      <RetailSettingsLayout className={cm.page}>
        <Tabs
          className={cm.tabs}
          centered
          activeKey={activeKey}
          onChange={handleActiveKey}
        >
          <Tabs.TabPane
            tab={t("pages.admin.restrictions.category")}
            key="category"
          >
            <RestrictionsPageCategoryTab />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={t("pages.admin.restrictions.keyword")}
            key="keyword"
          >
            <RestrictionsPageKeywordTab />
          </Tabs.TabPane>
        </Tabs>
      </RetailSettingsLayout>
    </>
  );
};

export default RestrictionsPage;
