import { Link, useLocation, LinkProps } from "react-router-dom";

interface RetailAdvertiserViewLinkProps extends LinkProps {
  to: string;
  advValue?: string;
}

const RetailAdvertiserViewLink = ({
  to,
  advValue,
  children,
  ...props
}: RetailAdvertiserViewLinkProps) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  if (advValue) {
    searchParams.set("adv", advValue);
  }

  const queryString = searchParams.toString();

  const toWithQuery = {
    pathname: to,
    search: queryString,
  };

  return (
    <Link to={toWithQuery} {...props}>
      {children}
    </Link>
  );
};

export default RetailAdvertiserViewLink;
