import { numFormatter } from "../../../utils/helpers";

export interface RetailNumberColumnProps {
  value: number;
  isZero?: boolean;
}
const RetailNumberColumn = ({
  value,
  isZero = true,
}: RetailNumberColumnProps) => {
  const thereIsNoValue = isZero ? 0 : "-";

  return <span>{value ? numFormatter(value) : thereIsNoValue}</span>;
};

export default RetailNumberColumn;
