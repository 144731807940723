import RetailAdvertiserViewLink from "../../Link/RetailAdvertiserViewLink";
import { ReactComponent as LinkOutlined } from "../../../assets/icons/linkOutlined.svg";

import cm from "../style.module.scss";

export interface RetailNameColumnProps {
  to: string;
  value: string;
  state?: any;
  isForm?: boolean;
}

const RetailNameColumn = ({
  to,
  value,
  state,
  isForm = false,
}: RetailNameColumnProps) => {
  return (
    <>
      {value ? (
        <RetailAdvertiserViewLink
          to={to}
          className={`${cm.link} ${isForm ? cm.formLink : cm.tableLink}`}
          state={state}
          target={isForm ? "_blank" : "_self"}
        >
          {value}
          {!isForm && <LinkOutlined className={cm.linkUp} />}
        </RetailAdvertiserViewLink>
      ) : (
        <span>-</span>
      )}
    </>
  );
};

export default RetailNameColumn;
