import cm from "./style.module.scss";

const TeknosaCaseStudyHeader = () => {
  return (
    <header className={cm.caseHeader}>
      <h1>Doğru müşteriye doğru reklamla ulaşmak artık çok kolay.</h1>
      <p>
        Birçok farklı kanalda gösterilebilen reklam formatları ile etkili kitle
        hedeflemeleri yaparak marka bilinirliğini, satışları ve genel reklam
        dönüşümlerini artır.
      </p>
    </header>
  );
};

export default TeknosaCaseStudyHeader;
