import { numFormatter, SelectCurrency } from "../../../utils/helpers";

export interface RetailMoneyColumnProps {
  value: number | string;
  isZero?: boolean;
  spendColumn?: boolean;
}

const RetailMoneyColumn = ({
  value,
  isZero = false,
  spendColumn = false,
}: RetailMoneyColumnProps) => {
  const suffix = isZero ? "0" : "-";

  return (
    <span>
      {value ? (
        <SelectCurrency amount={numFormatter(value, spendColumn)} />
      ) : (
        suffix
      )}
    </span>
  );
};

export default RetailMoneyColumn;
