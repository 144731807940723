import { useEffect, useState } from "react";
import useOnboardingApi from "../api/onboarding";

interface OnboardingUser {
  marketplace_url: string;
  marketplace_type: string;
  user_email: string;
  user_name: string;
  user_linkedin_picture_url: string;
  default_advertiser_id: number;
  is_sample_image: boolean;
  redirect_url: string;
  creative_id: number;
  creative_name: string;
  creative_size: string;
  creative_url: string;
  marketplace_uuid: string;
  default_placement_id: number;
}

const useOnboardingUser = () => {
  const [onboardingUser, setOnboardingUser] = useState<
    OnboardingUser | undefined
  >();

  const [loading, setLoading] = useState(false);

  const [isEnabled, setIsEnabled] = useState(false);

  const { onboardingApi } = useOnboardingApi();

  useEffect(() => {
    const fetchData = async () => {
      if (onboardingApi && !isEnabled) {
        setIsEnabled(true);
        try {
          setLoading(true);
          const response = await onboardingApi.get("/data");
          setOnboardingUser(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [onboardingApi, isEnabled]);

  return { onboardingUser, loading };
};

export default useOnboardingUser;
