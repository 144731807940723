import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import cc from "classcat";
import {
	ChangeEvent,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import useApi from "../../../../api";
import RetailDrawer from "../../../../components/Drawer/RetailDrawer";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import RetailSettingsHeader from "../../../../components/Layout/RetailSettingsHeader";
import RetailTable from "../../../../components/Table/RetailTable";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import cm from "../style.module.scss";

const RestrictionsPageKeywordTab = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const [keywordState, setKeywordState] = useState<{
    visible: "editing" | "creating" | "none";
    keyword: string;
    id?: number;
  }>({
    visible: "none",
    keyword: "",
  });

  const openCreate = () =>
    setKeywordState({ ...keywordState, visible: "creating" });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setKeywordState({ ...keywordState, keyword: target.value });

  const close = () => {
    setKeywordState({ keyword: "", visible: "none", id: undefined });
    form.resetFields();
  };

  const deleteCategoryApi = async (id: number) => {
    try {
      await api.delete(`keyword-blacklist/${id}`);
      queryClient.refetchQueries("table");
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async () => {
    try {
      keywordState.visible === "creating"
        ? await api.post("keyword-blacklist", {
            text: keywordState.keyword,
            restriction_type: form.getFieldValue("type"),
          })
        : await api.patch(`keyword-blacklist/${keywordState.id}`, {
            text: keywordState.keyword,
            restriction_type: form.getFieldValue("type"),
          });
      queryClient.refetchQueries("table");
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const openEdit = (record: any) => {
    setKeywordState({
      visible: "editing",
      keyword: record.text,
      id: record.id,
    });
    form.setFieldsValue({
      keyword: record.text,
      type: record.restriction_type,
    });
  };

  const columns = () => [
    {
      title: "",
      dataIndex: "id",
      width: 5,
      render: (value: number) => (
        <DeleteOutlined
          className={cc([cm.delete, cm.deleteKeyword])}
          onClick={() => deleteCategoryApi(value)}
        />
      ),
    },
  ];

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "restriction_type":
        return t(`pages.admin.restrictions.${value?.toLowerCase()}`);
      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "keyword-blacklist",
    isRelation: false,
    to: openEdit,
    activeKey: "keyword",
    renderColumns,
  };

  return (
    <>
      <RetailSettingsHeader type="keyword" />
      <div className={cm.container}>
        <div className={cm.flex}>
          <div>
            <RetailTitle level={4} className={cm.secondaryTitle}>
              {t("pages.admin.restrictions.keywordTitle")}
            </RetailTitle>
            <RetailText
              size="xxxs"
              family="poppins"
              className={cm.categoryText}
            >
              {t("pages.admin.restrictions.keywordText")}
            </RetailText>
          </div>
        </div>

        <Divider className={cm.divider} />

        <RetailTable
          tableConfig={tableConfig}
          columnsForAdditionalRendering={() => columns()}
          placeholder={t("pages.admin.restrictions.placeholder")}
          button={{
            title: t("pages.admin.restrictions.add"),
            onClick: openCreate,
          }}
          rowSelection={undefined}
        />

        <RetailDrawer
          title={t("pages.admin.restrictions.add")}
          onOk={onFinish}
          onClose={close}
          visible={keywordState.visible !== "none"}
        >
          <Form form={form} requiredMark={false}>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.restrictions.info")}
            </RetailTitle>

            <RetailFormInput
              isFocused={keywordState.keyword !== ""}
              label={t("pages.admin.restrictions.label")}
              name="keyword"
              help={t("pages.admin.restrictions.help")}
              rules={[
                {
                  required: true,
                },
              ]}
              className="floating"
            >
              <Input className="floating" onChange={handleChange} />
            </RetailFormInput>

            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.restrictions.type")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.subtext}>
              {t("pages.admin.restrictions.typeText")}
            </RetailText>

            <Form.Item
              name="type"
              className={cc(["flex", cm.type])}
              rules={[
                {
                  required: true,
                  message: t("pages.admin.restrictions.typeError"),
                },
              ]}
            >
              <Radio.Group name="type">
                <Radio value="EXCLUSIVE" className="form-radio">
                  <RetailTitle level={5} className={cm.title}>
                    {t("pages.admin.restrictions.exclusive")}
                  </RetailTitle>
                  <RetailText className={cm.text} size="xxxs">
                    {t("pages.admin.restrictions.exclusiveText")}
                  </RetailText>
                </Radio>
                <Radio value="BLOCK" className="form-radio">
                  <RetailTitle level={5} className={cm.title}>
                    {t("pages.admin.restrictions.block")}
                  </RetailTitle>
                  <RetailText className={cm.text} size="xxxs">
                    {t("pages.admin.restrictions.blockText")}
                  </RetailText>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </RetailDrawer>
      </div>
    </>
  );
};

export default RestrictionsPageKeywordTab;
