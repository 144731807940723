import {
	Row,
	Tag,
} from "antd";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Radio from "antd/lib/radio";
import cc from "classcat";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useTableFetch from "../../../../hooks/useTableFetch";
import RetailSearchBar from "../../../Bar/RetailSearchBar";
import RetailSuggestedTag from "../../../Tag/RetailSuggestedTag";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";
import RetailFormInfo from "../../RetailFormInfo";
import RetailFormRow from "../../RetailFormRow";
import cm from "../style.module.scss";

export interface RetailCampaignFormThirdStepProps {
  requiredFields: any;
  handleChange(value: any, name: string): void;
  words: boolean;
  setWords(word: boolean): void;
  indeterminate: boolean;
  checkAll: any;
  onCheckAllChange: any;
  onChange: any;
  checkedList: any;
}

export const RetailCampaignFormThirdStep: React.VFC<
  RetailCampaignFormThirdStepProps
> = ({
  requiredFields,
  handleChange,
  words,
  setWords,
  indeterminate,
  checkAll,
  onCheckAllChange,
  onChange,
  checkedList,
}) => {
  const { t } = useTranslation();

  const { data } = useTableFetch("cities", false, {});

  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      <RetailFormRow
        className={cc([cm.audienceRow, "form-row", words ? cm.fullHeight : ""])}
      >
        <RetailFormInfo
          column={{
            number: "1",
            title: t("components.campaignForm.thirdStep.targeting"),
            text: t("components.campaignForm.thirdStep.targetingText"),
          }}
        />
        <Form.Item name="audience" className="flex reversed">
          <Radio.Group
            onChange={({ target }) =>
              target.value === "auto" ? setWords(true) : setWords(false)
            }
          >
            <Radio value="auto" className="form-radio">
              <RetailTitle level={5}>
                {t("components.campaignForm.thirdStep.autoTargeting")}{" "}
                <RetailSuggestedTag />
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxs" family="poppins">
                {t("components.campaignForm.thirdStep.autoTargetingText")}
              </RetailText>
            </Radio>
            <Radio value="manual" className={cc(["form-radio", cm.marginTop])}>
              <RetailTitle level={5}>
                {t("components.campaignForm.thirdStep.manualTargeting")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxs" family="poppins">
                {t("components.campaignForm.thirdStep.manualTargetingText")}
              </RetailText>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </RetailFormRow>
      {!words && (
        <>
          <RetailFormRow className={cc([cm.targetingRow, cm.firstBorder])}>
            <RetailFormInfo
              column={{
                number: "",
                title: t("components.campaignForm.thirdStep.platform"),
                text: t("components.campaignForm.thirdStep.platformText"),
              }}
            />
            <Form.Item name="platform">
              <Checkbox.Group className={cc(["flex", cm.platformContainer])}>
                <Checkbox
                  value="TABLET"
                  className={cc(["form-radio", cm.targetingPlatform])}
                >
                  {t("components.campaignForm.thirdStep.tablet")}
                </Checkbox>
                <Checkbox
                  value="MOBILE"
                  className={cc(["form-radio", cm.targetingPlatform])}
                >
                  {t("components.campaignForm.thirdStep.mobile")}
                </Checkbox>
                <Checkbox
                  value="APP"
                  className={cc(["form-radio", cm.targetingPlatform])}
                >
                  {t("components.campaignForm.thirdStep.app")}
                </Checkbox>
                <Checkbox
                  value="DESKTOP"
                  className={cc(["form-radio", cm.targetingPlatform])}
                >
                  {t("components.campaignForm.thirdStep.desktop")}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </RetailFormRow>
          <RetailFormRow className={cm.targetingRow}>
            <RetailFormInfo
              column={{
                number: "",
                title: t("components.campaignForm.thirdStep.age"),
                text: t("components.campaignForm.thirdStep.ageText"),
              }}
            />
            <Form.Item name="age">
              <Checkbox.Group className={cc(["flex", cm.ageContainer])}>
                <Checkbox
                  value="18-24"
                  className={cc(["form-radio", cm.targetingAge])}
                >
                  18-24
                </Checkbox>
                <Checkbox
                  value="25-34"
                  className={cc(["form-radio", cm.targetingAge])}
                >
                  25-34
                </Checkbox>
                <Checkbox
                  value="35-44"
                  className={cc(["form-radio", cm.targetingAge])}
                >
                  35-44
                </Checkbox>
                <Checkbox
                  value="45-54"
                  className={cc(["form-radio", cm.targetingAge])}
                >
                  45-54
                </Checkbox>
                <Checkbox
                  value="55-64"
                  className={cc(["form-radio", cm.targetingAge])}
                >
                  55-64
                </Checkbox>
                <Checkbox
                  value="65+"
                  className={cc(["form-radio", cm.targetingAge])}
                >
                  65+
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </RetailFormRow>
          <RetailFormRow className={cm.targetingRow}>
            <RetailFormInfo
              column={{
                number: "",
                title: t("components.campaignForm.thirdStep.city"),
                text: t("components.campaignForm.thirdStep.cityText"),
              }}
            />
            <Form.Item name="cities">
              <Radio.Group
                className={cc(["flex", cm.relative, cm.column])}
                onChange={({ target }) =>
                  target.value === "ALL"
                    ? handleChange(true, "all_cities")
                    : handleChange(false, "all_cities")
                }
              >
                <Radio
                  value="ALL"
                  className={cc([
                    "form-radio",
                    cm.targetingCities,
                    cm.marginBottom,
                  ])}
                >
                  <RetailTitle level={5}>
                    {t("components.campaignForm.thirdStep.allCities")}
                  </RetailTitle>
                </Radio>
                <Col className={requiredFields.all_cities ? "" : cm.citiesCol}>
                  <Radio
                    value="selected_cities"
                    className={cc([
                      "form-radio",
                      cm.targetingCities,
                      requiredFields.all_cities
                        ? ""
                        : ["expanded", cm.selectedCities],
                    ])}
                  >
                    <RetailTitle level={5}>
                      {t("components.campaignForm.thirdStep.selectedCities")}
                    </RetailTitle>
                  </Radio>
                  {checkedList && (
                    <Row className={cm.tagContainer}>
                      {checkedList.map((item: any) => (
                        <Tag
                          key={item}
                          closable
                          className={cm.tag}
                          onClose={() => onChange(item)}
                        >
                          {item}
                        </Tag>
                      ))}
                    </Row>
                  )}
                  {!requiredFields.all_cities && (
                    <>
                      <Form.Item name="cities_select">
                        <Row className={cm.dropdown}>
                          <RetailSearchBar
                            fullWidth
                            placeholder={t(
                              "components.campaignForm.thirdStep.cityPlaceholder"
                            )}
                            style={{
                              padding: "12px 12px 12px 0",
                              marginBottom: "1rem",
                            }}
                            onChange={({ target }) =>
                              setSearchValue(target.value)
                            }
                          />
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={checkAll}
                          >
                            {t("common.checkAll")}
                          </Checkbox>
                          <Checkbox.Group value={checkedList}>
                            {data &&
                              data?.data?.records
                                ?.filter((item: any) => {
                                  if (searchValue === "") {
                                    return item.name;
                                  } else if (
                                    item.name
                                      .toLocaleLowerCase()
                                      .includes(searchValue.toLocaleLowerCase())
                                  ) {
                                    return item.name;
                                  }
                                })
                                ?.map((item: any) => (
                                  <Checkbox
                                    value={item.name}
                                    key={item.name}
                                    onChange={({ target }) =>
                                      onChange(target.value)
                                    }
                                  >
                                    {item.name}
                                  </Checkbox>
                                ))}
                          </Checkbox.Group>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Radio.Group>
            </Form.Item>
          </RetailFormRow>
          <RetailFormRow className={cm.targetingRow}>
            <RetailFormInfo
              column={{
                number: "",
                title: t("components.campaignForm.thirdStep.customer"),
                text: t("components.campaignForm.thirdStep.customerText"),
              }}
            />
            <Form.Item name="customers">
              <Checkbox.Group className={cm.placement}>
                <Checkbox
                  value="PRESENT"
                  className={cc(["form-radio", cm.placementCheckbox])}
                >
                  <RetailTitle
                    style={{ fontSize: "13px" }}
                    level={5}
                    className={cm.marginBottom}
                  >
                    {t("components.campaignForm.thirdStep.presentCustomers")}
                  </RetailTitle>
                  <RetailText className="checkbox-gray-text" family="poppins">
                    {t(
                      "components.campaignForm.thirdStep.presentCustomersText"
                    )}
                  </RetailText>
                </Checkbox>
                <Checkbox
                  value="NEW"
                  className={cc(["form-radio", cm.targetingGender])}
                >
                  <RetailTitle
                    style={{ fontSize: "13px" }}
                    level={5}
                    className={cm.marginBottom}
                  >
                    {t("components.campaignForm.thirdStep.newCustomers")}
                  </RetailTitle>
                  <RetailText className="checkbox-gray-text" family="poppins">
                    {t("components.campaignForm.thirdStep.newCustomersText")}
                  </RetailText>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </RetailFormRow>
          <RetailFormRow className={cc([cm.targetingRow, cm.lastBorder])}>
            <RetailFormInfo
              column={{
                number: "",
                title: t("components.campaignForm.thirdStep.gender"),
                text: t("components.campaignForm.thirdStep.genderText"),
              }}
            />
            <Form.Item name="gender">
              <Checkbox.Group className={cc(["flex", cm.column])}>
                <Checkbox
                  value="FEMALE"
                  className={cc(["form-radio", cm.targetingGender])}
                >
                  {t("components.campaignForm.thirdStep.female")}
                </Checkbox>
                <Checkbox
                  value="MALE"
                  className={cc(["form-radio", cm.targetingGender])}
                >
                  {t("components.campaignForm.thirdStep.male")}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </RetailFormRow>
        </>
      )}
    </>
  );
};
