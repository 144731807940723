import moment from "moment";

export interface RetailDateColumnProps {
  value: string;
}

const RetailDateColumn = ({ value }: RetailDateColumnProps) => {
  return <>{value === "0001-01-01" ? "-" : moment(value).format("DD/MM/YY")}</>;
};

export default RetailDateColumn;
