import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Input from "antd/lib/input";
import cc from "classcat";
import {
	ChangeEvent,
	KeyboardEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import useApi from "../../../api";
import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkWhite.svg";
import useSettings from "../../../hooks/useSettings";
import {
	prefix,
	validateNumber,
} from "../../../utils/helpers";
import { Category } from "../../../utils/types";
import RetailNotification from "../../Notification";
import RetailBidTooltip from "../../Tooltip/RetailBidTooltip";
import cm from "./style.module.scss";

export interface Selected {
  editing: boolean;
  data: {
    category_setting?: Category["category_setting"];
    [key: string]: string | number | Category["category_setting"];
  };

  value: string;
  editing_field: string;
}
export interface RetailBidEditContainerProps {
  selected: Selected;
  setSelected: (selected: Selected) => void;
  onClick?: () => void;
  justCloseOnBlur?: () => void;
}

const RetailBidEditContainer = ({
  selected,
  setSelected,
  onClick,
  justCloseOnBlur,
}: RetailBidEditContainerProps) => {
  const { t } = useTranslation();

  const { roleForDashboard } = useApi();

  const { data, value, editing_field } = selected;

  const { data: bidData } = useSettings(
    roleForDashboard,
    editing_field === "bid"
  );

  const ref = useRef<any>(null);

  const [error, setError] = useState(true);

  const [form] = useForm();

  const location = useLocation();

  const ad_type = location.state as { ad_type: string };

  const skipErrorAndOnBlur =
    editing_field === "relevance" ||
    editing_field === "acos" ||
    editing_field === "minimum_cpm" ||
    editing_field === "minimum_bid";

  const chooseMinimum = useCallback(() => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
        return bidData?.data?.sponsored_display_price_setting;
      case "VIDEO":
        return bidData?.data?.sponsored_video_price_setting;
      default:
        return bidData?.data?.sponsored_product_price_setting;
    }
  }, [ad_type?.ad_type, bidData]);

  const chosenMinBid = useCallback(() => {
    if (chooseMinimum() === "CPM") return bidData?.data?.minimum_cpm;
    else return bidData?.data?.minimum_bid;
  }, [bidData, chooseMinimum]);

  const close = () => {
    if (!error && !skipErrorAndOnBlur) {
      data[editing_field] = chosenMinBid();
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.editableFieldError")
      );
    } else {
      data[editing_field] = value;
      onClick && onClick();
      setSelected({ ...selected, editing: false, editing_field: "" });
    }
  };

  //Memorize the function to avoid re-rendering
  const errorNumber = useCallback(() => {
    const value = parseFloat(ref.current.input.value.replace(/,/g, "."));
    switch (editing_field) {
      case "acos":
        return value >= 0.0001 && value <= 100;
      case "relevance":
        return value >= 0 && value <= 100;
      default:
        return value >= (parseFloat(chosenMinBid()) || 0);
    }
  }, [editing_field, chosenMinBid]);

  const type = () => {
    switch (editing_field) {
      case "bid":
      case "minimum_bid":
      case "minimum_cpm":
        return "general";
      case "acos":
        return "percent";
      default:
        return "number";
    }
  };

  useEffect(() => {
    setError(errorNumber() && ref.current.input.value !== "");
  }, [errorNumber, value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSelected({ ...selected, value: target.value });

  const handleNumberValidation: KeyboardEventHandler<HTMLInputElement> = (e) =>
    validateNumber(e, editing_field !== "acos");

  const initial = () => {
    const isEmpty = value === "-" || !value;
    if (editing_field === "relevance") return value;
    if (isEmpty) return "";
    return parseFloat(value ?? chosenMinBid().toFixed(2));
  };

  const isClickingRef = useRef(false);

  const handleMouseDown = () => (isClickingRef.current = true);

  const handleBlur = () => {
    setTimeout(() => {
      if (isClickingRef.current) {
        isClickingRef.current = false;
        return;
      }
      justCloseOnBlur ? justCloseOnBlur() : close();
    }, 0);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    close();
  };

  return (
    <div
      data-cy="keyword-bid-input"
      className={cc(["flex", cm.inputContainer, error ? "" : cm.error])}
    >
      <div
        data-cy="keyword-bid-ok-button"
        className={cc([
          cm.iconContainer,
          cm.okContainer,
          "flex",
          error ? "" : cm.error,
        ])}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
      >
        <CheckOutlined />
      </div>
      {editing_field === "relevance" || editing_field === "acos" ? null : (
        <span className={cm.prefix}>{prefix()}</span>
      )}
      <Form form={form} initialValues={{ bid: initial() }} autoComplete="off">
        <Form.Item name="bid" className={cm.formItem}>
          <Input
            data-cy="keyword-edit-bid-icon"
            autoFocus={true}
            className={cc([cm.input, error ? "" : cm.error])}
            onKeyDownCapture={handleNumberValidation}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={ref}
          />
        </Form.Item>
      </Form>

      {!error && <RetailBidTooltip value={chosenMinBid() || 0} type={type()} />}
    </div>
  );
};

export default RetailBidEditContainer;
