import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import useApi from "../api";

const useDatasourceFetch = (enabled = true) => {
  const { pathname } = useLocation();

  const { api, baseURL } = useApi();

  const id = pathname.substring(pathname.lastIndexOf("/") + 1);

  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (baseURL() !== undefined) setIsEnabled(enabled && !isNaN(parseInt(id)));
  }, [enabled, id, baseURL]);

  const { data, isLoading, isError, error } = useQuery(
    ["datasource", id],
    () => {
      const data = api(`data_sources/${parseInt(id)}`);
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return { isLoading, isError, data, error };
};

export default useDatasourceFetch;
