import Switch, { SwitchProps } from "antd/lib/switch";

import useTableStatusChange from "../../../hooks/useTableStatusChange";
import { Relations } from "../../../utils/types";

export interface RetailStatusColumnProps extends SwitchProps {
  records: any;
  url: string;
  relationType?: Relations;
}

const RetailStatusColumn = ({
  records,
  url,
  relationType,
  ...switchProps
}: RetailStatusColumnProps) => {
  const { changeValue } = useTableStatusChange(
    url,
    relationType !== undefined,
    relationType
  );
  
  return (
    <Switch
      data-cy="switch"
      defaultChecked={
        records.status === "ACTIVE" || records.account_user_status === "ACTIVE"
      }
      checked={
        records.status === "ACTIVE" || records.account_user_status === "ACTIVE"
      }
      onChange={(value) => {
        changeValue(records);
      }}
      {...switchProps}
    />
  );
};

export default RetailStatusColumn;
