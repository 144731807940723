import { useContext } from "react";
import FilterContext from "../../../context/FilterProvider";
import Row from "antd/lib/row";
import cm from "./style.module.scss";
import Col from "antd/lib/col";
import RetailText from "../../Typography/RetailText";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterOutlined } from "../../../assets/icons/filterOutlined.svg";
import RetailFilterTag from "../../Tag/RetailFilterTag";

const RetailFilterBar = () => {
  const { t } = useTranslation();

  const { tags } = useContext(FilterContext);

  return (
    <>
      {tags.length > 0 && (
        <Row className={cm.tagContainer}>
          <Col className="flex">
            <FilterOutlined />
            <RetailText size="xs" family="poppins">
              {t("components.reportForm.filters")}
            </RetailText>
          </Col>
          <Col className="flex">
            {tags.map((tag) => (
              <RetailFilterTag type="FILTER" tag={tag} key={tag.id} />
            ))}
          </Col>
        </Row>
      )}
    </>
  );
};

export default RetailFilterBar;
