import Tooltip, { TooltipPropsWithTitle } from "antd/lib/tooltip";
import {
	CSSProperties,
	ReactNode,
} from "react";

export interface RetailTooltipProps extends TooltipPropsWithTitle {
  children: ReactNode;
  style?: React.CSSProperties;
}

const RetailTooltip = ({
  children,
  style,
  ...tooltipPropsWithTitle
}: RetailTooltipProps) => {
  const defaultStyles: CSSProperties = {
    padding: "10px 12px",
    borderRadius: "8px",
    fontSize: "12px",
    lineHeight: "18px",
  };

  const combinedStyles = { ...defaultStyles, ...style };

  return (
    <Tooltip
      color="var(--primary-6)"
      arrowPointAtCenter
      overlayInnerStyle={combinedStyles}
      {...tooltipPropsWithTitle}
      overlayClassName="retail-tooltip"
    >
      {children}
    </Tooltip>
  );
};

export default RetailTooltip;
