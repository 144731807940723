import { Input } from "antd";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import {
	ChangeEvent,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import { CheckOutlined } from "@ant-design/icons";

import useApi from "../../../api";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailCreativeImgColumn from "../../../components/Column/RetailCreativeImgColumn";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import CreativePreviewModal from "../../../components/Modal/CreativePreviewModal";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import { PolicyStatus } from "../../../utils/types";
import cm from "./style.module.scss";

const CreativesPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [activeKey, setActiveKey] = useState("ALL");

  const [visible, setVisible] = useState(false);

  const [url, setURL] = useState("");

  const [rejectModalStates, setRejectModalStates] = useState({
    visible: false,
    note: "",
    records: {},
  });

  const close = () => setVisible(false);

  const onRejectOk = () => {
    changeValue(rejectModalStates.records, "REJECTED");
    setRejectModalStates({
      visible: false,
      note: "",
      records: {},
    });
  };

  const onRejectCancel = () => {
    setRejectModalStates({
      visible: false,
      note: "",
      records: {},
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectModalStates({
      ...rejectModalStates,
      note: e.target.value,
    });
  };

  const updateValue = async (data: any) => {
    const response = await api.patch(`creatives/${data.id}`, {
      policy_status: data.updated_status,
      review_note:
        rejectModalStates.note !== "" ? rejectModalStates.note : null,
    });
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (data: any, status: PolicyStatus) => {
    try {
      const createdData = {
        ...data,
        updated_status: status,
      };
      await mutateAsync(createdData);
      queryClient.refetchQueries("table");
    } catch (err: any) {
      console.error(err);
    }
  };

  const okBtn = (records: any) => {
    return (
      <RetailMainButton
        hasBackground
        className={cc(["flex", cm.btn, cm.okBtn])}
        onClick={() => changeValue(records, "ACCEPTED")}
      >
        <CheckOutlined /> {t("common.accept")}
      </RetailMainButton>
    );
  };

  const rejectBtn = (records: any) => {
    const handleReject = () => {
      setRejectModalStates({
        visible: true,
        records,
        note: "",
      });
    };

    return (
      <RetailMainButton
        hasBackground={false}
        className={cc(["flex", cm.btn, cm.rejectBtn])}
        onClick={handleReject}
      >
        {t("common.reject")}
      </RetailMainButton>
    );
  };

  const switchBtn = (value: PolicyStatus, records: any) => {
    switch (value) {
      case "ACCEPTED":
        return rejectBtn(records);
      case "REJECTED":
        return okBtn(records);
      case "PENDING":
        return (
          <>
            {rejectBtn(records)}
            {okBtn(records)}
          </>
        );
    }
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "image_url":
        return (
          <RetailCreativeImgColumn
            onClick={() => {
              setURL(value);
              setVisible(true);
            }}
            value={value}
          />
        );
      case "policy_status":
        return (
          <RetailText
            family="poppins"
            weight="medium"
            size="xxxs"
            className={value ? cm[value.toLowerCase()] : ""}
          >
            {value ? t(`pages.admin.creatives.${value.toLowerCase()}`) : "-"}
          </RetailText>
        );
      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "creatives",
    isRelation: false,
    activeKey,
    renderColumns,
  };

  const tableConfigTab = {
    ...tableConfig,
    defaultFilter: [{ key: "policy_status", op: "eq", value: activeKey }],
  };

  const columns = () => [
    {
      title: "",
      dataIndex: "policy_status",
      render: (value: PolicyStatus, records: any) => (
        <div className={cc(["flex", cm.btnContainer])}>
          {switchBtn(value, records)}
        </div>
      ),
    },
  ];

  return (
    <RetailPageContainer>
      <Tabs
        className="bordered-container"
        activeKey={activeKey}
        onChange={(value) => setActiveKey(value)}
      >
        <Tabs.TabPane key="ALL" tab={t("pages.admin.creatives.all")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columnsForAdditionalRendering={() => columns()}
            tableConfig={tableConfig}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="ACCEPTED" tab={t("pages.admin.creatives.accepteds")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columnsForAdditionalRendering={() => columns()}
            tableConfig={tableConfigTab}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="REJECTED" tab={t("pages.admin.creatives.rejecteds")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columnsForAdditionalRendering={() => columns()}
            tableConfig={tableConfigTab}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="PENDING" tab={t("pages.admin.creatives.pendings")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columnsForAdditionalRendering={() => columns()}
            tableConfig={tableConfigTab}
          />
        </Tabs.TabPane>
      </Tabs>
      <CreativePreviewModal
        type="ad"
        url={url}
        visible={visible}
        onCancel={close}
      />
      <CampaignDetailsModal
        type="REJECT_CREATIVE"
        subtitle={t("pages.admin.creatives.rejectSubtitle")}
        visible={rejectModalStates.visible}
        onOk={onRejectOk}
        onCancel={onRejectCancel}
      >
        <RetailFormInput
          isFocused={rejectModalStates.note !== ""}
          name="note"
          label={t("pages.admin.creatives.note")}
          className="floating"
        >
          <Input.TextArea
            className={cc(["floating", cm.textarea])}
            onChange={handleInputChange}
            value={rejectModalStates.note}
          />
        </RetailFormInput>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default CreativesPage;
