import Card from "antd/lib/card";
import { useTranslation } from "react-i18next";

import { PlusCircleOutlined } from "@ant-design/icons";

import { ReactComponent as SuggestedOutlined } from "../../../assets/icons/suggestedOutlined.svg";
import { FormTable } from "../../../utils/types";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailSuggestionCardProps {
  mode: FormTable;
  title: string;
  text: string;
  onClick: () => void;
  disabled: boolean;
}

const RetailSuggestionCard = ({
  mode,
  title,
  text,
  onClick,
  disabled,
}: RetailSuggestionCardProps) => {
  const { t } = useTranslation();

  return (
    <Card className={cm.cardContainer}>
      <div className={cm.container}>
        <SuggestedOutlined />
        <div className={cm.textContainer}>
          <Card.Meta
            title={
              <div className={cm.meta}>
                <RetailTitle level={5} className={cm.title}>
                  {title}
                </RetailTitle>
              </div>
            }
          />
          <RetailText type="secondary" size="xs">
            {text}
          </RetailText>
        </div>
        <RetailMainButton
          hasBackground
          className={cm.button}
          onClick={onClick}
          data-test={`campaign-form-add-all-${mode.toLowerCase()}`}
          disabled={disabled}
        >
          <PlusCircleOutlined />
          {t("components.suggestionCard.addAll")}
        </RetailMainButton>
      </div>
    </Card>
  );
};

export default RetailSuggestionCard;
