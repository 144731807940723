import Card from "antd/lib/card";

import { SelectCurrency } from "../../../utils/helpers";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailTransactionCardProps {
  title: string;
  data: number | string | { amount: number; date: string } | null;
}

const RetailTransactionCard = ({ title, data }: RetailTransactionCardProps) => {
  return (
    <Card className={cm.cardContainer}>
      <Card.Meta
        title={
          <RetailText size="s" className={cm.title}>
            {title}
          </RetailText>
        }
      />
      {typeof data === "number" || typeof data === "string" ? (
        <RetailText size="md" weight="bold">
          {data ? <SelectCurrency amount={data} /> : "-"}
        </RetailText>
      ) : (
        <>
          <RetailText size="md" weight="bold">
            {data ? <SelectCurrency amount={data.amount} /> : "-"}
          </RetailText>
          <RetailText size="xs" className={cm.date}>
            {data && data.date}
          </RetailText>
        </>
      )}
    </Card>
  );
};

export default RetailTransactionCard;
