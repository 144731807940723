import Col from "antd/lib/col";
import Switch from "antd/lib/switch";
import { useTranslation } from "react-i18next";
import { ReactComponent as StateOutlined } from "../../../../../assets/icons/stateOutlined.svg";
import RetailText from "../../../../../components/Typography/RetailText";
import { useCampaignMutation } from "../../../../../hooks/useCampaignMutation";
import cm from "../style.module.scss";

export interface CampaignDetailsHeaderSwitchProps {
  id: number;
  initial: "ACTIVE" | "PAUSED";
}

const CampaignDetailsHeaderSwitch = ({
  id,
  initial,
}: CampaignDetailsHeaderSwitchProps) => {
  const { t } = useTranslation();

  const { changeValue } = useCampaignMutation("status", id);

  const handleChange = (value: boolean) =>
    value ? changeValue("ACTIVE") : changeValue("PAUSED");

  return (
    <Col>
      <RetailText className={cm.label} size="xs">
        <StateOutlined style={{ marginRight: "0.75rem" }} />
        {`${t("common.table.status")}:`}
      </RetailText>
      <RetailText size="xs">
        {initial === "ACTIVE"
          ? t("common.table.active")
          : t("common.table.stopped")}
      </RetailText>
      <Switch checked={initial === "ACTIVE"} onChange={handleChange} />
    </Col>
  );
};

export default CampaignDetailsHeaderSwitch;
