import Input, { InputProps } from "antd/lib/input";
import cc from "classcat";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SearchOutlined } from "../../../assets/icons/searchOutlined.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import cm from "./style.module.scss";

export interface RetailSearchBarProps extends InputProps {
  fullWidth?: boolean;
  prefix?: boolean;
  addonAfter?: boolean;
  handleClick?: any;
  shadow?: boolean;
  productSearch?: boolean;
  dataTest?: string;
}

const RetailSearchBar = ({
  fullWidth = false,
  prefix = true,
  addonAfter = false,
  shadow = false,
  productSearch = false,
  dataTest = "",
  handleClick,
  className,
  ...inputProps
}: RetailSearchBarProps) => {
  const { t } = useTranslation();
  return (
    <div className={cm.container} data-cy="input-searchbar">
      <Input
        prefix={prefix ? <SearchOutlined /> : ""}
        className={cc([
          cm.search,
          productSearch ? cm.product : "",
          fullWidth ? cm.fullWidth : "",
          "search",
          "flex",
          shadow ? cm.shadow : "",
        ])}
        {...inputProps}
      />
      {addonAfter && (
        <RetailMainButton
          hasBackground
          className={cm.btn}
          onClick={handleClick}
          data-test={dataTest}
        >
          {t("common.add")}
        </RetailMainButton>
      )}
    </div>
  );
};

export default RetailSearchBar;
