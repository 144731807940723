import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import sanityClient from "../client";

const usePostFetch = (type: "academy" | "case_study") => {
  const { i18n } = useTranslation();

  const [post, setPost] = useState<any | null>(null);

  const [loading, setLoading] = useState(true);

  const academy_slug = useMatch("/academy/blog/:title/:slug");

  const case_study_slug = useMatch("/case-study/:slug");

  const slug = type === "academy" ? academy_slug : case_study_slug;

  const fetchPost = async () => {
    await sanityClient
      .fetch(
        `*[slug.current == "${slug?.params.slug}"]{
          title,
          _id,
          slug,
          body,
          conclusion,
          description,
          info,
          goal,
          categories[] -> {
            "title": title.${i18n.language},
            slug
    },
  }`
      )
      .then((data) => setPost(data[0]))
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug?.params.slug, i18n.language]);

  return { post, loading };
};

export default usePostFetch;
