import React from "react";

import { AxisProps } from "@nivo/axes";
import {
	LineSvgProps,
	ResponsiveLine,
} from "@nivo/line";

import RetailText from "../../Typography/RetailText";

export interface WidgetAxis {
  x?: Partial<AxisProps<any>>;
  y?: Partial<AxisProps<any>>;
}
export interface RetailLineChartProps extends LineSvgProps {
  height?: number;
  axisOptions?: WidgetAxis;
}

const RetailLineChart: React.VFC<RetailLineChartProps> = ({
  data,
  height,
  xScale,
  xFormat,
  axisOptions = {},
  ...lineProps
}) => {
  return (
    <div
      style={{
        height: typeof height === "number" ? height : 80,
        width: "100%",
      }}
    >
      <ResponsiveLine
        data={data}
        margin={{
          top: 28,
          right: 0,
          bottom: 10,
          left: 0,
        }}
        xScale={xScale}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        pointLabel="xFormatted"
        pointLabelYOffset={-24}
        enableSlices="x"
        crosshairType="top"
        yFormat=" >-.2f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        colors={["var(--primary-7)"]}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        enableArea={true}
        pointSize={6}
        pointColor={{ theme: "background" }}
        pointBorderWidth={6}
        lineWidth={1}
        pointBorderColor={{ from: "serieColor" }}
        useMesh
        sliceTooltip={({ slice }) => {
          return (
            <div
              className="flex"
              style={{
                background: "var(--primary-7)",
                padding: " 10px 12px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              {slice.points.map((point) => (
                <>
                  <RetailText
                    size="xxxs"
                    style={{
                      color: "white",
                    }}
                  >
                    {point.data.xFormatted}
                  </RetailText>
                  <RetailText
                    style={{
                      color: "white",
                    }}
                    size="xxxs"
                  >
                    {point.serieId}
                  </RetailText>
                  <RetailText
                    weight="bold"
                    size="xxxs"
                    style={{
                      color: "white",
                    }}
                  >
                    {point.data.yFormatted}
                  </RetailText>
                </>
              ))}
            </div>
          );
        }}
        legends={[]}
        {...lineProps}
      />
    </div>
  );
};

export default RetailLineChart;
