import cc from "classcat";

import { ReactComponent as PencilOutlined } from "../../../assets/icons/pencilOutlined.svg";
import { numFormatter } from "../../../utils/helpers";
import RetailText from "../../Typography/RetailText";
import RetailMoneyColumn from "../RetailMoneyColumn";
import cm from "../style.module.scss";

export interface RetailEditableColumnProps {
  value: number;
  onClick?: () => void;
  suggested_bid?: number;
  type?: "money" | "number" | "percent";
  reverse?: boolean;
}

const RetailEditableColumn = ({
  value,
  onClick,
  suggested_bid = 1,
  type = "money",
  reverse = false,
}: RetailEditableColumnProps) => {
  const displayValue = value || suggested_bid;

  const formatValue = (val: number | string): React.ReactNode => {
    if (val === "-") return val;
    else {
      switch (type) {
        case "money":
          return <RetailMoneyColumn value={val} />;
        case "percent":
          return `${parseFloat(val.toString())}%`;
        case "number":
          return numFormatter(val);
        default:
          throw new Error(`Invalid type: ${type}`);
      }
    }
  };

  return (
    <div className={cc(["flex", reverse ? cm.reverse : ""])}>
      <div
        className={cc([cm.iconContainer, cm.editContainer, "flex"])}
        onClick={onClick}
      >
        <PencilOutlined data-cy="edit-bid-icon" />
      </div>
      <RetailText size="xs">{formatValue(displayValue)}</RetailText>
    </div>
  );
};

export default RetailEditableColumn;
