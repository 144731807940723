import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Switch from "antd/lib/switch";
import cc from "classcat";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import useApi from "../../../api";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailSettingsHeader from "../../../components/Layout/RetailSettingsHeader";
import RetailSettingsLayout from "../../../components/Layout/RetailSettingsLayout";
import RetailNotification from "../../../components/Notification";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useSettings from "../../../hooks/useSettings";
import { settingsSuccessNotification } from "../../../utils/helpers";
import cm from "./style.module.scss";

const AdvertiserSettingsPage = () => {
  const { t } = useTranslation();

  const { data } = useSettings("MARKETPLACE");

  const { api } = useApi();

  const [form] = useForm();

  const queryClient = useQueryClient();

  /*   const options = [
    {
      label: "tr",
      value: "TR",
    },
    {
      label: "en",
      value: "EN",
    },
  ]; */

  useEffect(() => {
    form.setFieldsValue({
      status: data?.data.default_advertiser_status === "ACTIVE",
      coupon: data?.data.welcome_coupon,
      /* language: data?.data.language_code, */
      balance: data?.data.balance_permission === "ALLOWED",
    });
  }, [data?.data, form]);

  const updateValue = async (settings: any) => {
    const config = { ...settings };
    const response = await api.patch(`settings`, config);
    return response;
  };

  const { mutateAsync, isLoading } = useMutation(updateValue);

  const changeValue = async (settings: any) => {
    await mutateAsync({ ...settings });
    queryClient.refetchQueries("settings");
  };

  const onFinish = () => {
    const { status, /* language, */ balance } = form.getFieldsValue();
    try {
      changeValue({
        default_seller_status: status === true ? "ACTIVE" : "PAUSED",
        /* language_code: language, */
        balance_permission: balance ? "ALLOWED" : "NOT_ALLOWED",
      });
      settingsSuccessNotification(t);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = () => {
    RetailNotification.showNotification(
      "error",
      t("pages.admin.advertiserSettings.error_title"),
      t("pages.admin.advertiserSettings.error_text")
    );
  };

  const submit = () => form?.submit();

  return (
    <RetailSettingsLayout spinning={isLoading}>
      <RetailSettingsHeader type="advertiser" onClick={submit} />
      <div>
        <Form
          form={form}
          autoComplete="off"
          colon={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
        >
          <div className={cc([cm.flex, cm.container])}>
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.advertiserSettings.status")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.advertiserSettings.statusText")}
              </RetailText>
            </div>
            <div className={cc(["flex", cm.baseline])}>
              <Form.Item name="status" valuePropName="checked">
                <Switch disabled />
              </Form.Item>
              <RetailText size="xs" family="poppins">
                {data?.data.default_advertiser_status === "ACTIVE"
                  ? t("pages.admin.advertiserSettings.open")
                  : t("pages.admin.advertiserSettings.closed")}
              </RetailText>
            </div>
          </div>
          <div className={cc([cm.flex, cm.container])}>
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.advertiserSettings.welcome")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.advertiserSettings.welcomeText")}
              </RetailText>
            </div>
            {data?.data?.welcome_coupon ? (
              <strong className={cm.couponText}>
                <RetailMoneyColumn value={data?.data?.welcome_coupon} />
              </strong>
            ) : null}
          </div>
          {/*       <div
            className={cc([
              "flex",
              cm.justifyContent,
              cm.couponContainer,
              cm.container,
            ])}
          >
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.advertiserSettings.language")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.advertiserSettings.languageText")}
              </RetailText>
            </div>
            <Form.Item name="language">
              <RetailSelect
                className={cm.select}
                options={options.map((language) => ({
                  label: t(`common.${language.label}`),
                  value: language.value,
                }))}
              />
            </Form.Item>
          </div> */}

          <div className={cc([cm.flex, cm.container])}>
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.advertiserSettings.balance")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.advertiserSettings.balanceText")}
              </RetailText>
            </div>
            <div className={cc(["flex", cm.baseline])}>
              <Form.Item name="balance" valuePropName="checked">
                <Switch disabled />
              </Form.Item>
              <RetailText size="xs" family="poppins">
                {data?.data.balance_permission === "ALLOWED"
                  ? t("pages.admin.advertiserSettings.open")
                  : t("pages.admin.advertiserSettings.closed")}
              </RetailText>
            </div>
          </div>
        </Form>
      </div>
    </RetailSettingsLayout>
  );
};

export default AdvertiserSettingsPage;
