import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as AddUserOutlined } from "../../../assets/icons/addUserOutlined.svg";
import useTableBulkEdit from "../../../hooks/useTableBulkEdit";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailSuccessModal from "../../Modal/RetailSuccessModal";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailInviteBarProps {
  handleRowkeys(): void;
  selectedRowKeys: any;
  activeKey: string;
}

const RetailInviteBar = ({
  handleRowkeys,
  selectedRowKeys,
  activeKey,
}: RetailInviteBarProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [url, setURL] = useState("");

  const { changeValue, successModalVisible, setSuccessModalVisible } =
    useTableBulkEdit(
      { url: url, isRelation: false },
      selectedRowKeys,
      handleRowkeys
    );

  const setURLAndCleanState = (url: string) => {
    setURL(url);
    const timer = setTimeout(() => {
      setURL("");
    }, 1000);
    return () => clearTimeout(timer);
  };

  const invite = () => setURLAndCleanState("invite-multiple-user");

  const activate = () => setURLAndCleanState("advertisers");

  const archive = () => setURLAndCleanState("advertisers");

  useEffect(() => {
    if (url === "invite-multiple-user") changeValue("INVITE");
    if (url === "advertisers" && activeKey !== "ARCHIVED")
      changeValue("ARCHIVED");
    if (url === "advertisers" && activeKey === "ARCHIVED")
      changeValue("ACTIVE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const ArchiveSection = () => {
    return (
      <>
        {activeKey === "ARCHIVED" ? (
          <button className={cc([cm.btn, cm.archive])} onClick={activate}>
            {t("components.bulkUpdate.list")}
          </button>
        ) : (
          <button className={cc([cm.btn, cm.archive])} onClick={archive}>
            {t("components.bulkUpdate.archive")}
          </button>
        )}
      </>
    );
  };

  const InviteButton = () => {
    return (
      <Col>
        <RetailMainButton hasBackground className={cm.btn} onClick={invite}>
          {t("components.invite.invite")}
        </RetailMainButton>
      </Col>
    );
  };

  const ReInviteButton = () => {
    return (
      <Col>
        <button
          className={cc([cm.btn, cm.archive, cm.reinvite])}
          onClick={invite}
        >
          <AddUserOutlined />
          {t("components.invite.reinvite")}
        </button>
      </Col>
    );
  };

  const isAdvertisers = pathname === "/admin/advertisers";

  return (
    <>
      {selectedRowKeys.length > 0 && (
        <Row className={cm.container}>
          <Col className={cm.tag}>
            <RetailText size="xxxs" weight="medium" family="poppins">
              {t("components.invite.selected", {
                value: selectedRowKeys.length,
              })}
            </RetailText>
          </Col>
          {isAdvertisers ? <ArchiveSection /> : <ReInviteButton />}
          {isAdvertisers && activeKey !== "ARCHIVED" && <InviteButton />}
        </Row>
      )}
      {isAdvertisers && url === "invite-multiple-user" && (
        <RetailSuccessModal
          type="multiple_advertisers"
          visible={successModalVisible}
          setVisible={setSuccessModalVisible}
        />
      )}
    </>
  );
};

export default RetailInviteBar;
