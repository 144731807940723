import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import FilterContext from "../../../context/FilterProvider";
import { FilterTag } from "../../../utils/types";
import RetailInfoTag from "../RetailInfoTag/index";

export interface RetailFilterTagProps {
  tag: any;
  type: FilterTag;
  closable?: boolean;
  isReportResult?: boolean;
}

const RetailFilterTag = ({
  tag,
  type,
  closable = true,
  isReportResult = false,
}: RetailFilterTagProps) => {
  const { t, i18n } = useTranslation();

  const { tags, setTags } = useContext(FilterContext);

  const handleDelete = () =>
    setTags(tags.filter((item) => item.selectedFilter !== tag.selectedFilter));

  const irritableTag = () => {
    if (isReportResult) {
      return {
        selectedFilter: tag.key,
        equality: tag.op,
        selectedValue: tag.value,
      };
    }
    return tag;
  };

  const { selectedFilter, equality, selectedValue } = irritableTag();

  const date = Array.isArray(selectedValue) ? selectedValue[0] : selectedValue;

  const isExist = i18n.exists(`common.filters.${selectedValue}`);

  const formattedValue = isExist
    ? t(`common.values.${selectedValue}`)
    : selectedFilter.includes("Date")
    ? moment(date).format("DD/MM/YY")
    : selectedValue;

  const selectedFilterExist = i18n.exists(`common.filters.${selectedFilter}`);

  const renderFormattedFilter = selectedFilterExist
    ? t(`common.filters.${selectedFilter}`)
    : t(`marketplaceDependentTable.${selectedFilter}`);

  return (
    <RetailInfoTag
      type={type}
      key={irritableTag().id}
      onClose={handleDelete}
      closable={closable}
    >
      <span>{`${renderFormattedFilter} /
                    ${t(`common.equalityValues.${equality}`)} /
                    ${formattedValue}`}</span>
    </RetailInfoTag>
  );
};

export default RetailFilterTag;
