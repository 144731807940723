import Col from "antd/lib/col";
import ConfigProvider from "antd/lib/config-provider";
import Row from "antd/lib/row";
import Table from "antd/lib/table";
import { useTranslation } from "react-i18next";

import { ReactComponent as FilterOutlined } from "../../../../assets/icons/filterOutlined.svg";
import RetailExportBottomBar from "../../../../components/Bar/RetailExportBottomBar";
import RetailPaginationButtons from "../../../../components/Button/RetailPaginationButtons";
import RetailFilterTag from "../../../../components/Tag/RetailFilterTag";
import RetailText from "../../../../components/Typography/RetailText";
import { localeLanguages } from "../../../../utils/helpers";
import cm from "../style.module.scss";

type setSort = {
  sortValue: string;
  order: string;
} | null;
export interface ReportDetailsResultTabProps {
  data: any;
  setSort(value: setSort): void;
  setIsExported(value: boolean): void;
  setFormat(value: "excel" | "pdf" | "csv"): void;
  changePageSize(current: number, value: number): void;
  colTitle(value: string): string;
  switchColumn(value: any, col: string): any;
  isLoading: boolean;
}

const ReportDetailsResultTab = ({
  data,
  changePageSize,
  setSort,
  setIsExported,
  setFormat,
  colTitle,
  switchColumn,
  isLoading,
}: ReportDetailsResultTabProps) => {
  const { t, i18n } = useTranslation();

  const columns =
    data !== undefined
      ? data.data.columns.map((col: string) => ({
          dataIndex: col,
          title: colTitle(col),
          sorter: col,
          render: (value: any, records: any) => (
            <>{value !== null ? switchColumn(value, col) : "-"}</>
          ),
        }))
      : [];

  const tableExport = (value: "excel" | "pdf" | "csv") => {
    setFormat(value);
    setIsExported(true);
  };

  return (
    <>
      {data && data.data.report_filters !== null && (
        <Row className={cm.tagContainer}>
          <Col className="flex">
            <FilterOutlined />
            <RetailText size="xs" family="poppins">
              {t("components.reportForm.filters")}
            </RetailText>
          </Col>
          <Col className="flex">
            {data.data.report_filters.map((tag: any) => (
              <RetailFilterTag
                tag={tag}
                type="FILTER"
                key={tag.id}
                closable={false}
                isReportResult={true}
              />
            ))}
          </Col>
        </Row>
      )}
      <ConfigProvider locale={localeLanguages(i18n.language)}>
        <div className={cm.wrapper}>
          <Table
            dataSource={data ? data.data.records : []}
            columns={columns}
            showSorterTooltip={false}
            loading={isLoading}
            pagination={{
              total: data?.data.total_records,
              showSizeChanger: true,
              showTotal: (total, range) => t("common.pageTotal"),
              itemRender(page, type, element) {
                return (
                  <RetailPaginationButtons type={type} element={element} />
                );
              },
            }}
            sortDirections={["descend", "ascend", null]}
            onChange={(pagination, filters, sorter: any) => {
              sorter.order !== undefined && sorter.order !== null
                ? setSort({
                    sortValue: sorter.field,
                    order: sorter.order === "ascend" ? "asc" : "desc",
                  })
                : setSort(null);
              changePageSize(pagination.current!, pagination.pageSize!);
            }}
            scroll={{ x: true }}
          />

          <RetailExportBottomBar tableExport={tableExport} />
        </div>
      </ConfigProvider>
    </>
  );
};

export default ReportDetailsResultTab;
