import graph from "../../../../../assets/images/landing_page/graph.png";
import green from "../../../../../assets/images/landing_page/mid-green.png";
import orange from "../../../../../assets/images/landing_page/mid-orange.png";
import purple from "../../../../../assets/images/landing_page/mid-purple.png";
import radio from "../../../../../assets/images/landing_page/radio.png";
import tri from "../../../../../assets/images/landing_page/tri.png";
import widget from "../../../../../assets/images/landing_page/widget.png";
import cm from "./style.module.scss";

const TeknosaLandingMid = () => {
  const cardData = [
    {
      icon: green,
      title: "Satışlarda Artış",
      text: "Teknosa Ads ile ürün reklamlarınızın daha geniş kitlelere ulaşmasını sağlayarak, hem marka bilinirliği hem de satışlarda yüksek verim elde edebilirsiniz.",
      img: graph,
    },
    {
      icon: purple,
      title: "Şeffaf Verilerle Kontrol Sizde",
      text: "Reklam kampanyalarınız hakkında ayrıntılı veri analizleri ve bilgiler edinerek gelecek kampanyalarınızı optimize edebilirsiniz.",
      img: widget,
    },
    {
      icon: orange,
      title: "Rakiplerinizden Öne Çıkın",
      text: "Ulaşmak istediğiniz kitlelere demografik ve davranışsal hedeflemeler yaparak reklamlarınızdan elde edebileceğiniz dönüşümleri büyük ölçüde artırabilirsiniz.",
      img: radio,
    },
  ];
  return (
    <section className={cm.container}>
      <img src={tri} alt="" />
      <h2>
        Etkili reklamlar ile <span>görünürlüğünüzü</span> artırın.
      </h2>
      <div>
        {cardData.map((card, index) => (
          <article key={index} className={cm.card}>
            <img src={card.icon} alt="" className={cm.cardIcon} />
            <h3>{card.title}</h3>
            <p>{card.text}</p>
            <img src={card.img} alt="" className={cm.mainImg} />
          </article>
        ))}
      </div>
    </section>
  );
};

export default TeknosaLandingMid;
