export const marketplaceName =
  (window as any).marketplaceConfig?.name?.toLowerCase() || "ACME";

export const bg_mode =
  (window as any).marketplaceConfig?.background_type?.toLowerCase() || "light";

export const currency =
  (window as any).marketplaceConfig?.currency_code || "USD";

// Get the user's browser language.
const browserLanguage = navigator?.language?.slice(0, 2);
// If the browser language isn't Turkish, we want to use English as the fallback language.
const fallbackLanguage = browserLanguage === "tr" ? "tr" : "en";
// Check if a language is configured in the config.
// If not, use the fallback language determined above.
export const noLanguageSelected =
  (window as any)?.marketplaceConfig?.language_code?.toLowerCase() ||
  fallbackLanguage;

export const marketplaceLogo = (window as any).marketplaceConfig?.logo;
