import Row, { RowProps } from "antd/lib/row";
import cc from "classcat";

import cm from "./style.module.scss";

export interface RetailFormRowProps extends RowProps {
  children: React.ReactNode;
  hasBackground?: boolean;
}

const RetailFormRow = ({
  children,
  hasBackground = false,
  className,
  ...rowProps
}: RetailFormRowProps) => {
  return (
    <Row
      className={cc([cm.row, hasBackground ? cm.bg : "", className || ""])}
      {...rowProps}
    >
      {children}
    </Row>
  );
};

export default RetailFormRow;
