import { ReactComponent as SearchOutlined } from "../../../assets/icons/searchOutlined.svg";
import cm from "./style.module.scss";

export interface RetailCreativeImgColumnProps {
  onClick(): void;
  value: string;
}

const RetailCreativeImgColumn = ({
  onClick,
  value,
}: RetailCreativeImgColumnProps) => {
  return (
    <div className="flex">
      <div className={cm.openBtn}>
        <SearchOutlined onClick={onClick} />
      </div>
      <img src={value} alt="" className={cm.img} />
    </div>
  );
};

export default RetailCreativeImgColumn;
