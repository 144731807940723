import Button from "antd/lib/button";
import DatePicker from "antd/lib/date-picker";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormItemProps } from "antd";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightOutlined.svg";
import RetailFormInput from "../RetailFormInput";
import { datePickerLocale } from "../../../utils/helpers";

export interface RetailEndDatePickerProps
  extends PickerDateProps<any>,
    Pick<FormItemProps<string>, "rules"> {
  handleChange(value: any, name: string): void;
  isFocused: boolean;
  modal?: boolean;
  onClose(): void;
  type: "campaigns" | "reports" | "campaign_details";
  startDate: any;
  small?: boolean;
}

const RetailEndDatePicker = ({
  handleChange,
  isFocused,
  onClose,
  type,
  modal = false,
  small = false,
  startDate,
  rules,
  ...pickerDateProps
}: RetailEndDatePickerProps) => {
  const { t, i18n } = useTranslation();

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const datePickerRef = useRef<any>(null);

  const closeEndDatePicker = () => {
    setDatePickerOpen(false);
    datePickerRef!.current!.blur();
    handleChange("", "end_date");
  };

  const handleClose = () => {
    closeEndDatePicker();
    onClose();
  };

  const handleDateCheck = (date: Moment) => {
    switch (type) {
      case "campaigns":
      case "reports":
        return moment(startDate).isAfter(date);
      case "campaign_details":
        return date < moment().startOf("day");
    }
  };

  const close = () => setDatePickerOpen(false);

  const toggle = () => setDatePickerOpen((prev) => !prev);

  return (
    <RetailFormInput
      isFocused={isFocused || datePickerOpen}
      label={t("components.endDatePicker.label")}
      help={small || modal ? undefined : t(`components.endDatePicker.${type}`)}
      className="floating"
      style={{ marginTop: small || modal ? 0 : "25px" }}
      name="end_date"
      rules={rules}
    >
      <DatePicker
        onChange={(value) =>
          handleChange(value ? value?.toString() : "", "end_date")
        }
        onClick={toggle}
        onSelect={handleClose}
        onBlur={close}
        placeholder=""
        className={`floating end-date-picker ${
          small ? "date-picker-small" : ""
        }`}
        format="DD/MM/YYYY"
        showToday={false}
        disabledDate={handleDateCheck}
        disabled={startDate === ""}
        ref={datePickerRef}
        prevIcon={<LeftOutlined />}
        nextIcon={<RightOutlined />}
        open={datePickerOpen}
        locale={datePickerLocale(i18n.language)}
        renderExtraFooter={() => (
          <Button onClick={handleClose}>
            {t("components.endDatePicker.button")}
          </Button>
        )}
        {...pickerDateProps}
      />
    </RetailFormInput>
  );
};

export default RetailEndDatePicker;
