import { useTranslation } from "react-i18next";
import { switchFlag } from "../../../utils/helpers";
import RetailText from "../../Typography/RetailText";
import RetailTooltip from "../../Tooltip/RetailTooltip";

import cm from "./style.module.scss";

interface RetailCreativeLanguageColumnProps {
  value: string[];
}

const RetailCreativeLanguageColumn = ({
  value,
}: RetailCreativeLanguageColumnProps) => {
  const { t } = useTranslation();

  if (!value || value.length === 0) return <></>;

  if (value?.length > 3)
    return (
      <RetailText size="xs" family="poppins" className={cm.text}>
        {t("pages.acc.campaignDetails.languages", {
          value: value.length,
        })}
      </RetailText>
    );

  return (
    <div className={cm.container}>
      {value?.map((lang: string) => (
        <RetailTooltip
          title={t(`common.languages.${lang?.toLowerCase()}`)}
          key={lang}
        >
          <RetailText className={cm.flag}>{switchFlag(lang)}</RetailText>
        </RetailTooltip>
      ))}
    </div>
  );
};

export default RetailCreativeLanguageColumn;
