import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkSuccessOutlined.svg";
import { ReactComponent as KeyOutlined } from "../../../assets/icons/keyOutlined.svg";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { getLanguageCode, getSubstring } from "../../../utils/helpers";
import axios from "axios";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import RetailTitle from "../../../components/Typography/RetailTitle";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import RetailNotification from "../../../components/Notification";
import cc from "classcat";
import cm from "./style.module.scss";

const NewPasswordPage = () => {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const [success, setSuccess] = useState(false);

  const access_token = getSubstring(window.location.href, "=", "&");

  const onFinish = async () => {
    try {
      await axios.patch(
        "api/users/reset-password",
        {
          access_token: access_token,
          password: form.getFieldValue("password"),
          password_verify: form.getFieldValue("second_password"),
        },
        {
          headers: {
            "Accept-Language": getLanguageCode(i18n.language),
          },
        }
      );
      setSuccess(true);
    } catch (err) {
      RetailNotification.showNotification(
        "error",
        t("pages.auth.newPassword.errTitle"),
        t("pages.auth.newPassword.help")
      );
    }
  };

  return (
    <RetailAuthLayout>
      {success ? (
        <section className={cc(["flex", cm.success])}>
          <RetailTitle level={2} className={cc(["flex", cm.check])}>
            <span className={cc(["flex", cm.icon])}>
              <CheckOutlined />
            </span>
            <span>{t("pages.auth.newPassword.check")}</span>
          </RetailTitle>
          <article className="flex">
            <p>{t("pages.auth.newPassword.message")}</p>
            <RetailMainButton hasBackground className={cm.btn}>
              <Link to="/login">{t("common.okay")}</Link>
            </RetailMainButton>
          </article>
        </section>
      ) : (
        <div className={cm.form}>
          <section className={cc(["flex", cm.textContainer])}>
            <div className={cc(["flex", cm.iconContainer])}>
              <KeyOutlined />
            </div>
            <article>
              <h1>{t("pages.auth.newPassword.title")}</h1>
              <p>{t("pages.auth.newPassword.text")}</p>
            </article>
          </section>
          <Form form={form} onFinish={onFinish} autoComplete="off">
            <Form.Item
              label=""
              name="password"
              className={cm.formItem}
              rules={[
                {
                  required: true,
                  message: t("pages.auth.newPassword.passwordInputErr"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("pages.auth.newPassword.password")}
              />
            </Form.Item>
            <p className={cm.help}>{t("pages.auth.newPassword.help")}</p>
            <Form.Item
              label=""
              name="second_password"
              className={cm.formItem}
              rules={[
                {
                  required: true,
                  message: t("pages.auth.newPassword.passwordInputErr"),
                },
              ]}
            >
              <Input.Password placeholder={t("pages.auth.newPassword.again")} />
            </Form.Item>
            <Form.Item>
              <RetailMainButton
                hasBackground
                htmlType="submit"
                className={cm.btn}
              >
                {t("pages.auth.password.reset")}
              </RetailMainButton>
            </Form.Item>
            <div className={cm.return}>
              <LeftOutlined />
              <Link to="/login">{t("pages.auth.password.return")}</Link>
            </div>
          </Form>
        </div>
      )}
    </RetailAuthLayout>
  );
};

export default NewPasswordPage;
