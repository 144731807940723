import Typography from "antd/lib/typography";
import { TextProps } from "antd/lib/typography/Text";
import cc from "classcat";
import React from "react";

import cm from "./style.module.scss";

export interface RetailTextProps extends TextProps {
  /**
   * Determines font-size. Default is s
   */
  size?: "xxxs" | "xxs" | "xs" | "s" | "md" | "lg" | "xl" | "xxl";
  /**
   * determines font-weight. Default is regular
   */
  weight?: "light" | "regular" | "medium" | "bold";
  /**
   * Determines font family. Default is Eudoxus Sans
   */
  family?: "eudoxus" | "poppins";
}

const RetailText: React.FC<RetailTextProps> = ({
  children,
  size = "s",
  weight = "regular",
  family = "eudoxus",
  className,
  ...textProps
}) => {
  const { Text } = Typography;
  return (
    <Text
      {...textProps}
      className={cc([
        cm[`font-${size}`],
        cm[`font-${weight}`],
        cm[`font-${family}`],
        className || "",
      ])}
    >
      {children}
    </Text>
  );
};

export default RetailText;
