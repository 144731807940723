import { useLocation, useNavigate } from "react-router-dom";

const useOnboardingNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const currentStep = parseInt(searchParams.get("q") || "1", 10);

  const updateStepInSearchParams = (newStep: number) => {
    searchParams.set("q", newStep.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    updateStepInSearchParams(nextStep);
  };

  const goToPreviousStep = () => {
    const previousStep = currentStep - 1;
    updateStepInSearchParams(previousStep);
  };

  return { currentStep, goToNextStep, goToPreviousStep };
};

export default useOnboardingNavigate;
