import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios from "axios";
import { ReactComponent as ArrowRightOutlined } from "../../../../assets/icons/arrowRightOutlined.svg";
import { ReactComponent as LinkedinFilled } from "../../../../assets/icons/linkedinFilled.svg";
import { useOnboardingStepAction } from "../../../../hooks/useOnboardingStepAction";
import { cleanAllData } from "../../../../utils/helpers";
import useOnboardingApi from "../../../../api/onboarding";
import useOnboardingNavigate from "../../../../hooks/useOnboardingNavigate";
import useOnboardingUser from "../../../../hooks/useOnboardingUser";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import RetailOnboardingCountContainer from "../../../../components/Container/RetailOnboardingCountContainer";
import img from "../../../../assets/images/onboarding/first-step.png";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailText from "../../../../components/Typography/RetailText";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import RetailSelect from "../../../../components/Select/RetailSelect";
import RetailNotification from "../../../../components/Notification";

import cm from "./style.module.scss";

const OnboardingSecondStep = () => {
  const { t } = useTranslation();

  const { currentStep, goToNextStep } = useOnboardingNavigate();

  const { getAction, markStepAsVisited } = useOnboardingStepAction(currentStep);

  const { onboardingApi } = useOnboardingApi();

  const { onboardingUser, loading: userLoading } = useOnboardingUser();

  const navigate = useNavigate();

  const [form] = useForm();

  const [formFields, setFormFields] = useState<{
    marketplace_url: string;
    user_password: string;
    marketplace_type: string | null;
    user_email: string;
    user_name: string;
    user_picture_url: string;
  }>({
    marketplace_url: "",
    user_password: "",
    marketplace_type: "",
    user_email: "",
    user_name: "",
    user_picture_url: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setFormFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleTypeChange = (value: string) =>
    setFormFields({ ...formFields, marketplace_type: value });

  const createMarketplace = async () => {
    const {
      marketplace_url,
      user_password,
      marketplace_type,
      user_email,
      user_name,
      user_picture_url,
    } = formFields;

    try {
      setLoading(true);
      const response = await axios.post("api/onboarding", {
        user_email,
        user_name,
        user_picture_url,
        marketplace_url,
        user_password,
        user_password_verify: user_password,
        marketplace_type,
      });

      markStepAsVisited();

      localStorage.setItem(
        "auth_token",
        JSON.stringify(response.data.jwt_token)
      );

      localStorage.setItem(
        "marketplace_id",
        JSON.stringify(response.data.marketplace_id)
      );

      goToNextStep();
    } catch (error) {
      console.error(error);
      RetailNotification.showNotification(
        "error",
        t("pages.auth.signUp.err"),
        t("pages.auth.signUp.warning")
      );
    } finally {
      setLoading(false);
    }
  };

  const updateMarketplace = async () => {
    const marketplaceID = localStorage.getItem("marketplace_id");

    const parsedMarketplaceID = JSON.parse(marketplaceID || "");

    const { marketplace_url, user_password, marketplace_type } = formFields;

    if (onboardingApi && parsedMarketplaceID !== "") {
      try {
        setLoading(true);
        await onboardingApi.patch("", {
          marketplace_url,
          user_password,
          user_password_verify: user_password,
          marketplace_type,
        });
        goToNextStep();
      } catch (error) {
        console.error(error);
        RetailNotification.showNotification(
          "error",
          t("pages.auth.signUp.err"),
          t("pages.auth.signUp.warning")
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const onFinish = async () => {
    const action = getAction();
    if (action === "post") createMarketplace();
    else if (action === "patch") updateMarketplace();
  };

  const changeAccount = () => {
    cleanAllData();
    navigate("/onboarding");
  };

  const isDataAvailable = (
    field: "user_picture_url" | "user_name" | "user_email"
  ) => formFields[field] !== "";

  useEffect(() => {
    setFormFields({
      marketplace_url: onboardingUser?.marketplace_url || "",
      user_password: "",
      marketplace_type: onboardingUser?.marketplace_type || null,
      user_email: onboardingUser?.user_email || "",
      user_name: onboardingUser?.user_name || "",
      user_picture_url: onboardingUser?.user_linkedin_picture_url || "",
    });

    form.setFieldsValue({
      marketplace_url: onboardingUser?.marketplace_url || "",
      user_password: "",
      marketplace_type: onboardingUser?.marketplace_type,
    });

    try {
      const storedValue = localStorage.getItem("linkedin");
      if (storedValue) {
        const { email, name, picture_url } = JSON.parse(storedValue);
        setFormFields((prevState) => ({
          ...prevState,
          user_email: email,
          user_name: name,
          user_picture_url: picture_url,
        }));
      }
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
    }
  }, [onboardingUser, form]);

  if (userLoading) return <Spin size="large" spinning={userLoading} />;

  return (
    <>
      <div className={`flex ${cm.container}`}>
        <section className={cm.side}>
          <article className={cm.userInfo}>
            {isDataAvailable("user_picture_url") && (
              <img src={formFields.user_picture_url} alt="Profile" />
            )}

            <div className={cm.infoTextContainer}>
              {isDataAvailable("user_name") && (
                <RetailText className={cm.userName} size="xs" weight="medium">
                  {formFields.user_name}
                </RetailText>
              )}
              {isDataAvailable("user_email") && (
                <RetailText
                  className={cm.userEmail}
                  size="xxxs"
                  weight="medium"
                >
                  {formFields.user_email}
                </RetailText>
              )}
            </div>
            <RetailMainButton
              hasBackground={false}
              className={cm.changeAccountBtn}
              onClick={changeAccount}
            >
              {t("pages.auth.onboarding.changeAccount")}
              <LinkedinFilled className={cm.linkedinIcon} />
            </RetailMainButton>
          </article>
          <RetailOnboardingCountContainer />
          <RetailTitle className={cm.title} level={3}>
            {t("pages.auth.onboarding.createTitle")}
          </RetailTitle>
          <RetailText className={cm.subtitle} size="xs" weight="medium">
            {t("pages.auth.onboarding.createSubtitle")}
          </RetailText>
          <Form
            form={form}
            requiredMark={false}
            className={cm.form}
            onFinish={onFinish}
            autoComplete="off"
            autoSave="off"
          >
            <RetailFormInput
              isFocused={formFields.marketplace_url !== ""}
              label={t("pages.auth.onboarding.websiteLabel")}
              name="marketplace_url"
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    const regex =
                      /^(https?:\/\/)?([a-z\u0600-\u06FF0-9](?:[a-z\u0600-\u06FF0-9-]{0,61}[a-z\u0600-\u06FF0-9])?\.)+[a-z\u0600-\u06FF]{2,10}\/?$/i;
                    if (!value || !regex.test(value) || value.length > 128) {
                      return Promise.reject();
                    } else return Promise.resolve();
                  },
                },
              ]}
              className={`floating ${cm.inputItem}`}
              help={t("pages.auth.onboarding.websiteHelp")}
            >
              <Input
                onChange={handleChange}
                className="floating"
                name="marketplace_url"
                value={formFields.marketplace_url}
              />
            </RetailFormInput>
            <Form.Item
              label=""
              name="user_password"
              rules={[{ required: true }]}
              className={`${cm.inputItem} ${cm.passwordInput}`}
              help={t("pages.auth.onboarding.passwordHelp")}
            >
              <Input.Password
                onChange={handleChange}
                name="user_password"
                value={formFields.user_password}
                type="password"
                placeholder={t("pages.auth.onboarding.passwordLabel")}
              />
            </Form.Item>
            <Form.Item
              label={""}
              name="marketplace_type"
              rules={[{ required: true }]}
              help={t("pages.auth.onboarding.typeHelp")}
              className={cm.selectItem}
            >
              <RetailSelect
                onChange={handleTypeChange}
                value={formFields.marketplace_type}
                className={cm.select}
                placeholder={t("pages.auth.onboarding.typeLabel")}
                options={[
                  {
                    value: "CLOTHING",
                    label: t("pages.auth.onboarding.clothing"),
                  },
                  { value: "TECH", label: t("pages.auth.onboarding.tech") },
                  {
                    value: "GROCERY",
                    label: t("pages.auth.onboarding.grocery"),
                  },
                ]}
              />
            </Form.Item>

            <RetailMainButton
              className={cm.submitBtn}
              htmlType="submit"
              hasBackground
              loading={loading}
            >
              {t("pages.auth.onboarding.continue")}
              <ArrowRightOutlined />
            </RetailMainButton>
          </Form>
        </section>
      </div>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.firstStepText")}
        sideImg={img}
        step="second"
      />
    </>
  );
};

export default OnboardingSecondStep;
