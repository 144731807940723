import { useTranslation } from "react-i18next";
import { DrawerProps } from "antd";
import RetailDrawer from "../../../../../components/Drawer/RetailDrawer";
import MultiRegion from "../../../../../components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/MultiRegion";

interface CampaignDetailsRegionsProps extends DrawerProps {
  regions: any[];
  selectedRegions: number[];
  multiRegion: string;
  error: boolean;
  visible: boolean;
  handleFieldChange(e: any): void;
  onOk(): void;
  setSelectedRegions(e: any): void;
}

const CampaignDetailsRegions = ({
  regions,
  selectedRegions,
  multiRegion,
  error,
  visible,
  handleFieldChange,
  onOk,
  setSelectedRegions,
  ...props
}: CampaignDetailsRegionsProps) => {
  const { t } = useTranslation();
  return (
    <RetailDrawer
      visible={visible}
      title={t("pages.acc.campaignDetails.regionsDrawerTitle")}
      onOk={onOk}
      {...props}
    >
      <MultiRegion
        regions={regions}
        page="details"
        selectedRegions={selectedRegions}
        multiRegion={multiRegion}
        error={error}
        handleFieldChange={handleFieldChange}
        setSelectedRegions={setSelectedRegions}
      />
    </RetailDrawer>
  );
};

export default CampaignDetailsRegions;
