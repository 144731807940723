import { Spin } from "antd";
import PortableText from "react-portable-text";
import { Link } from "react-router-dom";

import imageUrlBuilder from "@sanity/image-url";

import { ReactComponent as Icon } from "../../../../assets/images/landing_page/icon.svg";
import sanityClient, { id } from "../../../../client";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import usePostFetch from "../../../../hooks/usePostFetch";
import TeknosaLandingFooter from "../TeknosaLayout/TeknosaLandingFooter";
import TeknosaLandingNavbar from "../TeknosaLayout/TeknosaLandingNavbar";
import cm from "./style.module.scss";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source: any) {
  return builder.image(source);
}

export const TeknosaCaseStudyTemplate = () => {
  const { post, loading } = usePostFetch("case_study");

  if (loading) return <Spin />;

  return (
    <>
      <TeknosaLandingNavbar />
      {post ? (
        <main className={cm.container}>
          <article className={cm.goalContainer}>
            <div>
              <h1>AMAÇ</h1>
              <p className={cm.goal}>{post?.goal}</p>
            </div>
          </article>
          <div className={cm.body}>
            <h2>Çözüm</h2>
            <PortableText
              content={post?.body}
              dataset="production"
              projectId={id}
              serializers={{
                image: (props: any) => (
                  <div className={cm.imageContainer}>
                    <img
                      src={urlFor(props).url()}
                      alt={post?.title}
                      className={cm.image}
                    />
                  </div>
                ),
              }}
            />
          </div>

          <div className={cm.conclusion}>
            <h2>SONUÇ</h2>
            <PortableText
              content={post?.conclusion}
              dataset="production"
              projectId={id}
              serializers={{
                image: (props: any) => (
                  <div className={cm.imageContainer}>
                    <img
                      src={urlFor(props).url()}
                      alt={post?.title}
                      className={cm.image}
                    />
                  </div>
                ),
                li: ({ children }: any) => (
                  <li className={cm.smallCard}>
                    <Icon />
                    <span>{children}</span>
                  </li>
                ),
              }}
            />
          </div>
        </main>
      ) : (
        <section
          className="flex"
          style={{
            flexDirection: "column",
            minHeight: "40vh",
            justifyContent: "center",
          }}
        >
          <RetailTitle level={5}>Makale Bulunamadı.</RetailTitle>
          <Link to="/case-study" className={cm.btn}>
            Geri Dön
          </Link>
        </section>
      )}

      <TeknosaLandingFooter sectionVisible={false} />
    </>
  );
};
