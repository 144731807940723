import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import RetailTable from "../../../../components/Table/RetailTable";
import { placementFilters } from "../../../../utils/filters";

const AdPlacementTable = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType="PLACEMENTS"
          />
        );
      case "text":
        return t(`pages.acc.campaignDetails.${value?.toLowerCase()}`);
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "PLACEMENTS",
    id: id ? parseInt(id) : 1,
    filters: placementFilters(t),
    renderColumns,
  };

  return (
    <RetailTable
      placeholder={t("pages.acc.campaignDetails.placementPlaceholder")}
      tableConfig={tableConfig}
      addBtnVisible={false}
    />
  );
};

export default AdPlacementTable;
