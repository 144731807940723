import { useTranslation } from "react-i18next";

import QuestionCircleFilled from "@ant-design/icons/lib/icons/QuestionCircleFilled";

import { StateColumns } from "../../../utils/types";
import RetailTableTag from "../../Tag/RetailTableTag";
import RetailTooltip from "../../Tooltip/RetailTooltip";
import RetailText from "../../Typography/RetailText";
import cm from "../style.module.scss";

export interface RetailStateColumnProps {
  value: any;
  type: StateColumns;
  review_note?: string;
}

const RetailStateColumn = ({
  value,
  type,
  review_note,
}: RetailStateColumnProps) => {
  const { t } = useTranslation();

  const isTooltipVisible = value !== "ON_PUBLISH" && value !== undefined;

  const TooltipTitle = () => {
    const innerText = review_note
      ? "creatives_note"
      : `${type}_${value.toLowerCase()}`;

    return (
      <div className={review_note ? cm.noteContainer : cm.tooltipContainer}>
        <span className={review_note ? cm.noteTitle : ""}>
          {t(`components.states.${innerText}`)}
        </span>
        {review_note && <span>{review_note}</span>}
      </div>
    );
  };

  const switchMode = () => {
    switch (value) {
      case "ON_PUBLISH":
        return "green";
      case "REJECTED":
        return "unfilledRed";
      default:
        return "unfilledYellow";
    }
  };

  const switchText = () => {
    switch (value) {
      case "ON_PUBLISH":
        return t("common.table.on");
      case "REJECTED":
        return t("pages.admin.creatives.rejected");
      default:
        return t("common.table.stopped");
    }
  };

  return (
    <RetailTableTag mode={switchMode()}>
      <RetailText weight="bold" family="poppins" size="xxxs">
        {switchText()}
        {isTooltipVisible && (
          <RetailTooltip title={<TooltipTitle />}>
            <QuestionCircleFilled style={{ marginLeft: "6px" }} />
          </RetailTooltip>
        )}
      </RetailText>
    </RetailTableTag>
  );
};

export default RetailStateColumn;
