import { useTranslation } from "react-i18next";

import RetailAdvertiserViewLink from "../../Link/RetailAdvertiserViewLink";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailAcademyCategoryCardProps {
  category: any;
}

const RetailAcademyCategoryCard = ({
  category,
}: RetailAcademyCategoryCardProps) => {
  const { t, i18n } = useTranslation();

  return (
    <article className={cc([cm.category])}>
      <div
        className={cm.image}
        dangerouslySetInnerHTML={{ __html: category.svg }}
      ></div>
      <RetailTitle className={cm.title}>
        {category.title[i18n.language]}
      </RetailTitle>
      <RetailText size="xxxs" className={cm.text} family="poppins">
        {category.description[i18n.language]}
      </RetailText>
      <RetailAdvertiserViewLink
        to={category.slug ? `/academy/blog/${category.slug.current}` : ""}
        state={{
          breadcrumb: true,
          breadcrumbType: "academy",
          value: category.title[i18n.language],
        }}
      >
        {t("pages.acc.academy.more")}
      </RetailAdvertiserViewLink>
    </article>
  );
};

export default RetailAcademyCategoryCard;
