import Form from "antd/lib/form";
import Input from "antd/lib/input";
import cc from "classcat";
import {
	ChangeEvent,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import useApi from "../../../../api";
import { ReactComponent as WarningFilled } from "../../../../assets/icons/warningBlueFilled.svg";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import RetailSettingsHeader from "../../../../components/Layout/RetailSettingsHeader";
import RetailNotification from "../../../../components/Notification";
import RetailInfoTag from "../../../../components/Tag/RetailInfoTag";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import useSettings from "../../../../hooks/useSettings";
import {
	prefix,
	settingsSuccessNotification,
	validateNumber,
} from "../../../../utils/helpers";
import cm from "../style.module.scss";

const CampaignSettingsBidTab = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { data } = useSettings("MARKETPLACE");

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [fields, setFields] = useState({
    minimum_bid: "",
    minimum_cpm: "",
    target_acos: "",
    general_category_relevance: "",
  });

  useEffect(() => {
    setFields({
      minimum_bid: data?.data?.minimum_bid,
      minimum_cpm: data?.data?.minimum_cpm,
      target_acos: data?.data?.targeting_acos,
      general_category_relevance: data?.data.general_category_relevance,
    });
  }, [data?.data]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const updateValue = async (settings: any) => {
    const config = { ...settings };
    const response = await api.patch(`/settings`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (settings: any) => {
    await mutateAsync({ ...settings });
    queryClient.refetchQueries("settings");
  };

  const onFinish = () => {
    try {
      changeValue({
        minimum_bid: parseFloat(fields.minimum_bid),
        minimum_cpm: parseFloat(fields.minimum_cpm),
        targeting_acos: parseFloat(fields.target_acos),
        general_category_relevance: parseFloat(
          fields.general_category_relevance
        ),
      });
      settingsSuccessNotification(t);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = () => {
    RetailNotification.showNotification(
      "error",
      "",
      t("pages.admin.campaignSettings.inputError")
    );
  };

  useEffect(() => {
    form.setFieldsValue({ ...fields });
  }, [fields, form]);

  const Warning = () => {
    return (
      <RetailInfoTag type="SETTINGS" closable={false} className={cm.warning}>
        <WarningFilled />
        {t("pages.admin.campaignSettings.bidTabWarning")}
      </RetailInfoTag>
    );
  };

  return (
    <>
      <RetailSettingsHeader type="bid" onClick={form.submit} />
      <div>
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
        >
          <div className={cc(["flex", cm.container])}>
            <article>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.campaignSettings.bidLabelTitle")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.campaignSettings.bidLabelText")}
              </RetailText>
              <Warning />
            </article>
            <RetailFormInput
              isFocused={fields.minimum_bid !== ""}
              label={t("pages.admin.campaignSettings.bidLabel")}
              name="minimum_bid"
              rules={[
                {
                  required: true,
                  message: t("pages.admin.campaignSettings.inputErrorZero"),
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                },
              ]}
              className={cc([
                "floating",
                fields.minimum_bid === "" ? cm.hidePrefix : "",
              ])}
            >
              <Input
                className="number-input floating"
                name="minimum_bid"
                prefix={prefix()}
                onChange={handleChange}
                onKeyDownCapture={(e) => validateNumber(e, true)}
              />
            </RetailFormInput>
          </div>
          <div className={cc(["flex", cm.container])}>
            <article>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.campaignSettings.cpmTitle")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.campaignSettings.cpmText")}
              </RetailText>
              <Warning />
            </article>
            <RetailFormInput
              isFocused={fields.minimum_cpm !== ""}
              label={t("pages.admin.campaignSettings.cpmLabel")}
              name="minimum_cpm"
              className={cc([
                "floating inside",
                fields.minimum_cpm === "" ? cm.hidePrefix : "",
              ])}
              rules={[
                {
                  required: true,
                  message: t("pages.admin.campaignSettings.inputErrorZero"),
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                },
              ]}
            >
              <Input
                className="number-input floating"
                name="minimum_cpm"
                prefix={prefix()}
                onChange={handleChange}
                onKeyDownCapture={(e) => validateNumber(e, true)}
              />
            </RetailFormInput>
          </div>

          <div className={cc(["flex", cm.container])}>
            <article>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.campaignSettings.targetAcosTitle")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.campaignSettings.targetAcosText")}
              </RetailText>
              <Warning />
            </article>
            <RetailFormInput
              isFocused={fields.target_acos !== ""}
              label={t("pages.admin.campaignSettings.targetAcosLabel")}
              name="target_acos"
              className={cc([
                "floating inside",
                fields.target_acos === "" ? cm.hidePrefix : "",
              ])}
              rules={[
                {
                  required: true,
                  message: t("pages.admin.campaignSettings.inputErrorAcos"),
                  pattern:
                    /^(?!0(\.0{1,4})?$)(0*\d{0,2}(\.\d{1,4})?|100(\.0{1,4})?)$/,
                },
              ]}
            >
              <Input
                className="number-input floating"
                name="target_acos"
                prefix="%"
                onChange={handleChange}
                onKeyDownCapture={(e) => validateNumber(e)}
              />
            </RetailFormInput>
          </div>

          <div className={cc(["flex", cm.container])}>
            <article>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.campaignSettings.categoryRelevanceTitle")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.campaignSettings.categoryRelevanceText")}
              </RetailText>
              <Warning />
            </article>
            <RetailFormInput
              isFocused={fields.general_category_relevance !== ""}
              label={t("pages.admin.campaignSettings.categoryRelevanceLabel")}
              name="general_category_relevance"
              className={cc(["floating inside", cm.relevance])}
              rules={[
                {
                  required: true,
                  message: t(
                    "pages.admin.campaignSettings.inputErrorRelevance"
                  ),
                  pattern: /^[1-9][0-9]*$/,
                },
              ]}
            >
              <Input
                className="number-input floating"
                name="general_category_relevance"
                onChange={handleChange}
                onKeyDownCapture={(e) => validateNumber(e, true)}
              />
            </RetailFormInput>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CampaignSettingsBidTab;
