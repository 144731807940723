import Tabs from "antd/lib/tabs";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import useApi from "../../../api";
import RetailDateColumn from "../../../components/Column/RetailDateColumn";
import RetailLinkColumn from "../../../components/Column/RetailLinkColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailNotification from "../../../components/Notification";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import { reportFilters } from "../../../utils/filters";
import cm from "./style.module.scss";

const ReportsPage = () => {
  const { t } = useTranslation();

  const location = useLocation();

  let navigate = useNavigate();

  const queryClient = useQueryClient();

  const { pathname } = location;

  const { api } = useApi();

  const [activeKey, setActiveKey] = useState("");

  const updateValue = async (data: any) => {
    const config = {
      status: data.report_status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED",
    };
    const response = await api.patch(`reports/${data.report}`, config);
    return response;
  };
  const { mutateAsync } = useMutation(updateValue);

  const changeArchiveStatus = async (data: any) => {
    try {
      await mutateAsync(data);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t(`components.notification.${data.report_status.toLowerCase()}Report`)
      );
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "report_name":
        return (
          <RetailLinkColumn
            value={value}
            to={
              pathname.includes("admin")
                ? `/admin/report/${records.report}`
                : `/report/${records.report}`
            }
            state={{
              breadcrumb: true,
              breadcrumbType: pathname.includes("admin")
                ? "admin_report"
                : "report",
            }}
          />
        );
      case "report_start_date":
        return records.report_type === "SCHEDULED" || value === "0001-01-01"
          ? "-"
          : moment(value).format("DD/MM/YY");
      case "report_end_date":
        return <RetailDateColumn value={value} />;
      case "report_type":
      case "report_delivery_frequency":
        return value ? t(`components.reportForm.${value?.toLowerCase()}`) : "-";
      case "report_created_at":
        return (
          <RetailText family="poppins" size="xs">
            {moment(value).format("DD/MM/YY")}
          </RetailText>
        );
      default:
        return value ? value : "-";
    }
  };

  const handleClick = () =>
    navigate(
      !pathname.includes("admin")
        ? { pathname: "/create-report", search: location.search }
        : "/admin/create-report"
    );

  const tableConfig = {
    url: "reports",
    isRelation: false,
    filters: reportFilters(t),
    activeKey,
    to: !pathname.includes("admin") ? "edit-report" : "admin/edit-report",
    onArchive: changeArchiveStatus,
    renderColumns,
  };

  const activeTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "report_status", op: "ne", value: "ARCHIVED" }],
  };

  const archivedTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "report_status", op: "eq", value: "ARCHIVED" }],
  };

  return (
    <RetailPageContainer>
      <Tabs
        className={cm.container}
        onChange={(activeKey) => setActiveKey(activeKey)}
      >
        <Tabs.TabPane tab={t("common.listed")} key="ACTIVE">
          <RetailTable
            tableConfig={activeTabConfig}
            placeholder={t("pages.acc.reports.placeholder")}
            button={{
              title: t("pages.acc.reports.button"),
              dataTest: "add-new-report",
              onClick: handleClick,
            }}
            rowKey={(record) => record.report}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("common.archived")} key="ARCHIVED">
          <RetailTable
            tableConfig={archivedTabConfig}
            placeholder={t("pages.acc.reports.placeholder")}
            addBtnVisible={false}
            rowKey={(record) => record.report}
          />
        </Tabs.TabPane>
      </Tabs>
    </RetailPageContainer>
  );
};

export default ReportsPage;
