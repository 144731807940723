import { Col, Form, Radio, Row } from "antd";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import RetailSuggestedTag from "../../../../Tag/RetailSuggestedTag";
import RetailText from "../../../../Typography/RetailText";
import RetailTitle from "../../../../Typography/RetailTitle";
import RetailDatePicker from "../../../RetailDatePicker";
import RetailEndDatePicker from "../../../RetailEndDatePicker";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

export interface DateInputProps {
  start_date: string;
  end_date: string;
  date_type: string;
  startError: boolean;
  handleChange(value: any, name: string): void;
  closeDatePicker(): void;
}
const DateInput = ({
  handleChange,
  closeDatePicker,
  start_date,
  end_date,
  date_type,
  startError,
}: DateInputProps) => {
  const { t } = useTranslation();

  return (
    <RetailFormRow className="form-row">
      <RetailFormInfo
        column={{
          number: "2",
          title: t("components.campaignForm.firstStep.dateInfoTitle"),
          text: t("components.campaignForm.firstStep.dateInfoText"),
        }}
      />
      <Col>
        <Form.Item name="date" className="flex radio">
          <Radio.Group
            onChange={({ target }) => handleChange(target.value, "date_type")}
            data-test="campaign-form-date-type"
          >
            <Radio
              className="form-radio"
              value="STOPPED"
              data-test="campaign-form-date-type-stopped"
            >
              <RetailTitle level={5} className="flex">
                {t("components.campaignForm.firstStep.stopped")}
                <RetailSuggestedTag />
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxs" family="poppins">
                {t("components.campaignForm.firstStep.stoppedText")}
              </RetailText>
            </Radio>
            <Radio
              value="DEFINED"
              data-test="campaign-form-date-type-defined"
              className={cc([
                "form-radio",
                cm.startDate,
                startError && date_type !== "STOPPED" && start_date === ""
                  ? cm.errorBorder
                  : "",
              ])}
            >
              <RetailTitle level={5} className="flex">
                {t("components.campaignForm.firstStep.defined")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxs" family="poppins">
                {t("components.campaignForm.firstStep.definedText")}
              </RetailText>
              {date_type === "DEFINED" && (
                <Row className={cm.dateRow}>
                  <Col>
                    <RetailDatePicker
                      isFocused={start_date !== ""}
                      small
                      rules={[{ required: true }]}
                      type="campaigns"
                      data-test="campaign-form-start-date"
                      onChange={(value) =>
                        handleChange(
                          value ? value?.toString() : "",
                          "start_date"
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <RetailEndDatePicker
                      isFocused={end_date !== ""}
                      onClose={closeDatePicker}
                      handleChange={handleChange}
                      startDate={start_date}
                      type="campaigns"
                      data-test="campaign-form-end-date"
                      small
                    />
                  </Col>
                </Row>
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {/*   */}
      </Col>
    </RetailFormRow>
  );
};

export default DateInput;
