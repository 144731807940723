import {
	Tabs,
	Tree,
} from "antd";
import ConfigProvider from "antd/lib/config-provider";
import Divider from "antd/lib/divider";
import cc from "classcat";
import {
	ChangeEvent,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import useApi from "../../../../api";
import { ReactComponent as CollapseOutlined } from "../../../../assets/icons/collapseOutlined.svg";
import { ReactComponent as DownOutlined } from "../../../../assets/icons/downOutlined.svg";
import { ReactComponent as ExpandOutlined } from "../../../../assets/icons/expandOutlined.svg";
import RetailSearchBar from "../../../../components/Bar/RetailSearchBar";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailEditableColumn from "../../../../components/Column/RetailEditableColumn";
import RetailBidEditContainer, { Selected } from "../../../../components/Container/RetailBidEditContainer";
import Empty from "../../../../components/Empty";
import RetailSettingsHeader from "../../../../components/Layout/RetailSettingsHeader";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import useSettings from "../../../../hooks/useSettings";
import useTableFetch from "../../../../hooks/useTableFetch";
import { localeLanguages } from "../../../../utils/helpers";
import {
	Category,
	TreeNode,
} from "../../../../utils/types";
import cm from "../style.module.scss";

const RestrictionsPageCategoryTab = () => {
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  const { api } = useApi();

  const { data } = useSettings("MARKETPLACE");

  const [activeKey, setActiveKey] = useState("ALL");

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const {
    data: categories,
    setSearch,
    search,
  } = useTableFetch("category-tree", false, null);

  const [selected, setSelected] = useState<Selected>({
    data: {},
    editing: false,
    editing_field: "",
    value: "",
  });

  const [categoryTree, setCategoryTree] = useState<any>(null);

  useEffect(() => {
    if (categories?.data) {
      setCategoryTree(categories.data);
    }
  }, [categories?.data]);

  const [editing, setEditing] = useState<{
    [key: string]: { [field: string]: boolean };
  }>({});

  const updateColumn = async () => {
    const id = selected.data.category_setting?.id;

    const config = {
      [selected.editing_field]: parseFloat(selected.value),
      id,
    };
    const response = await api.patch(`/category-based-setting/${id}`, config);
    return response;
  };

  const postCategory = async (value: any) => {
    try {
      const response = await api.post("category-based-setting", {
        category_id: value.category_id,
        minimum_bid: parseFloat(value.minimum_bid) || data?.data?.minimum_bid,
        minimum_cpm: parseFloat(value.minimum_cpm) || data?.data?.minimum_cpm,
        acos: parseFloat(value.acos) || data?.data?.targeting_acos,
        relevance:
          parseInt(value.relevance) || data?.data?.general_category_relevance,
      });
      queryClient.refetchQueries("table");
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: mutateColumn } = useMutation(updateColumn);

  const handleRequest = async () => {
    if (selected.data?.category_setting?.id) await mutateColumn();
    else await postCategory(selected.data);
  };

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const customizeRenderEmpty = () => <Empty type="categories_table" />;

  const getAllKeys = (node: Category): string[] => {
    const keys = [node.resource_id];
    if (node.sub_categories) {
      node.sub_categories.forEach((subNode: Category) => {
        keys.push(...getAllKeys(subNode));
      });
    }
    return keys;
  };

  const handleExpandAll = () => {
    if (!categoryTree) return;
    const allKeys = getAllKeys(categoryTree);
    setExpandedKeys(allKeys);
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const toggleEditMode = (id: string, field: string) => {
    setEditing((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: !prev[id]?.[field],
      },
    }));
  };

  const updateNodeData = (id: string, field: string, value: any) => {
    if (!categoryTree) return;
    const updateNode = (node: Category): any => {
      if (node.resource_id === id) {
        return {
          ...node,
          category_setting: {
            ...node.category_setting,
            [field]: value,
          },
        };
      }
      if (node.sub_categories) {
        return { ...node, sub_categories: node.sub_categories.map(updateNode) };
      }
      return node;
    };
    setCategoryTree(updateNode(categoryTree));
  };

  const filterCategories = (category: Category): any => {
    if (!categoryTree) return;
    const isFilled =
      category?.category_setting?.acos !== undefined ||
      category?.category_setting?.relevance !== undefined ||
      category?.category_setting?.minimum_bid !== undefined ||
      category?.category_setting?.minimum_cpm !== undefined;

    const filteredSubCategories = category.sub_categories
      .map(filterCategories)
      .filter(Boolean);

    if (isFilled || filteredSubCategories.length > 0) {
      return {
        ...category,
        sub_categories: filteredSubCategories,
      };
    }
    return null;
  };

  const filteredData = filterCategories(categoryTree ? categoryTree : []);

  const fieldError = (field: string, value: any) => {
    switch (field) {
      case "acos":
        return !(+value <= 0.0001 && +value <= 100);
      case "relevance":
        return +value >= 1 && +value <= 100;
      default:
        return value !== "";
    }
  };

  const renderField = (node: any, field: string) => {
    const isEditing = editing[node.resource_id]?.[field];

    const value = node?.category_setting?.[field] ?? "-";

    return (
      <div className={cm.editCol}>
        <RetailText size="xxxs" weight="medium" className={cm.editLabel}>
          {t(`common.table.${field}`)}:
        </RetailText>
        {isEditing ? (
          <RetailBidEditContainer
            selected={selected}
            setSelected={setSelected}
            onClick={() => {
              toggleEditMode(node.resource_id, field);
              if (fieldError(field, selected.value)) {
                updateNodeData(node.resource_id, field, selected.value);
                handleRequest();
              }
            }}
            justCloseOnBlur={() => toggleEditMode(node.resource_id, field)}
          />
        ) : (
          <RetailEditableColumn
            value={value}
            type={field === "acos" ? "percent" : "number"}
            reverse={true}
            onClick={() => {
              toggleEditMode(node.resource_id, field);
              setSelected({
                data: node,
                editing: true,
                value: value,
                editing_field: field,
              });
            }}
          />
        )}
      </div>
    );
  };

  const convertToTreeRows = (node: Category): TreeNode => ({
    title: (
      <div className={cm.row}>
        <RetailText size="xs" weight="medium" className={cm.nodeName}>
          {node.name}
        </RetailText>
        {renderField(node, "minimum_bid")}
        {renderField(node, "minimum_cpm")}
        {renderField(node, "acos")}
        {renderField(node, "relevance")}
      </div>
    ),
    key: node.resource_id,
    switcherIcon: (props: { expanded: boolean }) => (
      <div className={cc([cm.icon, props.expanded ? cm.up : cm.down])}>
        <DownOutlined />
      </div>
    ),

    children: node.sub_categories
      ? node.sub_categories.map(convertToTreeRows)
      : [],
  });

  const treeData = [convertToTreeRows(categoryTree ?? [])];

  const filteredTreeData = [convertToTreeRows(filteredData ?? [])];

  return (
    <>
      <RetailSettingsHeader type="category" />
      <div className={cm.container}>
        <div className={cm.flex}>
          <div>
            <RetailTitle level={4} className={cm.secondaryTitle}>
              {t("pages.admin.restrictions.categoryTitle")}
            </RetailTitle>
            <RetailText
              size="xxxs"
              family="poppins"
              className={cm.categoryText}
            >
              {t("pages.admin.restrictions.categoryText")}
            </RetailText>
          </div>
        </div>

        <Divider className={cm.divider} />
        <RetailSearchBar
          placeholder={t("common.search")}
          shadow
          value={search}
          onChange={handleSearch}
          className={cm.search}
        />
        {categoryTree && (
          <ConfigProvider
            renderEmpty={customizeRenderEmpty}
            locale={localeLanguages(i18n.language)}
          >
            <Tabs
              activeKey={activeKey}
              onChange={(activeKey) => setActiveKey(activeKey)}
              className={cm.categoryTabs}
              tabBarExtraContent={
                <div className={cm.btnContainer}>
                  <RetailMainButton
                    className={cm.btn}
                    onClick={handleExpandAll}
                  >
                    <ExpandOutlined />
                    {t("pages.admin.restrictions.expand")}
                  </RetailMainButton>
                  <RetailMainButton
                    className={cm.btn}
                    onClick={handleCollapseAll}
                  >
                    <CollapseOutlined />
                    {t("pages.admin.restrictions.collapse")}
                  </RetailMainButton>
                </div>
              }
            >
              <Tabs.TabPane tab={t("pages.admin.restrictions.all")} key="ALL">
                <Tree
                  treeData={treeData}
                  showLine={{
                    showLeafIcon: false,
                  }}
                  expandedKeys={expandedKeys}
                  onExpand={(keys) => setExpandedKeys(keys as string[])}
                  blockNode
                  selectable={false}
                  virtual={false}
                  className={cm.tree}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t("pages.admin.restrictions.edited")}
                key="EDITED"
              >
                {filteredData && (
                  <Tree
                    treeData={filteredTreeData}
                    showLine={{
                      showLeafIcon: false,
                    }}
                    expandedKeys={expandedKeys}
                    onExpand={(keys) => setExpandedKeys(keys as string[])}
                    blockNode
                    selectable={false}
                    virtual={false}
                    className={cm.tree}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          </ConfigProvider>
        )}
      </div>
    </>
  );
};

export default RestrictionsPageCategoryTab;
