import { Spin } from "antd";
import axios from "axios";
import jwt from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import { ReactComponent as KeyOutlined } from "../../../assets/icons/keyOutlined.svg";
import { ReactComponent as UserOutlined } from "../../../assets/icons/userOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import { Auth, AuthContext } from "../../../context/AuthProvider";
import { Account } from "../../../utils/types";
import { addAccount } from "../LoginPage";
import { Link, useLocation } from "react-router-dom";
import RetailTitle from "../../../components/Typography/RetailTitle";
import RetailText from "../../../components/Typography/RetailText";

import cc from "classcat";
import cm from "./style.module.scss";

const AccountsPage = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const empty = location.state as { empty: boolean };

  const supportEmail = (window as any).marketplaceConfig.support_email || null;

  const {
    authInfo,
    authToken,
    logout,
    navigateDependingOnAccount,
    setAuthInfo,
  } = useContext(AuthContext) as Auth;

  const selectAccount = (index: number) => {
    localStorage.setItem("account_index", JSON.stringify(index));
    navigateDependingOnAccount(authInfo[index]);
  };

  const access_token = localStorage.getItem("access_token") || "";

  const getUser = async (token: string) => {
    const data = jwt(token) as any;
    try {
      const response = await axios.get(`/api/users/${data.user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAuthInfo(response?.data?.accounts);
      localStorage.setItem(
        "auth_info",
        JSON.stringify(response?.data?.accounts)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const createAccount = async () => {
    try {
      setLoading(true);
      await addAccount(access_token, authToken, t);
      await getUser(authToken);
      localStorage.removeItem("access_token");
      localStorage.removeItem("email_from_url");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (access_token !== "") createAccount();
    else if (authToken) getUser(authToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token, authToken]);

  return (
    <Spin spinning={loading}>
      <RetailAuthLayout>
        {empty ? (
          <div className={cm.wrapper}>
            <section className={cc(["flex", cm.noAccountContainer])}>
              <article className="flex">
                <span className={cc(["flex", cm.icon])}>
                  <CloseOutlined />
                </span>
                <RetailTitle
                  level={4}
                  className={cc(["flex", cm.noAccountTitle])}
                >
                  {t("pages.auth.accounts.noAccount")}
                </RetailTitle>
                <RetailText
                  weight="medium"
                  size="xs"
                  className={cm.noAccountText}
                >
                  {supportEmail ? (
                    <Trans
                      i18nKey={t("pages.auth.accounts.noAccountText", {
                        value: supportEmail,
                      })}
                    />
                  ) : (
                    t("pages.auth.accounts.noAccountTextNoEmail")
                  )}
                </RetailText>
                <RetailMainButton
                  hasBackground={false}
                  className={cm.noAccountButton}
                >
                  {supportEmail ? (
                    <a href={`mailto:${supportEmail}`}>
                      {t("pages.auth.accounts.contact")}
                    </a>
                  ) : (
                    <Link to="/login" onClick={logout}>
                      {t("pages.auth.accounts.login")}
                    </Link>
                  )}
                </RetailMainButton>
              </article>
            </section>
          </div>
        ) : (
          <div className={cm.wrapper}>
            <section className={cc(["flex", cm.textContainer])}>
              <div className={cc(["flex", cm.iconContainer])}>
                <KeyOutlined />
              </div>
              <article>
                <h1>{t("pages.auth.accounts.title")}</h1>
                <p>{t("pages.auth.accounts.text")}</p>
              </article>
            </section>

            <section className={cm.accounts}>
              {authInfo?.map((account: Account, index: number) => (
                <div
                  key={account.id}
                  className={cc(["flex", cm.account])}
                  onClick={() => {
                    selectAccount(index);
                  }}
                >
                  <p className={cc(["flex", cm.userIcon])}>
                    <UserOutlined />
                  </p>
                  <p>{account?.account_name}</p>
                </div>
              ))}
            </section>
            <RetailMainButton
              hasBackground={false}
              className={cm.button}
              onClick={logout}
            >
              {t("pages.auth.accounts.logout")}
            </RetailMainButton>
          </div>
        )}
      </RetailAuthLayout>
    </Spin>
  );
};

export default AccountsPage;
