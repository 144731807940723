import {
	useEffect,
	useState,
} from "react";

import sanityClient from "../../../client";
import RetailAcademyCategoryCard from "../../../components/Card/RetailAcademyCategoryCard";
import RetailAcademyHeader from "../../../components/Layout/RetailAcademyHeader";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import cm from "./style.module.scss";

const RetailAcademyPage = () => {
  const [categories, setCategories] = useState<any[] | null>(null);

  const fetchCategories = async () => {
    sanityClient
      .fetch(
        `*[_type == "category" ]{
          title,
          description,
          sorting,
          svg,
          slug
      }`
      )
      .then((data) =>
        setCategories(data.sort((a: any, b: any) => a.sorting - b.sorting))
      )
      .catch(console.error);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <RetailPageContainer>
      <RetailAcademyHeader />
      <section className={cm.container}>
        {categories?.map((category, index) => (
          <RetailAcademyCategoryCard category={category} key={index} />
        ))}
      </section>
    </RetailPageContainer>
  );
};

export default RetailAcademyPage;
