const beymen_en = {
  marketplaceDependent: {
    form: {
      budget_link:
        "/academy/blog/management/kategori-bazli-tiklama-maliyetleri",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_budget_text:
      "Determine your campaign's budget type and amount. The campaign stops showing when your spend reaches your budget. Unit costs to be spent vary by category. The cost per click is charged as 5₺.",
    campaign_form_targeting_text:
      "Campaign targeting type will be determined automatically by Beymen.",
    campaign_form_creative_text:
      "You can upload the creatives you want to use in your campaign.",
    invoice_modal_help_text:
      "If you continue by clicking on the choose payment method button, the amount to be collected will not be refunded.",
    invoice_general_error_modal_title: "Failed to Load Balance!",
    invoice_general_error_modal_text:
      "For the error you received in loading the balance, you can open a request to the 'Campaign and Advertising Management' via the Seller Support.",
  },
};

export default beymen_en;
