import Col from "antd/lib/col";
import { Header } from "antd/lib/layout/layout";
import cc from "classcat";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import useApi from "../../../../api";
import { ReactComponent as LeftOutlined } from "../../../../assets/icons/leftOutlined.svg";
import { ReactComponent as SyncOutlined } from "../../../../assets/icons/menu/syncOutlined.svg";
import logo from "../../../../assets/images/logo.svg";
import { Auth, AuthContext } from "../../../../context/AuthProvider";
import useSettings from "../../../../hooks/useSettings";
import { bg_mode, marketplaceLogo } from "../../../../utils/defaults";
import RetailAdminBar from "../../../Bar/RetailAdminBar";
import RetailDocsBar from "../../../Bar/RetailDocsBar";
import RetailBalanceContainer from "../../../Container/RetailBalanceContainer";
import RetailAdvertiserViewLink from "../../../Link/RetailAdvertiserViewLink";
import RetailNavbarPopover from "../../../Popover/RetailNavbarPopover";
import RetailTitle from "../../../Typography/RetailTitle";
import RetailBreadcrumb from "./RetailBreadcrumb";
import RetailBrowserBreadcrumb from "./RetailBrowserBreadcrumb";
import cm from "./style.module.scss";
import { SMOContext, SMOContextType } from "../../../../context/SMOProvider";

const RetailPageNavbar = () => {
  const { t, i18n } = useTranslation();

  const { showAdminBar } = useContext(AuthContext) as Auth;

  const { showDocs } = useContext(SMOContext) as SMOContextType;

  const { roleForDashboard } = useApi();

  const { data } = useSettings(
    roleForDashboard,
    roleForDashboard === "ADVERTISER"
  );

  const navigate = useNavigate();

  const location = useLocation();

  const adminNavbar = location.pathname.includes("admin");

  const userType = adminNavbar ? "admin" : "user";

  const breadcrumb = location.state as {
    breadcrumb: boolean;
    breadcrumbType: string;
  };

  const isBreadcrumbFromBrowser =
    /\d/.test(decodeURI(location.pathname)) ||
    location.pathname.includes("academy/");

  const languageSelect = adminNavbar
    ? location.pathname.substring(7)
    : location.pathname.substring(1);

  const isCampaign =
    location.pathname === "/" ||
    location.pathname === "/admin" ||
    location.pathname === "/admin/";

  const adminBarTopValue = showDocs ? 38 : 0;

  const navbarTopValue = () => {
    if (showAdminBar && showDocs) return 104;

    if (showAdminBar) return 66;

    if (showDocs) return 38;

    return 0;
  };

  const title = () => {
    if (isCampaign) return t("sider.user.campaign");
    if (i18n.exists(`sider.${userType}.${languageSelect}`))
      return t(`sider.${userType}.${languageSelect}`);
    return "";
  };

  const handleNavigate = () => navigate("/admin/synchronization");

  return (
    <>
      {showDocs && <RetailDocsBar />}
      {showAdminBar && <RetailAdminBar topValue={adminBarTopValue} />}
      <Header
        className={cc([cm.navbar, cm[bg_mode]])}
        style={{ top: navbarTopValue() }}
      >
        {/** Logo */}
        <div className={cm.logo}>
          <RetailAdvertiserViewLink to={adminNavbar ? "/admin/campaigns" : "/"}>
            <img alt="" className={cm.logoImg} src={marketplaceLogo || logo} />
          </RetailAdvertiserViewLink>
        </div>

        {breadcrumb !== null && breadcrumb.breadcrumb ? (
          <RetailBreadcrumb />
        ) : isBreadcrumbFromBrowser ? (
          <RetailBrowserBreadcrumb />
        ) : (
          <RetailTitle className={cm.title} level={5}>
            {title()}
          </RetailTitle>
        )}

        {userType === "user" ? (
          <>
            {data?.data?.seller_site && (
              <Col className={cc(["flex", cm.link, cm[bg_mode]])}>
                <a
                  href={`https://${data?.data?.seller_site}`}
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LeftOutlined />
                  {t("components.navbar.site")}
                </a>
              </Col>
            )}
            {/*
             * Show balance on navbar
             */}
            <RetailBalanceContainer
              className={data?.data?.seller_site ? "" : cm.noSellerSite}
            />
          </>
        ) : (
          <Col
            className={cc([cm.logout, cm.sync, "flex"])}
            onClick={handleNavigate}
          >
            <SyncOutlined />
          </Col>
        )}

        {/*
         * Account Info, change account and language
         */}
        <RetailNavbarPopover />
      </Header>
    </>
  );
};

export default RetailPageNavbar;
