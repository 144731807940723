import { useTranslation } from "react-i18next";
import { ButtonProps } from "antd";
import { ReactComponent as ArrowRightOutlined } from "../../../assets/icons/arrowRightOutlined.svg";
import RetailMainButton from "../RetailMainButton";

import cm from "./style.module.scss";

interface RetailOnboardingButtonGroupProps extends ButtonProps {
  handleBack: () => void;
  nextText: string;
  handleNext: () => void;
}

const RetailOnboardingButtonGroup = ({
  handleBack,
  nextText,
  handleNext,
  ...props
}: RetailOnboardingButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <div className={cm.buttonGroup}>
      <RetailMainButton
        hasBackground={false}
        className={cm.backBtn}
        onClick={handleBack}
      >
        <ArrowRightOutlined className={cm.backIcon} />
        {t("components.tour.prev")}
      </RetailMainButton>

      <RetailMainButton
        hasBackground={true}
        className={cm.nextBtn}
        onClick={handleNext}
        {...props}
      >
        {nextText}
        <ArrowRightOutlined />
      </RetailMainButton>
    </div>
  );
};

export default RetailOnboardingButtonGroup;
