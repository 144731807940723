import cc from "classcat";
import {
	useRef,
	useState,
} from "react";

import { ReactComponent as DotFilled } from "../../../../../assets/icons/dotFilled.svg";
import { ReactComponent as Brand } from "../../../../../assets/images/landing_page/brand.svg";
import display from "../../../../../assets/images/landing_page/display.png";
import { ReactComponent as Loyalty } from "../../../../../assets/images/landing_page/loyalty.svg";
import { ReactComponent as Opinion } from "../../../../../assets/images/landing_page/opinion.svg";
import product from "../../../../../assets/images/landing_page/product.png";
import { ReactComponent as Sales } from "../../../../../assets/images/landing_page/sales.svg";
import cm from "./style.module.scss";

const TeknosaLandingInfo = () => {
  const [activeIcon, setActiveIcon] = useState<number>(0);
  const [activeCard, setActiveCard] = useState<number>(0);

  const ref = useRef<HTMLElement>(null);

  const iconData = [
    {
      icon: <Brand />,
      text: "Marka Farkındalığını Oluşturun",
    },
    {
      icon: <Opinion />,
      text: "Daha Fazla Müşteriden Değerlendirme Alın",
    },
    {
      icon: <Sales />,
      text: "Satışları Artırın",
    },
    {
      icon: <Loyalty />,
      text: "Müşteri Sadakatini Kazanın",
    },
  ];
  const cardData = [
    {
      img: product,
      title: "Sponsorlu Ürün",
      text: "Ürünlerinizi öne çıkararak yüksek dönüşümler sağlayın.",
    },
    {
      img: display,
      title: "Display",
      text: "Banner reklamlarınızı alakalı kategori sayfalarının en üst bölümlerinde sergileyin.",
    },
  ];

  const handleBulletClick = (index: number) => {
    const container = ref.current;
    setActiveIcon(index);
    if (container) {
      const scrollX = index * 220;
      container.scrollTo({
        left: scrollX,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section className={cm.wrapper}>
        <div>
          <article>
            <h3 className={cm.title}>
              Müşterilerin Alışveriş Noktalarında <span>Kontrol</span> Sağlayın!
            </h3>
            <p>
              Hedef müşterinizin ürün arayışına başladığı ilk andan satın alma
              işlemine kadar tüm alışveriş yolculuğunda kontrolü sağlayarak,
              etkili bir reklam stratejisi ile her bir adımı iyi
              değerlendirebilirsiniz.
            </p>
          </article>

          <section ref={ref} className={cm.iconCardWrapper}>
            {iconData.map((data, index) => (
              <article key={index} className={cm.iconCard}>
                <div className={cm.icon}>{data.icon}</div>
                <p>{data.text}</p>
              </article>
            ))}
          </section>
          <div className={cm.iconContainer}>
            {iconData.map((data, index) => (
              <DotFilled
                key={index}
                className={cc([cm.dot, index === activeIcon && cm.active])}
                onClick={() => handleBulletClick(index)}
              />
            ))}
          </div>
        </div>
      </section>
      <section className={cm.container}>
        <h3>FORMATLAR</h3>
        <h4>En iyi reklam yerleşimlerini bulun.</h4>
        <section>
          {cardData.map((card, index) => (
            <article
              key={index}
              className={cc([
                cm.card,
                cm[`card-${index}`],
                activeCard === index ? cm.active : cm.inactive,
              ])}
            >
              <img src={card.img} alt="" className={cm.mainImg} />
              <h6>{card.title}</h6>
              <p>{card.text}</p>
            </article>
          ))}
        </section>
        <div className={cm.iconContainer}>
          {cardData.map((card, index) => (
            <DotFilled
              key={index}
              className={cc([cm.dot, index === activeCard && cm.active])}
              onClick={() => setActiveCard(index)}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default TeknosaLandingInfo;
