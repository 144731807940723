import footerLogo from "../../../../../assets/images/landing_page/footer-logo.png";
import logo from "../../../../../assets/images/landing_page/logo.png";
import cm from "./style.module.scss";

export interface TeknosaLandingFooterProps {
  sectionVisible?: boolean;
}

const TeknosaLandingFooter = ({
  sectionVisible = true,
}: TeknosaLandingFooterProps) => {
  const year = new Date().getFullYear();

  return (
    <footer className={cm.footer}>
      {sectionVisible && (
        <>
          <p>Reklam vermek için hemen başvurun!</p>
          <a href="/login">Reklam Ver</a>
        </>
      )}
      <div className={cm.divider}></div>
      <ul>
        <li>
          <img src={logo} alt="logo" className={cm.logo} />
        </li>
        <li className={cm.links}>
          <a
            href="https://www.teknosa.com/gizlilik-sozlesmesi"
            target="_blank"
            rel="noreferrer"
          >
            Gizlilik Sözleşmesi
          </a>
          <div className={cm.circle}></div>
          <a
            href="https://www.teknosa.com/kisisel-verilerin-korunmasi"
            target="_blank"
            rel="noreferrer"
          >
            Kişisel Verilerin Korunması
          </a>
          <div className={cm.circle}></div>
          <a
            href="https://www.teknosa.com/kisisel-verilerin-korunmasi"
            target="_blank"
            rel="noreferrer"
          >
            Çerez Ayarları
          </a>
        </li>

        <li className={cm.year}>© {year} Teknosa.com, Her hakkı saklıdır.</li>
        <li className={cm.footerLogo}>
          <img src={footerLogo} alt="logo" className={cm.logo} />
        </li>
      </ul>
    </footer>
  );
};

export default TeknosaLandingFooter;
