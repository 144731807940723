import cc from "classcat";
import React from "react";

import RetailText from "../RetailText";
import cm from "./style.module.scss";

export interface RetailNumericProps
  extends React.HTMLAttributes<HTMLDivElement> {
  colored?: boolean;
}

const RetailNumeric: React.FC<RetailNumericProps> = ({
  children,
  colored = false,
  className,
  ...htmlDivElement
}) => {
  return (
    <div
      {...htmlDivElement}
      className={cc([
        colored ? cm.colored : cm.transparent,
        cm.container,
        className || "",
      ])}
    >
      <RetailText weight="bold" size="xs">
        {children}
      </RetailText>
    </div>
  );
};

export default RetailNumeric;
