import cc from "classcat";
import getVideoId from "get-video-id";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PortableText from "react-portable-text";

import { CopyOutlined, InfoCircleFilled } from "@ant-design/icons";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient, { id } from "../../../client";
import RetailInfoTag from "../../../components/Tag/RetailInfoTag";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import { Auth, AuthContext } from "../../../context/AuthProvider";
import usePostFetch from "../../../hooks/usePostFetch";
import useSettings from "../../../hooks/useSettings";
import cm from "./style.module.scss";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source: any) {
  return builder.image(source);
}

const RetailAcademyPost = () => {
  const { t } = useTranslation();

  const { data } = useSettings("ADVERTISER");

  const { showAdminBar } = useContext(AuthContext) as Auth;

  const { post } = usePostFetch("academy");

  if (!post) return <RetailTitle level={4}>Loading...</RetailTitle>;

  //Copy URL
  const copyTextToClipboard = async () =>
    await navigator.clipboard.writeText(window.location.href);

  return (
    <section className={cc([cm.page, showAdminBar ? cm.advertiserView : ""])}>
      <section className={cm.titleContainer}>
        <h1 className={cm.title}>{post.title}</h1>
        <button className={cc(["flex", cm.btn])} onClick={copyTextToClipboard}>
          <CopyOutlined />
          {t("pages.acc.academy.copy")}
        </button>
      </section>
      <article>
        <section>
          {/* {post.description && (
            <RetailText className={cm.description}>
              {post.description}
            </RetailText>
          )} */}
          <PortableText
            content={post.body}
            dataset="production"
            projectId={id}
            serializers={{
              image: (props: any) => (
                <div className={cm.imageContainer}>
                  <img
                    src={urlFor(props).url()}
                    alt={post.title}
                    className={cm.image}
                  />
                </div>
              ),
              div: ({ children }: any) => (
                <RetailInfoTag
                  type="SETTINGS"
                  closable={false}
                  className={cm.info}
                >
                  <InfoCircleFilled />
                  {children}
                </RetailInfoTag>
              ),
              youtube: (props: any) => (
                <iframe
                  src={`https://www.youtube.com/embed/${
                    getVideoId(props.url).id
                  }`}
                  width="100%"
                  className={cm.youtube}
                  height={400}
                  allowFullScreen
                  allowTransparency
                  title={props.title}
                />
              ),
            }}
          />
        </section>
      </article>
      <footer className={cc(["flex", cm.footer])}>
        <section>
          <RetailTitle>{t("pages.acc.academy.helpTitle")}</RetailTitle>
          <RetailText>{t("pages.acc.academy.helpText")}</RetailText>
        </section>
        <a href={`mailto:${data?.data?.email}`} className="flex">
          {t("pages.acc.academy.contact")}
        </a>
      </footer>
    </section>
  );
};

export default RetailAcademyPost;
