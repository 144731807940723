import cc from "classcat";
import { useTranslation } from "react-i18next";

import { SettingsHeader } from "../../../utils/types";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailSettingsHeaderProps {
  type: SettingsHeader;
  onClick?(): void;
}

const RetailSettingsHeader = ({ type, onClick }: RetailSettingsHeaderProps) => {
  const { t } = useTranslation();

  const isTab =
    type === "campaign" ||
    type === "bid" ||
    type === "conversion" ||
    type === "category" ||
    type === "keyword";

  const isBid = type === "bid" || type === "category" || type === "keyword";

  return (
    <article
      className={cc([
        "flex",
        cm.header,
        isTab ? cm.settingsTab : "",
        isBid ? cm.bidTab : "",
      ])}
    >
      <section className={cc(["flex", cm.textContainer])}>
        <div>
          <RetailTitle level={3} className={cm.mainTitle}>
            {t(`components.settingsHeader.${type}`)}
          </RetailTitle>
          <RetailText size="xs" className={cm.mainText}>
            {t(`components.settingsHeader.${type}Text`)}
          </RetailText>
        </div>
        {onClick && (
          <RetailMainButton
            hasBackground
            className={cc(["flex", cm.btn])}
            onClick={onClick}
          >
            {t("common.save")}
          </RetailMainButton>
        )}
      </section>
    </article>
  );
};

export default RetailSettingsHeader;
