import Col from "antd/lib/col";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import SelectCategoriesTable from "../../../Table/SelectCategoriesTable";
import SelectKeywordsTable from "../../../Table/SelectKeywordsTable";
import SelectNegativeTable from "../../../Table/SelectNegativeTable";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";
import RetailFormInfo from "../../RetailFormInfo";
import RetailFormRow from "../../RetailFormRow";
import cm from "../style.module.scss";

export interface RetailCampaignFormSecondStepProps {
  requiredFields: any;
  errorStates: any;
}

const RetailCampaignFormSecondStep = ({
  requiredFields,
  errorStates,
}: RetailCampaignFormSecondStepProps) => {
  const { t } = useTranslation();

  const title =
    requiredFields.placement === "IN_SEARCH"
      ? t("components.campaignForm.secondStep.wordTargeting")
      : t("components.campaignForm.secondStep.categoryTargeting");

  const text =
    requiredFields.placement === "IN_SEARCH"
      ? t("components.campaignForm.secondStep.wordTargetingText")
      : t("components.campaignForm.secondStep.categoryTargetingTitle");

  return (
    <div className={cm.wrapper}>
      <RetailFormRow
        className={cc([cm.keywords, "form-row", cm.unroundedKeywords])}
      >
        <RetailFormInfo
          column={{
            number: "1",
            title: title,
            text: text,
          }}
        />
      </RetailFormRow>

      {requiredFields.placement === "IN_SEARCH" ? (
        <RetailFormRow className={cm.notAutoKeyword}>
          <Col span={24}>
            <RetailTitle level={5} className={cm.selectedTitle}>
              {t("components.campaignForm.secondStep.keywordTitle")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.keywordText")}
            </RetailText>
          </Col>
          <SelectKeywordsTable
            suggestKeywords
            isDynamic={requiredFields.dataBidType === "FIXED"}
            error={errorStates.keywordError}
          />
          <Col>
            <RetailTitle
              level={5}
              className={cc([cm.negativeTitle, cm.selectedTitle])}
            >
              {t("components.campaignForm.secondStep.negativeTitle")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.negativeText")}
            </RetailText>
            <SelectNegativeTable type="NEGATIVE_KEYWORDS" />
          </Col>
        </RetailFormRow>
      ) : (
        <RetailFormRow className={cm.notAutoKeyword}>
          <Col span={24}>
            <RetailTitle level={5} className={cm.selectedTitle}>
              {t("components.campaignForm.secondStep.category")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.categoryTargetingText")}
            </RetailText>
          </Col>
          <SelectCategoriesTable
            suggestCategories
            isDynamic={requiredFields.dataBidType === "FIXED"}
            error={errorStates.categoryError}
          />
          <Col>
            <RetailTitle
              level={5}
              className={cc([cm.negativeTitle, cm.selectedTitle])}
            >
              {t("components.campaignForm.secondStep.negativeCategoryTitle")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.negativeCategoryText")}
            </RetailText>
            <SelectNegativeTable type="NEGATIVE_CATEGORIES" />
          </Col>
        </RetailFormRow>
      )}
    </div>
  );
};

export default RetailCampaignFormSecondStep;
