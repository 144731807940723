import Table from "antd/lib/table";
import cc from "classcat";
import {
	useContext,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
	Keyword,
	KeywordContext,
} from "../../../../context/KeywordProvider";
import {
	Product,
	ProductContext,
} from "../../../../context/ProductProvider";
import useTableFetch from "../../../../hooks/useTableFetch";
import {
	controlSelected,
	convertKeywordVolume,
} from "../../../../utils/helpers";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailSuggestionCard from "../../../Card/RetailSuggestionCard";
import RetailMoneyColumn from "../../../Column/RetailMoneyColumn";
import cm from "../style.module.scss";

const RetailKeywordSuggestionTable = () => {
  const { t } = useTranslation();

  const { setKeywords, keywords } = useContext(KeywordContext) as Keyword;

  const { selectedProducts } = useContext(ProductContext) as Product;

  const location = useLocation();

  const ad_type = location.state as { ad_type: string };

  const filter =
    ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
      ? [
          {
            key: "productResourceID",
            op: "in",
            value: selectedProducts.map((product: any) => product.sku),
          },
        ]
      : [];

  const { data } = useTableFetch("keywords", false, {}, undefined, filter);

  const [dataForTable, setDataForTable] = useState<any>([]);

  useEffect(() => {
    setDataForTable(
      data?.data.records !== null &&
        data?.data.records.map((keyword: any, index: number) => ({
          ...keyword,
          id: index,
        }))
    );
  }, [data?.data.records]);

  const suggestedKeywordColumns = [
    {
      key: 1,
      title: t("components.table.keywords.keyword"),
      dataIndex: "text",
    },
    {
      key: 2,
      title: t("components.table.keywords.matchType"),
      dataIndex: "match_type",
      width: 200,
      render: (value: "EXACT" | "PHRASE" | "BROAD") => (
        <span>{t(`common.${value.toLowerCase()}`)}</span>
      ),
    },
    {
      key: 3,
      title: t("components.table.keywords.suggested"),
      dataIndex: "suggested_bid",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.table.keywords.volume"),
      dataIndex: "volume",
      render: (value: string) => convertKeywordVolume(value, t),
    },
    {
      key: 7,
      title: "",
      dataIndex: "isAdded",
      render: (value: any, records: any) => (
        <RetailMainButton
          hasBackground={false}
          onClick={() => {
            setKeywords((curr: any) => [...curr, records]);
          }}
          className={cm.btn}
          disabled={controlSelected(records.id, keywords!, dataForTable)}
          data-test="campaign-form-add-keyword"
        >
          {t("common.add")}
        </RetailMainButton>
      ),
    },
  ];

  const handleAddAll = () => {
    setKeywords((curr: any[]) => {
      const data = [...curr, ...dataForTable];
      return data.filter((v, i, a) => a.indexOf(v) === i);
    });
  };

  return (
    <div className={cm.borderContainer}>
      <div className={cm.firstBorder}></div>
      <div className={cm.secondBorder}></div>
      <div className={cm.suggestedContainer} data-cy="suggestion-table">
        <RetailSuggestionCard
          title={t(`components.table.keywords.suggestTitle`)}
          text={t(`components.table.keywords.suggestText`)}
          mode="KEYWORDS"
          onClick={handleAddAll}
          disabled={!dataForTable || keywords.length >= dataForTable?.length}
        />
        <Table
          dataSource={dataForTable}
          columns={suggestedKeywordColumns}
          className={cc([cm.keyTable, cm.more])}
          pagination={{
            total: data?.data.total_records,
            pageSize: 3,
            showSizeChanger: false,
          }}
          rowKey={(records) => records.id}
        />
      </div>
    </div>
  );
};

export default RetailKeywordSuggestionTable;
