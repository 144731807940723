import Row from "antd/lib/row";
import { useTranslation } from "react-i18next";

import { InfoCircleFilled } from "@ant-design/icons";

import RetailText from "../../../../../components/Typography/RetailText";
import cm from "../style.module.scss";

export interface CreativeInfoProps {
  url: string;
}

const CreativeInfo = ({ url }: CreativeInfoProps) => {
  const { t } = useTranslation();

  return (
    <Row className={cm.info}>
      <RetailText
        size="xxs"
        weight="medium"
        family="poppins"
        className={cm.infoTitle}
      >
        {t("pages.acc.campaignDetails.creativesLinkTitle")}
        <InfoCircleFilled className={cm.infoIcon} />
      </RetailText>
      <RetailText family="poppins" size="xxxs" className={cm.infoText}>
        {t("pages.acc.campaignDetails.creativesLinkText", {
          value: url,
        })}
      </RetailText>
    </Row>
  );
};

export default CreativeInfo;
