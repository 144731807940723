

import { Divider } from "antd";

import { ArrowRightOutlined } from "@ant-design/icons";

import cm from "./style.module.scss";

const TeknosaLandingHeader = () => {
  return (
    <>
      <header className={cm.header}>
        <div className={cm.headerBg}></div>
        <article>
          <h1>
            <span>Teknosa Ads</span> ile Satışlarınızı Artırın
          </h1>
          <p>
            Yeni müşterilere ulaşmak, mevcut trafiğinizi artırmak ve
            ürünlerinizi daha güçlü bir strateji ile pazarlamak için Teknosa Ads
            ile tanışın! Artık tüm reklam metriklerini ve satışları tek bir
            platform üzerinden kontrol edebilirsiniz.
          </p>
          <a href="/login" className={cm.cta}>
            Reklam Ver <ArrowRightOutlined />
          </a>
        </article>
      </header>
      <Divider className={cm.divider} />
    </>
  );
};

export default TeknosaLandingHeader;
