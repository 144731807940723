import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useLocation } from "react-router-dom";

import useApi from "../../../../api";
import { ReactComponent as BidOutlined } from "../../../../assets/icons/bidOutlined.svg";
import { ReactComponent as DateOutlined } from "../../../../assets/icons/dateOutlined.svg";
import { ReactComponent as DistributionOutlined } from "../../../../assets/icons/distributionOutlined.svg";
import { ReactComponent as EarthOutlined } from "../../../../assets/icons/earthOutlined.svg";
import { ReactComponent as EditOutlined } from "../../../../assets/icons/editWhiteOutlined.svg";
import { ReactComponent as LinesOutlined } from "../../../../assets/icons/linesOutlined.svg";
import { ReactComponent as WalletOutlined } from "../../../../assets/icons/walletOutlined.svg";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNotification from "../../../../components/Notification";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import {
	days,
	daysMap,
	generateDayPartingObject,
	numFormatter,
	prefix,
} from "../../../../utils/helpers";
import { Schedule } from "../../../../utils/types";
import CampaignDetailsDayParting from "./CampaignDetailsDayParting";
import CampaignDetailsHeaderSwitch from "./CampaignDetailsHeaderSwitch";
import CampaignUpdateModal from "./CampaignDetailsModal";
import CampaignDetailsRegions from "./CampaignDetailsRegions";
import cm from "./style.module.scss";

export interface CampaignDetailsHeaderProps {
  data: any;
  chosenMinBid: () => any;
  settings: any;
}

const CampaignDetailsHeader = ({
  data,
  chosenMinBid,
  settings,
}: CampaignDetailsHeaderProps) => {
  const { t, i18n } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const location = useLocation();

  const [visible, setVisible] = useState(false);

  const [activeKey, setActiveKey] = useState("");

  const [dayPartingVisible, setDayPartingVisible] = useState(false);

  const [regionsVisible, setRegionsVisible] = useState(false);

  const [regionType, setRegionType] = useState<
    "ALL_REGIONS" | "SELECTED_REGIONS" | string
  >("");

  const [selectedRegions, setSelectedRegions] = useState<number[]>([]);

  const [dayParting, setDayParting] = useState<
    "ALL_TIMES" | "SCHEDULED" | undefined
  >(undefined);

  const open = (key: string) => {
    setVisible(true);
    setActiveKey(key);
  };

  const contextMenuRouting = location.state as any;

  const language = i18n.language.toUpperCase();

  useEffect(
    () =>
      contextMenuRouting !== null && contextMenuRouting?.data === true
        ? open("")
        : undefined,
    [contextMenuRouting]
  );

  const cancel = () => setVisible(false);

  const acos = data && data.bid_type === "MAX_ACOS";

  const editableBid =
    data?.bid_type === "FIXED" && data?.targeting_type === "AUTO";

  const localizedDays = useMemo(() => days(t), [t]);
  const localizedDaysMap = useMemo(() => daysMap(t), [t]);

  const reverseDaysMap = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(localizedDaysMap).map(([key, value]) => [value, key])
      ),
    [localizedDaysMap]
  );

  const [schedule, setSchedule] = useState<Schedule>(
    Array(7)
      .fill(undefined)
      .map(() => Array(24).fill(false))
  );

  const openDayParting = () => setDayPartingVisible(true);

  const allDay = useCallback(
    (newSchedule) => {
      Object.keys(data?.dayparting?.all_day).forEach((dayKey) => {
        if (data?.dayparting?.all_day[dayKey]) {
          const dayIndex = localizedDays.indexOf(reverseDaysMap[dayKey]);
          newSchedule[dayIndex] = Array(24).fill(true);
        }
      });
    },
    [data, localizedDays, reverseDaysMap]
  );

  const scheduled = useCallback(
    (newSchedule) => {
      Object.keys(data?.dayparting?.schedule).forEach((dayKey) => {
        const dayIndex = localizedDays.indexOf(reverseDaysMap[dayKey]);
        newSchedule[dayIndex] = data?.dayparting?.schedule[dayKey];
      });
    },
    [data, localizedDays, reverseDaysMap]
  );

  const defaultDayParting = useCallback(() => {
    const newSchedule = Array(7)
      .fill(undefined)
      .map(() => Array(24).fill(false));
    if (data?.dayparting?.all_day) allDay(newSchedule);
    if (data?.dayparting?.schedule) scheduled(newSchedule);
    setSchedule(newSchedule);
  }, [data, allDay, scheduled]);

  const updateValue = async (value: any) => {
    const response = await api.patch(`campaigns/${data.id}`, value);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (dayParting: any) => {
    await mutateAsync({ ...dayParting });
    queryClient.invalidateQueries("single_campaign", data.id);
  };

  const changeRegionValue = async (regions: any) => {
    await mutateAsync({ ...regions });
    queryClient.invalidateQueries("single_campaign", data.id);
  };

  const onOk = () => {
    setDayPartingVisible(false);
    changeValue({
      ...generateDayPartingObject(dayParting!, schedule, t),
    });
  };

  const onCancel = () => {
    setDayPartingVisible(false);
    const newSchedule = Array(7)
      .fill(undefined)
      .map(() => Array(24).fill(false));
    if (data?.dayparting?.all_day) allDay(newSchedule);
    if (data?.dayparting?.schedule) scheduled(newSchedule);
    setSchedule(newSchedule);
  };

  const handleFieldChange = ({ target }: any) => {
    setDayParting(target.value);
  };

  useEffect(() => {
    setDayParting(data?.dayparting?.all_week ? "ALL_TIMES" : "SCHEDULED");
    defaultDayParting();
  }, [data, defaultDayParting]);

  const activeRegions = data?.regions?.filter(
    (region: any) => region.status === "ACTIVE"
  );

  const regionStateSetFromData = () => {
    setRegionType(
      activeRegions?.length === settings?.regions?.length
        ? "ALL_REGIONS"
        : "SELECTED_REGIONS"
    );
    setSelectedRegions(
      activeRegions.map((region: any) => region.region_id) || []
    );
  };

  const openMultiRegion = () => {
    regionStateSetFromData();
    setRegionsVisible(true);
  };

  const handleMultiRegionChange = ({ target }: any) => {
    setRegionType(target.value);
  };

  const onMultiRegionCancel = () => {
    setRegionsVisible(false);
  };

  const onMultiRegionOk = async () => {
    try {
      await changeRegionValue({
        campaign_regions: {
          status: "ACTIVE",
          id:
            regionType === "ALL_REGIONS"
              ? settings?.regions?.map((region: any) => region.id)
              : selectedRegions,
        },
      });
    } catch (e) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.campaignForm.firstStep.errorStates.generalErr")
      );
    } finally {
      onMultiRegionCancel();
    }
  };

  return (
    <>
      {data !== undefined && (
        <header
          className={cc([cm.header, data.status === "PAUSED" ? cm.paused : ""])}
        >
          <RetailTitle level={2} className={cm.title}>
            {data.name}
            <span
              className={cc([cm.bigIcon, cm.iconContainer])}
              onClick={() => open("name")}
            >
              <EditOutlined />
            </span>
          </RetailTitle>
          <Row className={cm.bottomBar}>
            <CampaignDetailsHeaderSwitch id={data.id} initial={data.status} />
            <Col>
              <RetailText className={cm.label} size="xs">
                <DateOutlined style={{ marginRight: "0.75rem" }} />
                {t("pages.acc.campaignDetails.date")}
              </RetailText>
              <RetailText className={cm.label} size="xs">
                {data.start_date}
              </RetailText>
              <RetailText size="xs">-</RetailText>
              <RetailText className={cm.label} size="xs">
                {data.end_date
                  ? data.end_date
                  : t("components.endDatePicker.button")}
                <span
                  className={cc([cm.smallIcon, cm.iconContainer])}
                  onClick={() => open("end_date")}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            </Col>
            <RetailText className={cc(["flex", cm.label])} size="xs">
              <span className="flex">
                <WalletOutlined />
                {`${t("components.campaignForm.firstStep.budget")}:`}
              </span>
              <RetailText size="xs">
                {data.budget_type
                  ? t(
                      `pages.acc.campaignDetails.${data.budget_type.toLowerCase()}`
                    )
                  : ""}
              </RetailText>

              <RetailMoneyColumn
                value={data?.daily_budget || data?.total_budget}
              />
              {data.budget_type === "FLEXIBLE" && (
                <>
                  <RetailText
                    family="poppins"
                    size="xs"
                    className={cm.flexibleBudgetText}
                  >
                    -
                  </RetailText>
                  <RetailText
                    family="poppins"
                    size="xs"
                    className={cm.flexibleBudgetText}
                  >
                    {t("pages.acc.campaigns.table.budgetLimit", {
                      value: `${prefix()} ${data?.total_budget}`,
                    })}
                  </RetailText>
                </>
              )}
              <span
                className={cc([cm.smallIcon, cm.iconContainer])}
                onClick={() => {
                  open(
                    data?.budget_type === "TOTAL"
                      ? "total_budget"
                      : "daily_budget"
                  );
                }}
              >
                <EditOutlined />
              </span>
            </RetailText>
            <RetailText className={cm.label} size="xs">
              <span className="flex">
                <BidOutlined />
                {t("pages.acc.campaignDetails.bid")}
              </span>
              <RetailText
                className={cc([data.fixed_bid ? "flex" : "", cm.bidText])}
                size="xs"
              >
                {acos
                  ? `${data.max_acos}${t("pages.acc.campaignDetails.maxAcos")}`
                  : t(
                      `pages.acc.campaignDetails.${
                        data && data?.bid_type?.toLowerCase()
                      }`
                    )}
                {data.fixed_bid ? (
                  <RetailMoneyColumn value={data.fixed_bid} />
                ) : null}
                <span
                  className={cc([
                    cm.smallIcon,
                    cm.iconContainer,
                    !editableBid ? cm.uneditable : "",
                  ])}
                  onClick={() => open("bid")}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            </RetailText>
            {data?.spending_type && (
              <RetailText className={cc(["flex", cm.label])} size="xs">
                <span className="flex">
                  <DistributionOutlined />
                  {`${t("components.campaignForm.firstStep.spendingTitle")}:`}
                </span>
                <RetailText size="xs">
                  {t(
                    `components.campaignForm.firstStep.${data?.spending_type.toLowerCase()}`
                  )}
                </RetailText>
                {data?.front_loaded_percentage ? (
                  <RetailText size="xs">{`%${numFormatter(
                    data?.front_loaded_percentage
                  )}`}</RetailText>
                ) : null}
                <span
                  className={cc([cm.smallIcon, cm.iconContainer])}
                  onClick={() => open("spending_type")}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            )}
            {data?.dayparting && (
              <RetailText className={cc(["flex", cm.label])} size="xs">
                <span className="flex">
                  <LinesOutlined />
                  {`${t("components.campaignForm.firstStep.dayParting")}:`}
                </span>
                <RetailText size="xs">
                  {t(
                    `pages.acc.campaignDetails.${
                      data?.dayparting?.all_week ? "allTimes" : "scheduled"
                    }`
                  )}
                </RetailText>
                <span
                  className={cc([cm.smallIcon, cm.iconContainer])}
                  onClick={openDayParting}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            )}

            {settings?.is_multi_region && activeRegions && (
              <RetailText className={cc(["flex", cm.label])} size="xs">
                <span className="flex">
                  <EarthOutlined />
                  {t("pages.acc.campaignDetails.region")}
                </span>
                <RetailText size="xs">
                  {activeRegions.length >= 4
                    ? t("pages.acc.campaignDetails.regionLength", {
                        value: activeRegions.length,
                      })
                    : activeRegions
                        .map((region: any) => region.translations[language])
                        .join(", ")}
                </RetailText>
                <span
                  className={cc([cm.smallIcon, cm.iconContainer])}
                  onClick={openMultiRegion}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            )}
          </Row>
          <CampaignUpdateModal
            visible={visible}
            activeKey={activeKey}
            data={data}
            cancel={cancel}
            chosenMinBid={chosenMinBid}
            settings={settings}
          />
          <CampaignDetailsDayParting
            visible={dayPartingVisible}
            schedule={schedule}
            setSchedule={setSchedule}
            onOk={onOk}
            onCancel={onCancel}
            handleFieldChange={handleFieldChange}
            dayParting={dayParting}
          />
          <CampaignDetailsRegions
            visible={regionsVisible}
            regions={settings?.regions}
            selectedRegions={selectedRegions}
            multiRegion={regionType}
            error={false}
            handleFieldChange={handleMultiRegionChange}
            onOk={onMultiRegionOk}
            onClose={onMultiRegionCancel}
            setSelectedRegions={setSelectedRegions}
          />
        </header>
      )}
    </>
  );
};

export default CampaignDetailsHeader;
