import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStateColumn from "../../../../components/Column/RetailStateColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import RetailCreativeLanguageColumn from "../../../../components/Column/RetailCreativeLanguageColumn";
import VastPlayer from "../../../../components/Player/VastPlayer";
import RetailTable from "../../../../components/Table/RetailTable";

const VideoTable = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType="CREATIVES"
          />
        );
      case "state":
        return <RetailStateColumn value={value} type="creatives" />;
      case "image_url":
        return (
          <VastPlayer
            vastURL={records.vast_tag || ""}
            type="details"
            id={records?.creative_id}
          />
        );

      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
      case "CVR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      case "languages":
        return <RetailCreativeLanguageColumn value={value} />;
      default:
        return value ? value : "-";
    }
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "CREATIVES",
    renderColumns,
  };

  return (
    <RetailTable
      addBtnVisible={false}
      placeholder={t("pages.acc.campaignDetails.creativesPlaceholder")}
      tableConfig={tableConfig}
    />
  );
};

export default VideoTable;
