import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Form, FormInstance } from "antd";
import { ConfigStates, EntityStates, MethodStates } from "../../../utils/types";
import { switchMethod } from "../../../utils/helpers";
import useApi from "../../../api";
import RetailConfigurationsRow from "./RetailConfigurationsRow";
import RetailEntityRow from "./RetailEntityRow";
import RetailMappingRow from "./RetailMappingRow";
import RetailMethodRow from "./RetailMethodRow";
import RetailNotification from "../../Notification";

import cm from "./style.module.scss";

interface RetailDatasourceFormProps {
  form: FormInstance;
  entityStates: EntityStates;
  setEntityStates: React.Dispatch<React.SetStateAction<EntityStates>>;
  methodStates: MethodStates;
  setMethodStates: React.Dispatch<React.SetStateAction<MethodStates>>;
  configStates: ConfigStates;
  setConfigStates: React.Dispatch<React.SetStateAction<ConfigStates>>;
  validateFields: () => void;
}

const navigation = ["entity", "method", "configurations", "mapping"];

const RetailDatasourceForm = ({
  form,
  entityStates,
  setEntityStates,
  methodStates,
  setMethodStates,
  configStates,
  setConfigStates,
  validateFields,
}: RetailDatasourceFormProps) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const queryClient = useQueryClient();
  const data: any = queryClient.getQueryData("entities");

  const [activeState, setActiveState] = useState<{
    activatedSteps: string[];
    currentActive: string | null;
  }>({
    activatedSteps: ["entity"],
    currentActive: "entity",
  });

  const scrollToActive = (newSection: string) => {
    const element = document.getElementById(newSection);
    if (element) {
      const top = element.offsetTop;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
    setActiveState((prev) => ({
      ...prev,
      currentActive: newSection,
      activatedSteps: prev.activatedSteps.includes(newSection)
        ? prev.activatedSteps
        : [...prev.activatedSteps, newSection],
    }));
  };

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    let currentActive: string | null = null;

    navigation.forEach((nav) => {
      const section = document.getElementById(nav);
      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        if (
          scrollPosition >= sectionTop - 300 &&
          scrollPosition < sectionTop + sectionHeight - 300 &&
          activeState.activatedSteps.includes(nav)
        ) {
          currentActive = nav;
        }
      }
    });

    if (currentActive && currentActive !== activeState.currentActive) {
      setActiveState((prev) => ({
        ...prev,
        currentActive,
      }));
    }
  }, [activeState]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const switchErrKey = (err: AxiosError) => {
    if (err?.request?.responseText?.includes("already a field"))
      return "existsErr";
    else if (err?.request?.responseText?.includes("feed_url field"))
      return "feedErr";
    else if (err?.request?.responseText?.includes("schedule_interval"))
      return "cycleErr";
    else if (err?.request?.responseText?.includes("could not be accessed"))
      return "permissionErr";
    else return "generalErr";
  };

  const fetchAndMap = async () => {
    setConfigStates((prev) => ({ ...prev, loading: true }));
    const urlConfig = { url: configStates.url };
    const xml = configStates.xml;
    const formData = new FormData();
    formData.append("file", configStates.file);

    const switchConfig = () => {
      switch (methodStates.selected) {
        case "googleFeedFile":
          return formData;
        case "googleFeedDirectXml":
          return xml;
        default:
          return urlConfig;
      }
    };

    try {
      const response = await api.post(
        `data_sources/fetch_and_map?import_method=${switchMethod(
          methodStates.selected
        )}`,
        switchConfig(),
        {
          headers: {
            "Content-Type":
              methodStates.selected === "googleFeedDirectXml"
                ? "text/plain"
                : "application/json",
          },
        }
      );
      setConfigStates((prev) => ({ ...prev, data: response.data }));
      scrollToActive("mapping");
    } catch (error) {
      console.error(error);
      RetailNotification.showNotification(
        "error",
        "",
        t(`pages.admin.createDatasource.${switchErrKey(error)}`)
      );
    } finally {
      setConfigStates((prev) => ({ ...prev, loading: false }));
    }
  };

  const onFinishFailed = () => {
    RetailNotification.showNotification(
      "error",
      t("components.notification.formTitle"),
      t("components.notification.formText")
    );
  };

  return (
    <Form
      form={form}
      onFinish={fetchAndMap}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      colon={false}
      requiredMark={false}
    >
      <section className={cm.container}>
        {/* Navigation */}
        <aside className={cm.navigation}>
          <ul className={cm.list}>
            {navigation.map((nav) => (
              <li
                key={nav}
                className={`${cm.listItem} ${
                  activeState.currentActive === nav ? cm.active : ""
                }`}
              >
                {t(`components.datasource.${nav}`)}
              </li>
            ))}
          </ul>
        </aside>
        {/* Content */}
        <main className={cm.main}>
          <RetailEntityRow
            entityStates={entityStates}
            setEntityStates={setEntityStates}
            setActive={scrollToActive}
          />
          <RetailMethodRow
            methodStates={methodStates}
            setMethodStates={setMethodStates}
            isActive={entityStates.filled}
            setActive={scrollToActive}
          />
          <RetailConfigurationsRow
            configStates={configStates}
            setConfigStates={setConfigStates}
            isActive={methodStates.filled}
            selected={methodStates.selected}
            form={form}
          />
          <RetailMappingRow
            isActive={configStates.data !== null}
            data={data}
            importedData={configStates.data}
            validateFields={validateFields}
          />
        </main>
      </section>
    </Form>
  );
};

export default RetailDatasourceForm;
