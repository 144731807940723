import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Select from "antd/lib/select";
import cc from "classcat";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import useApi from "../../../api";
import { ReactComponent as WarningFilled } from "../../../assets/icons/warningBlueFilled.svg";
import RetailSettingsHeader from "../../../components/Layout/RetailSettingsHeader";
import RetailSettingsLayout from "../../../components/Layout/RetailSettingsLayout";
import RetailSelect from "../../../components/Select/RetailSelect";
import RetailInfoTag from "../../../components/Tag/RetailInfoTag";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useSettings from "../../../hooks/useSettings";
import {
  renderSettingsLinks,
  settingsSuccessNotification,
} from "../../../utils/helpers";
import cm from "./style.module.scss";

const { Option, OptGroup } = Select;

const AuctionSettingsPage = () => {
  const { t } = useTranslation();

  const { data } = useSettings("MARKETPLACE");

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      auction_type: data?.data.auction_type,
    });
  }, [data?.data, form]);

  const updateValue = async (data: string) => {
    const config = { auction_type: data };
    const response = await api.patch(`/settings`, config);
    return response;
  };

  const { mutateAsync, isLoading } = useMutation(updateValue);

  const changeValue = async (settings: any) => {
    await mutateAsync(settings);
    queryClient.refetchQueries("settings");
  };

  const onFinish = () => {
    try {
      changeValue(form.getFieldValue("auction_type"));
      settingsSuccessNotification(t);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = () => form?.submit();

  return (
    <RetailSettingsLayout spinning={isLoading}>
      <RetailSettingsHeader type="auction" onClick={submit} />
      <div>
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          requiredMark={false}
        >
          <div className={cc([cm.flex, cm.container])}>
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.auctionSettings.type")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.auctionSettings.typeText")}
              </RetailText>
              {renderSettingsLinks(
                t("marketplaceDependentSettings.auction_link"),
                {
                  className: cm.link,
                  text: t("pages.admin.auctionSettings.link"),
                }
              )}
              <RetailInfoTag
                type="SETTINGS"
                closable={false}
                className={cm.warning}
              >
                <WarningFilled />
                {t("pages.admin.auctionSettings.warning")}
              </RetailInfoTag>
            </div>
            <Form.Item
              name="auction_type"
              rules={[
                {
                  required: true,
                  message: t("pages.admin.auctionSettings.inputError"),
                },
              ]}
            >
              <RetailSelect
                className={cm.select}
                dropdownClassName={cc(["text-select", cm.dropdown])}
                placeholder=""
              >
                <Option value="FIRST_PRICE">
                  {t("pages.admin.auctionSettings.firstOpt")}
                </Option>
                <OptGroup
                  label={t("pages.admin.auctionSettings.firstOptText")}
                />
                <Option value="SECOND_PRICE">
                  {t("pages.admin.auctionSettings.secondOpt")}
                </Option>
                <OptGroup
                  label={t("pages.admin.auctionSettings.secondOptText")}
                />
              </RetailSelect>
            </Form.Item>
          </div>
        </Form>
      </div>
    </RetailSettingsLayout>
  );
};

export default AuctionSettingsPage;
