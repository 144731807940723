import Modal, { ModalProps } from "antd/lib/modal";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailPreVideoContainer from "../../Container/RetailPreVideoContainer";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface CreativePreviewModalProps extends ModalProps {
  url: string;
  type: "ad" | "creative" | "video";
}

const CreativePreviewModal = ({
  url,
  type,
  onCancel,
  ...modalProp
}: CreativePreviewModalProps) => {
  const { t } = useTranslation();

  const switchModalContent = () => {
    if (type === "video" && url)
      return (
        <video controls={true}>
          <source src={url} type="video/mp4" />
        </video>
      );
    else if (type === "video" && !url) return <RetailPreVideoContainer />;

    return <img src={url} alt="" />;
  };

  return (
    <Modal
      closable
      closeIcon={<CloseOutlined />}
      centered
      onCancel={onCancel}
      className={cc(["campaign-modal", cm.modal])}
      destroyOnClose
      title={
        <RetailTitle level={4} noMargin>
          {t(`pages.admin.creatives.${type}ModalTitle`)}
        </RetailTitle>
      }
      footer={[
        <RetailMainButton
          hasBackground
          className={cm.modalBtn}
          onClick={onCancel}
        >
          {t("common.okay")}
        </RetailMainButton>,
      ]}
      {...modalProp}
    >
      <div className={`${cm.container} ${url ? cm.withURL : ""}`}>
        {switchModalContent()}
      </div>
    </Modal>
  );
};

export default CreativePreviewModal;
