import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import RetailText from "../../Typography/RetailText";

import cm from "./style.module.scss";

export interface RetailRoleRestrictionContainerProps {
  handleRestriction: (value: boolean) => void;
  restricted: boolean;
  type?: "modal" | "drawer";
}

const RetailRoleRestrictionContainer = ({
  handleRestriction,
  restricted,
  type = "modal",
}: RetailRoleRestrictionContainerProps) => {
  const { t } = useTranslation();
  return (
    <div className={cm[`${type}Box`]}>
      <article>
        <RetailText weight="medium" className={cm.restrictionTitle}>
          {t("pages.admin.invited.restriction")}
        </RetailText>
        <RetailText className={cm.restrictionText} weight="medium">
          {t("pages.admin.invited.restrictionText")}
        </RetailText>
      </article>
      <Switch onChange={handleRestriction} checked={restricted} />
    </div>
  );
};

export default RetailRoleRestrictionContainer;
