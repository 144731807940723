import cc from "classcat";
import { useState } from "react";

import {
	CloseOutlined,
	MenuOutlined,
} from "@ant-design/icons";

import logo from "../../../../../assets/images/landing_page/logo.png";
import cm from "./style.module.scss";

const TeknosaLandingNavbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggle = () => setIsNavVisible(!isNavVisible);

  return (
    <nav className={cc([cm.landingNav, isNavVisible ? cm.visible : ""])}>
      <div>
        <img src={logo} alt="logo" />
        {isNavVisible ? (
          <CloseOutlined onClick={toggle} />
        ) : (
          <MenuOutlined onClick={toggle} />
        )}

        <ul className={isNavVisible ? cm.activeNav : ""}>
          <li>
            <a href="/case-study" className={cm.contact}>
              Case Study
            </a>
          </li>
          <li>
            <a href="mailto:reklam@teknosa.com" className={cm.contact}>
              İletişim
            </a>
          </li>
          <li>
            <a href="/login" className={cm.login}>
              Giriş Yap
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TeknosaLandingNavbar;
