import Col from "antd/lib/col";
import Popover from "antd/lib/popover";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as DownloadOutlined } from "../../../assets/icons/downloadOutlined.svg";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailExportBottomBarProps {
  tableExport(value: "excel" | "pdf" | "csv"): void;
}
const RetailExportBottomBar = ({ tableExport }: RetailExportBottomBarProps) => {
  const { t } = useTranslation();

  const content = (
    <div className={cm.exportFormats}>
      <RetailText
        family="poppins"
        size="xs"
        onClick={() => tableExport("excel")}
      >
        .XLSX
      </RetailText>
      {/* <RetailText family="poppins" size="xs" onClick={() => tableExport("pdf")}>
        .PDF
      </RetailText> */}
      <RetailText family="poppins" size="xs" onClick={() => tableExport("csv")}>
        .CSV
      </RetailText>
    </div>
  );

  return (
    <Popover
      content={content}
      overlayClassName={cm.export}
      arrowPointAtCenter={true}
      placement="top"
    >
      <Col className={cc(["flex", cm.iconContainer])}>
        <DownloadOutlined />
        <RetailText size="xs" weight="bold" className={cm.text}>
          {t("common.export")}
        </RetailText>
      </Col>
    </Popover>
  );
};

export default RetailExportBottomBar;
