import Form from "antd/lib/form";
import Popover from "antd/lib/popover";
import Spin from "antd/lib/spin";
import Switch from "antd/lib/switch";
/* import { ReactComponent as DownloadOutlined } from "../../../assets/icons/downloadOutlined.svg"; */
import cc from "classcat";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import { CopyOutlined } from "@ant-design/icons";

import useApi from "../../../api";
import RetailSettingsHeader from "../../../components/Layout/RetailSettingsHeader";
import RetailSettingsLayout from "../../../components/Layout/RetailSettingsLayout";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useSettings from "../../../hooks/useSettings";
import cm from "./style.module.scss";
import { renderSettingsLinks } from "../../../utils/helpers";

const IntegrationInformationPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { data } = useSettings("INTEGRATION");

  const { data: settings } = useSettings("MARKETPLACE");

  const [isCopied, setIsCopied] = useState(false);

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const copyTextToClipboard = async () =>
    await navigator.clipboard.writeText(data?.data?.api_key);

  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    form.setFieldsValue({ impression: settings?.data?.auto_impression });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.data?.auto_impression]);

  const updateValue = async (value: boolean) =>
    await api.patch(`/settings`, { auto_impression: value });

  const { mutateAsync, isLoading } = useMutation(updateValue);

  const changeValue = async (value: boolean) => {
    await mutateAsync(value);
    queryClient.refetchQueries("settings");
  };

  return (
    <RetailSettingsLayout>
      <RetailSettingsHeader type="integration" />
      <div>
        <div className={cc(["flex", cm.start, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.integrationInfo.api")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.integrationInfo.apiText")}
            </RetailText>
            {renderSettingsLinks(t("marketplaceDependentSettings.api_link"), {
              className: cm.link,
              text: t("pages.admin.integrationInfo.apiLink"),
            })}
          </div>
          <div className={cc(["flex", cm.api, cm.justify])}>
            <RetailText size="xs" family="poppins" className={cm.apiText}>
              {data?.data?.api_key}
            </RetailText>
            <Popover
              content={
                isCopied
                  ? t("pages.admin.integrationInfo.copied")
                  : t("pages.admin.integrationInfo.copy")
              }
              trigger="hover"
            >
              <CopyOutlined className={cm.icon} onClick={handleCopyClick} />
            </Popover>
          </div>
        </div>
        <Form
          form={
            form
          } /* initialValues={{status: settings?.data?.auto_impression}} */
        >
          <div className={cc([cm.flex, cm.container])}>
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.integrationInfo.auto")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.integrationInfo.autoText")}
              </RetailText>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {renderSettingsLinks(
                  t("marketplaceDependentSettings.auto_impression_link"),
                  {
                    className: cm.link,
                    text: t("pages.admin.integrationInfo.link"),
                  }
                )}
              </RetailText>
            </div>
            <Spin spinning={isLoading}>
              <div className={cc(["flex", cm.baseline])}>
                <Form.Item name="impression" valuePropName="checked">
                  <Switch onChange={changeValue} />
                </Form.Item>
                <RetailText size="xs" family="poppins">
                  {settings?.data?.auto_impression
                    ? t("pages.admin.advertiserSettings.open")
                    : t("pages.admin.advertiserSettings.closed")}
                </RetailText>
              </div>
            </Spin>
          </div>
        </Form>
        {/* <div className={cc(["flex", cm.start, cm.container])}>
            <div>
              <RetailTitle level={5} className={cm.title}>
                {t("pages.admin.integrationInfo.ftp")}
              </RetailTitle>
              <RetailText size="xxxs" family="poppins" className={cm.text}>
                {t("pages.admin.integrationInfo.ftpText")}
              </RetailText>
            </div>
            <div className="flex">
              <DownloadOutlined />
              <RetailText
                size="xs"
                weight="bold"
                className={cc([cm.download, cm.icon])}
              >
                {t("pages.admin.integrationInfo.download")}
              </RetailText>
            </div>
          </div> */}
      </div>
    </RetailSettingsLayout>
  );
};

export default IntegrationInformationPage;
