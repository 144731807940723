import { CheckboxValueType } from "antd/lib/checkbox/Group";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

import { ReactComponent as CreativeLanguage } from "../../../../../../assets/icons/creativeLanguage.svg";
import { renderFlagOrItem } from "../../../../../../utils/helpers";
import { Img } from "../../../../../../utils/types";
import RetailText from "../../../../../Typography/RetailText";
import cm from "./style.module.scss";

interface CreativeActionsProps {
  item: Img;
  isLanguageSelectable: boolean;
  selectedLanguages: CheckboxValueType[];
  open: (url: string) => void;
  setLanguageDrawerVisible: (visible: boolean) => void;
  deleteCreative: (uuid: string) => void;
}

const CreativeActions = ({
  item,
  isLanguageSelectable,
  selectedLanguages,
  open,
  setLanguageDrawerVisible,
  deleteCreative,
}: CreativeActionsProps) => {
  const { t } = useTranslation();

  const { uuid, url, size_valid, languages } = item;

  const className = cc(["flex", cm.iconBtn]);

  const languageClassName = cc([
    "flex",
    cm.iconBtn,
    languages && languages?.length > 0 ? cm.languageActive : "",
  ]);

  const handleOpen = () => open(url);

  const handleDelete = () => deleteCreative(uuid);

  const openDrawer = () => setLanguageDrawerVisible(true);

  if (size_valid) {
    return (
      <div className="flex">
        <div className={className} onClick={handleOpen}>
          <SearchOutlined />
        </div>
        {isLanguageSelectable && (
          <div className={languageClassName} onClick={openDrawer}>
            <div className={cm.languageContainer}>
              <RetailText size="xxxs" weight="bold">
                {renderFlagOrItem(languages || selectedLanguages)}
              </RetailText>
            </div>
            <CreativeLanguage className={cm.language} />
          </div>
        )}

        <div className={className} onClick={handleDelete}>
          <DeleteOutlined className={cm.delete} />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={cc(["flex", cm.justDelete])} onClick={handleDelete}>
        <DeleteOutlined className={cm.delete} />
        <RetailText weight="bold" size="xs">
          {t("common.delete")}
        </RetailText>
      </div>
      <div className={cm.invalidTag}>
        <RetailText family="poppins" weight="bold" size="xxxs">
          {t("components.campaignForm.firstStep.invalid")}
        </RetailText>
      </div>
    </>
  );
};

export default CreativeActions;
