import { useTranslation } from "react-i18next";

import { ReactComponent as PlayButtonFilled } from "../../../assets/icons/playButtonFilled.svg";
import { ReactComponent as PlayButtonOutlined } from "../../../assets/icons/playButtonOutlined.svg";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailPlayButtonProps {
  type: "form" | "details" | "inspect";
}

const RetailPlayButton = ({ type }: RetailPlayButtonProps) => {
  const { t } = useTranslation();

  const fontSize = type === "inspect" ? "s" : "xxxs";

  const className = `flex ${cm.container} ${cm[`${type}Icon`]} `;

  if (type === "details") {
    return (
      <div className={cm.btn}>
        <PlayButtonOutlined />
      </div>
    );
  }

  return (
    <div className={className}>
      <PlayButtonFilled />
      <RetailText size={fontSize} weight="bold">
        {t("common.watch")}
      </RetailText>
    </div>
  );
};

export default RetailPlayButton;
