import Tag, { TagProps } from "antd/lib/tag";
import cc from "classcat";
import React from "react";

import cm from "./style.module.scss";

export interface RetailTableTagProps extends TagProps {
  mode:
    | "unfilledGreen"
    | "unfilledBlue"
    | "unfilledYellow"
    | "unfilledRed"
    | "filledGreen"
    | "filledBlue"
    | "filledYellow"
    | "filledRed"
    | "green";
}

const RetailTableTag: React.FC<RetailTableTagProps> = ({
  mode,
  children,
  className,
  ...tagProps
}) => {
  return (
    <Tag className={cc([cm.tag, cm[mode], className || ""])} {...tagProps}>
      {children}
    </Tag>
  );
};

export default RetailTableTag;
