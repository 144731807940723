import Table from "antd/lib/table";
import cc from "classcat";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  Category,
  CategoryContext,
} from "../../../../context/CategoryProvider";
import { Product, ProductContext } from "../../../../context/ProductProvider";
import useTableFetch from "../../../../hooks/useTableFetch";
import { controlSelected, truncate } from "../../../../utils/helpers";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailSuggestionCard from "../../../Card/RetailSuggestionCard";
import RetailMoneyColumn from "../../../Column/RetailMoneyColumn";
import RetailTooltip from "../../../Tooltip/RetailTooltip";
import cm from "../style.module.scss";
import RetailPaginationButtons from "../../../Button/RetailPaginationButtons";

const RetailCategorySuggestionTable = () => {
  const { t } = useTranslation();

  const { setCategories, categories } = useContext(CategoryContext) as Category;

  const { selectedProducts } = useContext(ProductContext) as Product;

  const location = useLocation();

  const ad_type = location.state as { ad_type: string };

  const filter =
    ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
      ? [
          {
            key: "productResourceID",
            op: "in",
            value: selectedProducts.map((product: any) => product.sku),
          },
        ]
      : [];

  const { data } = useTableFetch(
    "category_form_table",
    false,
    {},
    undefined,
    filter
  );

  const suggestedCategoryColumns = [
    {
      title: t("common.table.category"),
      dataIndex: "text",
      render: (value: string) =>
        value && value.length > 25 ? (
          <RetailTooltip title={value}>{truncate(value)}</RetailTooltip>
        ) : (
          value
        ),
    },
    {
      title: t("components.table.keywords.suggested"),
      dataIndex: "suggested_bid",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.table.keywords.volume"),
      dataIndex: "volume",
    },
    {
      key: 5,
      title: "",
      dataIndex: "isAdded",
      render: (value: any, records: any) => (
        <RetailMainButton
          hasBackground={false}
          onClick={() => {
            setCategories((curr: any) => [...curr, records]);
          }}
          className={cm.btn}
          disabled={controlSelected(records.id, categories, data?.data.records)}
        >
          {t("common.add")}
        </RetailMainButton>
      ),
    },
  ];

  const handleAddAll = () => setCategories(data?.data.records);

  return (
    <div className={cm.borderContainer}>
      <div className={cm.firstBorder}></div>
      <div className={cm.secondBorder}></div>
      <div className={cm.suggestedContainer} data-cy="suggestion-table">
        <RetailSuggestionCard
          title={t(`components.table.categories.suggestTitle`)}
          text={t(`components.table.categories.suggestText`)}
          mode="CATEGORIES"
          onClick={handleAddAll}
          disabled={
            !data?.data.records || categories.length >= data?.data.records
          }
        />
        <Table
          dataSource={data?.data.records}
          columns={suggestedCategoryColumns}
          className={cc([cm.keyTable, cm.more])}
          pagination={{
            total: data?.data.total_records,
            pageSize: 3,
            showSizeChanger: false,
            itemRender(page, type, element) {
              return <RetailPaginationButtons type={type} element={element} />;
            },
          }}
          rowKey={(records) => records.id}
        />
      </div>
    </div>
  );
};

export default RetailCategorySuggestionTable;
