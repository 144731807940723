const appier_en = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/what-is-manual-targeting-how-is-it-done",
      bid_link:
        "/academy/blog/management/what-is-bidding-how-to-determine-your-bid",
      frequency_link:
        "/academy/blog/measuring/what-are-the-reporting-types-how-to-create-a-scheduled-report",
      report_type_link:
        "/academy/blog/measuring/what-are-the-predefined-reports-how-to-create-them",
      product_link:
        "/academy/blog/optimization/what-to-consider-when-choosing-a-product",
    },
    campaign_form_targeting_text:
      "You can set your targeting strategy for your campaign.",
    campaign_form_budget_text:
      "Select the budget type of the campaign and set the budget. Once your spend reaches the budget, the campaign will automatically stop.",
    campaign_form_creative_text:
      "You can upload the creatives you want to use in your campaign.",
    invoice_modal_help_text:
      "If you continue by clicking the Choose a payment method button, you are deemed to have confirmed that the amount to be collected will not be refunded.",
    invoice_error_modal_title: "Failed to Load Balance!",
    invoice_error_modal_text: "An error has occurred.",
  },
  marketplaceDependentSettings: {
    api_link: "",
    auto_impression_link: "",
    auction_link: "",
    product_link: "",
    stock_link: "",
    targeting_link: "",
    bidding_link: "",
  },
};

export default appier_en;
