import { useState } from "react";
import { StepAction } from "../utils/types";

interface UseOnboardingStepAction {
  visitedSteps: { [key: number]: boolean };
  getAction: () => StepAction;
  markStepAsVisited: () => void;
}

export const useOnboardingStepAction = (
  step: number
): UseOnboardingStepAction => {
  const [visitedSteps, setVisitedSteps] = useState<{ [key: number]: boolean }>(
    () => {
      const savedSteps = localStorage.getItem("visited_steps");
      return savedSteps ? JSON.parse(savedSteps) : {};
    }
  );

  const getAction = (): StepAction => {
    if (visitedSteps[step]) {
      return "patch";
    }
    return "post";
  };

  const markStepAsVisited = () => {
    const updatedSteps = {
      ...visitedSteps,
      [step]: true,
    };
    setVisitedSteps(updatedSteps);
    localStorage.setItem("visited_steps", JSON.stringify(updatedSteps));
  };

  return { visitedSteps, getAction, markStepAsVisited };
};
