import Col from "antd/lib/col";
import Row, { RowProps } from "antd/lib/row";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as BoxOutlined } from "../../assets/icons/boxOutlined.svg";
import { ReactComponent as PhotoFilled } from "../../assets/icons/photoFilled.svg";
import { ReactComponent as LoadingOutlined } from "../../assets/icons/loadingOutlined.svg";
import RetailText from "../Typography/RetailText";
import RetailTitle from "../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface EmptyProps extends RowProps {
  type: string;
  isError?: boolean;
  children?: React.ReactNode;
}

const Empty = ({
  type,
  isError = false,
  children,
  className,
  ...rowProps
}: EmptyProps) => {
  const { t } = useTranslation();

  const isSubVisible = type.includes("archived") || type.includes("invited");

  const classNameForEmptyTable =
    type === null || type.includes("table") ? cm.emptyForTable : "";

  const classNameForErr = isError ? cm.error : "";

  const switchEmptyIcon = (type: string) => {
    switch (type) {
      case "creativeForm":
        return <PhotoFilled />;
      case "report_loading":
        return <LoadingOutlined />;
      default:
        return <BoxOutlined />;
    }
  };

  return (
    <Row
      className={cc([
        cm.emptyContainer,
        classNameForEmptyTable,
        classNameForErr,
        className ? className : "",
      ])}
      {...rowProps}
    >
      <Col className={cm.emptyCol}>
        <Col className={cm.boxContainer}>{switchEmptyIcon(type)}</Col>
        <Col>
          <RetailTitle level={5} className={cm.emptyTitle}>
            {t(`components.empty.${type}_title`)}
          </RetailTitle>
        </Col>
        {!isSubVisible && (
          <Col>
            <RetailText family="poppins" size="xxs" className={cm.emptyText}>
              {t(`components.empty.${type}_text`)}
            </RetailText>
          </Col>
        )}
        {children && <Col>{children}</Col>}
      </Col>
    </Row>
  );
};

export default Empty;
