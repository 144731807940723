import Button, { ButtonProps } from "antd/lib/button";
import cc from "classcat";

import cm from "./style.module.scss";

export interface RetailMainButtonProps extends ButtonProps {
  hasBackground?: boolean;
  isPrimaryBorder?: boolean;
}

const RetailMainButton = ({
  hasBackground,
  isPrimaryBorder,
  children,
  className,
  ...buttonProps
}: RetailMainButtonProps) => {
  const styleClassName = () => {
    if (hasBackground) return cm.hasBackground;
    if (isPrimaryBorder) return cm.primaryBorder;
    return cm.noBackground;
  };

  return (
    <Button
      className={cc([styleClassName(), cm.btn, className || ""])}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default RetailMainButton;
