import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Category, CategoryContext } from "../../../context/CategoryProvider";
import { truncate } from "../../../utils/helpers";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailEditableColumn from "../../Column/RetailEditableColumn";
import RetailMoneyColumn from "../../Column/RetailMoneyColumn";
import RetailBidEditContainer, {
  Selected,
} from "../../Container/RetailBidEditContainer";
import RetailTooltip from "../../Tooltip/RetailTooltip";
import RetailFormTable from "../RetailFormTable";
import cm from "./style.module.scss";

interface SelectCategoriesTableProps {
  suggestCategories?: boolean;
  isDynamic?: boolean;
  error?: boolean;
  alreadySelectedData?: any; // Consider using a more specific type here
}

const SelectCategoriesTable = ({
  suggestCategories = false,
  isDynamic = false,
  error,
  alreadySelectedData,
}: SelectCategoriesTableProps) => {
  const { t } = useTranslation();

  const { deleteCategory } = useContext(CategoryContext) as Category;

  const [selected, setSelected] = useState<Selected>({
    data: {},
    editing: false,
    value: "",
    editing_field: "bid",
  });

  const renderBidColumn = useCallback(
    (value: number, records: any) => {
      if (!isDynamic)
        return <span>{t("components.table.keywords.dynamic")}</span>;

      if (selected?.data.id === records.id && selected.editing) {
        return (
          <RetailBidEditContainer
            selected={selected}
            setSelected={setSelected}
          />
        );
      }

      return (
        <RetailEditableColumn
          value={value}
          suggested_bid={records.suggested_bid}
          onClick={() => {
            setSelected({
              data: records,
              editing: true,
              editing_field: "bid",
              value: value || records.suggested_bid,
            });
          }}
        />
      );
    },
    [t, isDynamic, selected, setSelected]
  );

  const categoryColumns = [
    {
      title: t("common.table.category"),
      dataIndex: "text",
      render: (value: string) =>
        value && value.length > 25 ? (
          <RetailTooltip title={value}>{truncate(value)}</RetailTooltip>
        ) : (
          value
        ),
    },
    {
      title: t("components.table.keywords.suggested"),
      dataIndex: "suggested_bid",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.bid"),
      dataIndex: "bid",
      width: 125,
      render: renderBidColumn,
    },
    {
      title: t("components.table.keywords.volume"),
      dataIndex: "volume",
    },
    {
      key: 7,
      title: "",
      dataIndex: "action",
      render: (value: any, records: any) => (
        <RetailMainButton
          hasBackground={false}
          onClick={() => {
            deleteCategory(records.id);
            records.isAdded = false;
          }}
          className={cm.btn}
        >
          {t("common.remove")}
        </RetailMainButton>
      ),
    },
  ];

  return (
    <RetailFormTable
      error={error}
      type="CATEGORIES"
      columns={categoryColumns}
      suggest={suggestCategories}
      alreadySelectedData={alreadySelectedData}
    />
  );
};

export default SelectCategoriesTable;
