import ConnectedWithProducts from "../ConnectedWithProducts";
import CreativeStep from "../CreativeStep";
import RedirectURLInput from "../RedirectURLInput";
import VideoStep from "../VideoStep";

interface WithProductsWrapperProps {
  targeting: string;
  languages: string[];
  redirect_url: string;
  redirectURLError: boolean;
  connected_with_products: boolean;
  promote_product: boolean;
  type: string;
  displayError: boolean;
  videoError: boolean;
  handleFieldChange(e: any): void;
}

const WithProductsWrapper = ({
  targeting,
  languages,
  redirect_url,
  redirectURLError,
  connected_with_products,
  promote_product,
  type,
  displayError,
  videoError,
  handleFieldChange,
}: WithProductsWrapperProps) => {
  return (
    <>
      {type === "DISPLAY" ? (
        <CreativeStep
          languages={languages}
          targeting={targeting}
          error={displayError}
        />
      ) : (
        <VideoStep targeting={targeting} error={videoError} />
      )}
      <ConnectedWithProducts
        targeting={targeting}
        connected_with_products={connected_with_products}
        promote_product={promote_product}
        type={type}
        handleFieldChange={handleFieldChange}
      />
      <RedirectURLInput
        targeting={targeting}
        redirect_url={redirect_url}
        redirectURLError={redirectURLError}
        handleFieldChange={handleFieldChange}
      />
    </>
  );
};

export default WithProductsWrapper;
