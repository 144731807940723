import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Tabs } from "antd";
import { AuthContext, Auth } from "../../../../context/AuthProvider";
import "highlight.js/styles/night-owl.css";
import Highlight from "react-highlight";

import useOnboardingNavigate from "../../../../hooks/useOnboardingNavigate";
import useOnboardingUser from "../../../../hooks/useOnboardingUser";
import useOnboardingApi from "../../../../api/onboarding";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailText from "../../../../components/Typography/RetailText";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import RetailOnboardingCountContainer from "../../../../components/Container/RetailOnboardingCountContainer";
import RetailOnboardingButtonGroup from "../../../../components/Button/RetailOnboardingButtonGroup";
import RetailSuccessModal from "../../../../components/Modal/RetailSuccessModal";
import RetailTooltip from "../../../../components/Tooltip/RetailTooltip";
import { cleanOnboardingData } from "../../../../utils/helpers";
import { ReactComponent as CopyOutlined } from "../../../../assets/icons/copyOutlined.svg";
import { ReactComponent as ThunderFilled } from "../../../../assets/icons/thunderFilled.svg";
import { ReactComponent as RefreshOutlined } from "../../../../assets/icons/refreshOutlined.svg";

import cc from "classcat";
import cm from "./style.module.scss";

const OnboardingFourthStep = () => {
  const { t } = useTranslation();

  const { goToPreviousStep } = useOnboardingNavigate();

  const { onboardingApi } = useOnboardingApi();

  const { onboardingUser, loading } = useOnboardingUser();

  const { getUser } = useContext(AuthContext) as Auth;

  const [token, setToken] = useState<string>("");

  const [status, setStatus] = useState(false);

  const [visible, setVisible] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const [activeKey, setActiveKey] = useState("CUSTOM");

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("auth_token");
    if (tokenFromStorage) {
      try {
        setToken(JSON.parse(tokenFromStorage));
      } catch (error) {
        console.error("Error parsing token:", error);
      }
    }
  }, []);

  const checkConnection = useCallback(async () => {
    try {
      const response = await onboardingApi?.get("/check_traffic");
      if (response?.data?.result) setStatus(true);
    } catch (error) {
      console.error(error);
    }
  }, [onboardingApi]);

  useEffect(() => {
    if (!status) {
      const interval = setInterval(() => {
        checkConnection();
      }, 5000);
      return () => clearInterval(interval);
    }
  });

  const open = () => setVisible(true);

  const finishOnboarding = async () => {
    try {
      getUser(token);
      cleanOnboardingData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (key: string) => setActiveKey(key);

  const code = `<script type="text/javascript">
  (function () {
    if (!window.top.document.getElementById("retail-ads-library")) {
      var e = document.createElement("script");
      e.type = "text/javascript";
      e.defer = true;
      e.id = "retail-ads-library";
      e.src = "https://sdk.gowit.com/index.js";
      window.top.document.getElementsByTagName("head")[0].appendChild(e);
    }
    (window.top.retail_ads = window.top.retail_ads || []).push({
      ad_type: "banner",
      api_url: "${window.location.origin}",
      marketplace_id: "${onboardingUser?.marketplace_uuid}", 
      page_number: 1,
      placements: [{ 
        // You can replace with your specific placement ID
        placement_id: ${onboardingUser?.default_placement_id}, 
      }],
      adFailed: function () {
        console.log("Ad Failed To Load");
      },
      adLoaded: function () {
        console.log("Ad Loaded");
      },
    });
  })();
</script>
`;

  if (loading) return <Spin size="large" spinning={loading} />;

  const copyTextToClipboard = () => navigator.clipboard.writeText(code);

  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className={cc(["flex", cm.container])}>
        <article className={cm.textContainer}>
          <RetailOnboardingCountContainer />
          <RetailTitle className={cm.title} level={3}>
            {t("pages.auth.onboarding.embedCodeTitle")}
          </RetailTitle>
          <RetailText size="xs" weight="medium" className={cm.mainText}>
            {t("pages.auth.onboarding.embedCodeText")}
          </RetailText>
          <section className={cm.codeWrapper}>
            <div className={cm.codeContainer}>
              <Highlight className="javascript">{code}</Highlight>
            </div>
            <RetailTooltip
              title={
                isCopied
                  ? t("pages.admin.integrationInfo.copied")
                  : t("pages.admin.integrationInfo.copy")
              }
              trigger="hover"
            >
              <RetailText
                size="xxxs"
                weight="medium"
                className={cm.copyText}
                onClick={handleCopyClick}
              >
                Copy
                <CopyOutlined className={cm.copy} />
              </RetailText>
            </RetailTooltip>
          </section>
          <div
            className={cc([
              cm.activityWrapper,
              status ? cm.activityWrapperLive : cm.activityWrapperPending,
            ])}
          >
            <RetailText size="xs" weight="medium">
              {t("pages.auth.onboarding.activityTitle")}
            </RetailText>
            <RetailText size="xs" weight="bold" className={cm.autoText}>
              {t(`pages.auth.onboarding.${status ? "live" : "pending"}`)}
            </RetailText>
            <ThunderFilled />
          </div>
          <section className={cc(["flex", cm.connectionContainer])}>
            <RetailText size="xs" weight="medium" className={cm.connectionText}>
              {t("pages.auth.onboarding.connectionText")}
            </RetailText>
            <RetailMainButton
              hasBackground={false}
              className={cm.connectionBtn}
              onClick={checkConnection}
            >
              {t("pages.auth.onboarding.checkConnection")}
              <RefreshOutlined />
            </RetailMainButton>
          </section>

          <RetailOnboardingButtonGroup
            handleBack={goToPreviousStep}
            nextText={t("pages.auth.onboarding.finishOnboarding")}
            handleNext={open}
            disabled={!status}
          />
        </article>
      </section>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.fourthStepText")}
        step="fourth"
      >
        <Tabs className={cm.tabsContainer} onChange={handleTabChange}>
          <Tabs.TabPane
            tab={t("pages.auth.onboarding.custom")}
            key="CUSTOM"
            className={cm.tab}
          >
            <video
              src="./custom-code.mp4"
              controls={true}
              muted={true}
              autoPlay={activeKey === "CUSTOM"}
              loop={true}
              className={cm.video}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t("pages.auth.onboarding.gtm")}
            key="GTM"
            className={cm.tab}
          >
            <video
              src="./gtm-code.mp4"
              controls={true}
              muted={true}
              autoPlay={activeKey === "GTM"}
              loop={true}
              className={cm.video}
            />
          </Tabs.TabPane>
        </Tabs>
      </RetailOnboardingContainer>
      <RetailSuccessModal
        type="onboarding"
        visible={visible}
        setVisible={setVisible}
        onClick={finishOnboarding}
      />
    </>
  );
};

export default OnboardingFourthStep;
