import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import useApi from "../../api";

export type Keyword = {
  //Keywords
  keywords: any[];
  setKeywords(keyword: any): void;
  handleKeywordAdd(value: string, adTypeFromApi?: string): void;
  deleteKeyword(id: number): void;
  //Negative Keywords
  negativeKeywords: any[];
  setNegativeKeywords(keyword: any): void;
  handleNegativeKeywordAdd(value: string, adTypeFromApi?: string): void;
  deleteNegativeKeyword(id: number): void;
};

export const KeywordContext = createContext<Keyword | null>(null);

export const KeywordProvider: React.FC = ({ children }) => {
  const { api } = useApi();

  const location = useLocation();

  const adTypeFromLocationState = location.state as { ad_type: string };

  const [currentKeyword, setCurrentKeyword] = useState<any>(null);
  const [keywords, setKeywords] = useState<any[]>([]);

  const [currentNegativeKeyword, setCurrentNegativeKeyword] =
    useState<any>(null);
  const [negativeKeywords, setNegativeKeywords] = useState<any[]>([]);

  const ad_type = adTypeFromLocationState?.ad_type;

  const handleKeywordAdd = async (value: string, adTypeFromApi?: string) => {
    if (value === "") return;
    await api
      .get(
        `keywords/metrics?keyword=${value}&matchType=EXACT&campaignType=${
          adTypeFromApi || ad_type
        }`
      )
      .then((res) =>
        setCurrentKeyword({
          ...res.data,
          id: uuidv4(),
        })
      )
      .catch(() => console.error);
  };

  const deleteKeyword = (id: number) =>
    setKeywords(keywords.filter((word: any) => id !== word.id));

  useEffect(() => {
    currentKeyword !== null &&
      setKeywords((curr: any) => [...curr, currentKeyword]);
  }, [currentKeyword]);

  const handleNegativeKeywordAdd = async (
    value: string,
    adTypeFromApi?: string
  ) => {
    if (value === "") return;
    await api
      .get(
        `keywords/metrics?keyword=${value}&matchType=EXACT&campaignType=${
          adTypeFromApi || ad_type
        }`
      )
      .then((res) => setCurrentNegativeKeyword({ ...res.data, id: uuidv4() }))
      .catch(() => console.error);
  };

  const deleteNegativeKeyword = (id: number) =>
    setNegativeKeywords(negativeKeywords.filter((word: any) => id !== word.id));

  useEffect(() => {
    currentNegativeKeyword !== null &&
      setNegativeKeywords((curr: any) => [...curr, currentNegativeKeyword]);
  }, [currentNegativeKeyword]);

  return (
    <KeywordContext.Provider
      value={{
        keywords,
        setKeywords,
        handleKeywordAdd,
        deleteKeyword,
        negativeKeywords,
        setNegativeKeywords,
        handleNegativeKeywordAdd,
        deleteNegativeKeyword,
      }}
    >
      {children}
    </KeywordContext.Provider>
  );
};

export default KeywordProvider;
