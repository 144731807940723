import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as DownOutlined } from "../../../../assets/icons/downOutlined.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/rightArrowFilled.svg";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";

import cm from "./style.module.scss";

interface FieldSchema {
  default_system_fields: string;
  description: string;
  enum: string[] | null;
  field: string;
  required?: boolean;
  type: string;
}

interface ImportedField {
  default_system_fields?: string;
  field: string;
  sample_data: string;
}

interface RetailMappingRowProps {
  isActive: boolean;
  importedData: any;
  data: any;
  validateFields: () => void;
}

const RetailMappingRow = ({
  isActive,
  importedData,
  data,
  validateFields,
}: RetailMappingRowProps) => {
  const { t } = useTranslation();

  const stickyRef = useRef<HTMLDivElement>(null);

  const [showFields, setShowFields] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  const requiredFields = data?.data?.product?.filter(
    (item: any) => item.required
  );

  const notRequiredFields = data?.data?.product?.filter(
    (item: any) => !item.required
  );

  const getImportedField = (fieldName: string): ImportedField | null => {
    if (!importedData?.imported_fields) return null;
    const importedField = importedData.imported_fields.find(
      (imported: ImportedField) => imported.default_system_fields === fieldName
    );

    return (
      importedField || {
        default_system_fields: fieldName,
        field: "",
        sample_data: "",
      }
    );
  };

  const toggleShowFields = () => setShowFields(!showFields);

  useEffect(() => {
    if (!isActive) return;
    const currentRef = stickyRef.current;

    const handleScroll = () => {
      if (currentRef) {
        setIsSticky(currentRef.getBoundingClientRect().top < 70);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isActive]);

  return (
    <section
      className={`${cm.contentContainer} ${isActive ? cm.active : ""}`}
      id="mapping"
    >
      <article
        className={`${cm.titleContainer} ${isSticky ? cm.sticky : ""}`}
        ref={stickyRef}
      >
        <Trans>
          <h2 className={cm.title}>
            {t("components.datasource.mappingTitle")}
          </h2>
        </Trans>
        {isActive && (
          <RetailMainButton hasBackground={true} onClick={validateFields}>
            {t("components.datasource.validate")}
          </RetailMainButton>
        )}
      </article>

      <p className={cm.text}>{t("components.datasource.mappingText")}</p>

      {isActive && (
        <section className={cm.mappingContainer}>
          <div>
            {/* Header */}
            <div className={cm.mappingHeader}>
              <RetailTitle level={5} noMargin className={cm.mappingTitle}>
                {t("components.datasource.system")}
              </RetailTitle>
              <RetailTitle level={5} noMargin className={cm.mappingTitle}>
                {t("components.datasource.imported")}
              </RetailTitle>
              <RetailTitle level={5} noMargin className={cm.mappingTitle}>
                {t("components.datasource.preview")}
              </RetailTitle>
            </div>
            {/* Rows */}
            <div className={cm.rowContainer}>
              {requiredFields.map((schemaField: FieldSchema) => {
                const importedField = getImportedField(schemaField.field);

                if (!importedField) return null;

                return (
                  <div key={schemaField.field} className={cm.mappingRow}>
                    <div className={cm.fieldRow}>
                      <p className={cm.fieldContainer}>
                        <RetailText
                          family="poppins"
                          size="xxxs"
                          className={cm.field}
                        >
                          {schemaField.field}
                        </RetailText>
                        <RetailText
                          family="poppins"
                          size="xxxs"
                          className={cm.type}
                        >
                          {schemaField.type}
                        </RetailText>
                        <RetailText
                          family="poppins"
                          size="xxxs"
                          className={cm.required}
                        >
                          {t("components.datasource.required")}
                        </RetailText>
                        <RightArrow className={cm.arrow} />
                      </p>
                    </div>
                    <div className={cm.matchedRow}>
                      <RetailText size="xxxs">{importedField.field}</RetailText>
                    </div>
                    <div className={cm.dataRow}>
                      <RetailText size="xxxs">
                        {schemaField.field === "status" &&
                        !importedField.sample_data
                          ? "ACTIVE"
                          : importedField.sample_data}
                      </RetailText>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={cm.showContainer} onClick={toggleShowFields}>
              <div className={cm.showHeader}>
                <DownOutlined
                  className={showFields ? cm.iconUp : cm.iconDown}
                />
                <RetailText
                  size="xxxs"
                  weight="bold"
                  className={cm.showText}
                  onClick={toggleShowFields}
                >
                  {t(
                    `components.datasource.${
                      showFields ? "hide" : "show"
                    }Fields`
                  )}
                </RetailText>
              </div>
            </div>
            <div className={`${showFields ? cm.open : cm.hidden}`}>
              {notRequiredFields.map((schemaField: FieldSchema) => {
                const importedField = getImportedField(schemaField.field);

                if (!importedField) return null;

                return (
                  <div key={schemaField.field} className={cm.mappingRow}>
                    <div className={cm.fieldRow}>
                      <p className={cm.fieldContainer}>
                        <RetailText
                          family="poppins"
                          size="xxxs"
                          className={cm.field}
                        >
                          {schemaField.field}
                        </RetailText>
                        <RetailText
                          family="poppins"
                          size="xxxs"
                          className={cm.type}
                        >
                          {schemaField.type}
                        </RetailText>
                        <RightArrow className={cm.arrow} />
                      </p>
                    </div>
                    <div className={cm.matchedRow}>
                      <RetailText size="xxxs">{importedField.field}</RetailText>
                    </div>
                    <div className={cm.dataRow}>
                      <RetailText size="xxxs">
                        {importedField.sample_data}
                      </RetailText>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

export default RetailMappingRow;
