import { Form } from "antd";
import { useTranslation } from "react-i18next";

import DayParting from "../../../../../components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/DayParting";
import CampaignDetailsModal from "../../../../../components/Modal/CampaignDetailsModal";
import { Schedule } from "../../../../../utils/types";

export interface CampaignDetailsDayPartingProps {
  visible: boolean;
  dayParting: "ALL_TIMES" | "SCHEDULED" | undefined;
  schedule: Schedule;
  setSchedule: (schedule: Schedule) => void;
  onOk: () => void;
  onCancel: () => void;
  handleFieldChange: (e: any) => void;
}

const CampaignDetailsDayParting = ({
  visible,
  dayParting,
  schedule,
  setSchedule,
  onOk,
  onCancel,
  handleFieldChange,
}: CampaignDetailsDayPartingProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const isScheduled = dayParting === "SCHEDULED";

  const isSelected = schedule.every((day) => day.every((hour) => !hour));

  return (
    <CampaignDetailsModal
      type="UPDATE_DAYPARTING"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      subtitle={t("pages.acc.campaignDetails.dayParting")}
      disabled={isScheduled && isSelected}
    >
      <Form
        initialValues={{
          dayParting,
        }}
        form={form}
      >
        <DayParting
          page="details"
          isDayParting={isScheduled}
          isVisible={true}
          schedule={schedule}
          setSchedule={setSchedule}
          handleFieldChange={handleFieldChange}
        />
      </Form>
    </CampaignDetailsModal>
  );
};

export default CampaignDetailsDayParting;
