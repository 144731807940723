import { Spin } from "antd";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import moment from "moment";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";

import useApi from "../../../api";
import { ReactComponent as DashedCircleOutlined } from "../../../assets/icons/dashedCircleOutlined.svg";
import { ReactComponent as EditOutlined } from "../../../assets/icons/editWhiteOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import Empty from "../../../components/Empty";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailNotification from "../../../components/Notification";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useTableFetch from "../../../hooks/useTableFetch";
import { numFormatter } from "../../../utils/helpers";
import ReportDetailsInfoTab from "./ReportDetailsInfoTab";
import ReportDetailsResultTab from "./ReportDetailsResultTab";
import cm from "./style.module.scss";

const ReportDetailsPage = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const { api } = useApi();

  const [activeKey, setActiveKey] = useState("info");

  const [fetchTotal, setFetchTotal] = useState(false);

  const queryKey = location.pathname.split("/").pop();

  const { data: totalData, isLoading: isTotalLoading } = useQuery(
    `${queryKey}_sum`,
    () => {
      const data = api.post(`/reports/${queryKey}/sum`, {
        page: 1,
        page_size: 50,
      });
      return data;
    },
    {
      retry: false,
      enabled: fetchTotal,
    }
  );

  const handleKey = (key: string) => setActiveKey(key);

  const {
    data,
    isError,
    setSort,
    setIsExported,
    setFormat,
    changePageSize,
    isLoading,
  } = useTableFetch("query", false);

  const navigate = useNavigate();

  const admin = location.pathname.includes("admin");

  const showTotalDataContainer = fetchTotal && totalData?.data?.records;

  const redirect = () =>
    navigate(
      admin
        ? `/admin/edit-report/${data?.data.report}`
        : {
            pathname: `/edit-report/${data?.data.report}`,
            search: location.search,
          }
    );

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(
        () =>
          navigate(
            admin
              ? "/admin/reports"
              : { pathname: "/reports", search: location.search }
          ),
        100
      );
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const switchColumn = (value: any, col: string) => {
    switch (col) {
      case "year":
        return moment(value).format("YYYY");
      case "month":
        return moment(value).locale(i18n.language).format("MMMM YYYY");
      case "week":
        return `
        ${t("components.table.result.weekOf", {
          value: moment(value).format("DD-MM-YYYY"),
        })}`;
      case "day":
        return moment(value).format("DD-MM-YYYY");
      case "hour":
        return moment(value).format("HH:mm DD-MM-YYYY");
      case "ACoS":
      case "CTR":
      case "CVR":
      case "VCR":
      case "viewabilityRate":
      case "fillRate":
        return `%${numFormatter(value ? parseFloat(value) : 0)}`;
      case "CPC":
      case "CPM":
      case "sumDirectSaleAmount":
      case "sumIndirectSaleAmount":
      case "sumSaleAmount":
      case "sumSpend":
        return <RetailMoneyColumn value={value} isZero={true} />;
      case "sumImpression":
      case "sumViewableImpression":
      case "sumClick":
      case "sumSale":
      case "sumIndirectSale":
      case "sumDirectSale":
      case "sumVideoStart":
      case "sumVideoComplete":
      case "RoAS":
        return `${numFormatter(value ? parseFloat(value) : 0)}`;
      case "campaignBidType":
        return t(`pages.acc.campaigns.table.${value.toLowerCase()}`);
      case "campaignBudgetType":
        return t(
          `components.campaignForm.firstStep.${value.toLowerCase()}BudgetLabel`
        );
      case "placementText":
        return value
          ? t(`components.table.result.${value?.toLowerCase()}`)
          : "-";
      case "keywordMatchType":
      case "customerType":
        return t(`common.${value?.toLowerCase()}`);
      case "campaignAdType":
        return t(
          `components.modal.selectCampaign.sponsored_${value?.toLowerCase()}_title`
        );
      default:
        return value;
    }
  };

  const colTitle = (col: string) => {
    switch (col) {
      case "CPC":
      case "CPM":
      case "ROAS":
      case "RoAS":
        return t(`marketplaceDependentTable.${col}`);
      default:
        return t(`components.table.result.${col}`);
    }
  };

  const toggleFetchData = () => setFetchTotal(!fetchTotal);

  return (
    <RetailPageContainer>
      {isLoading && activeKey === "info" ? (
        <Empty type="report_loading" className={cm.loading} />
      ) : (
        <>
          <header className={cm.header}>
            <RetailTitle level={2}>
              {data?.data.report_name ? data?.data.report_name : "-"}
            </RetailTitle>
            {data !== undefined && (
              <div className={cm.btnContainer}>
                <RetailMainButton className={cm.btn} onClick={toggleFetchData}>
                  <DashedCircleOutlined />
                  {t("pages.acc.reportDetails.total")}
                </RetailMainButton>

                <RetailMainButton
                  hasBackground
                  className={cm.btn}
                  onClick={redirect}
                >
                  <EditOutlined /> {t("common.edit")}
                </RetailMainButton>
              </div>
            )}
          </header>
          {isTotalLoading && <Spin spinning={isTotalLoading} />}
          <div
            className={`${cm.cardContainer} ${
              showTotalDataContainer && cm.visible
            } flex`}
          >
            {totalData?.data?.records?.[0] &&
              Object?.entries(totalData?.data?.records?.[0])
                .filter(([key]) =>
                  (totalData?.data?.columns ?? []).includes(key)
                )
                .map(([key, value]) => {
                  if (value !== null && value !== undefined) {
                    return (
                      <div key={key} className={cm.card}>
                        <RetailText
                          size="xs"
                          weight="medium"
                          className={cm.cardTitle}
                        >
                          {colTitle(key)}
                        </RetailText>
                        <RetailText weight="bold" className={cm.cardText}>
                          {switchColumn(value, key)}
                        </RetailText>
                      </div>
                    );
                  }
                  return null;
                })}
          </div>

          <Row className="bordered-container">
            <Tabs
              className={cm.tabs}
              activeKey={activeKey}
              onChange={handleKey}
            >
              <Tabs.TabPane tab={t("components.reportForm.title")} key="info">
                <ReportDetailsInfoTab data={data} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t("pages.acc.reportDetails.result")}
                key="result"
              >
                <ReportDetailsResultTab
                  data={data}
                  setSort={setSort}
                  setIsExported={setIsExported}
                  setFormat={setFormat}
                  changePageSize={changePageSize}
                  colTitle={colTitle}
                  switchColumn={switchColumn}
                  isLoading={isLoading}
                />
              </Tabs.TabPane>
            </Tabs>
          </Row>
        </>
      )}
    </RetailPageContainer>
  );
};

export default ReportDetailsPage;
