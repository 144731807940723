import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useApi from "../../../api";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailTitle from "../../../components/Typography/RetailTitle";

const NoMatch = () => {
  const { t } = useTranslation();

  const { roleForDashboard } = useApi();

  const link = roleForDashboard === "MARKETPLACE" ? "/admin/campaigns" : "/";

  return (
    <RetailPageContainer>
      <section
        className="flex"
        style={{
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <RetailTitle level={5}>{t("pages.acc.noMatch.title")}</RetailTitle>
        <RetailMainButton hasBackground>
          <Link to={link}>{t("pages.acc.noMatch.btn")}</Link>
        </RetailMainButton>
      </section>
    </RetailPageContainer>
  );
};

export default NoMatch;
