import DatePicker from "antd/lib/date-picker";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";
import cc from "classcat";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightOutlined.svg";
import RetailFormInput from "../RetailFormInput";
import { datePickerLocale } from "../../../utils/helpers";
import { FormItemProps } from "antd";

export interface RetailDatePickerProps
  extends PickerDateProps<any>,
    Pick<FormItemProps<string>, "rules"> {
  type: "reports" | "campaigns" | "modal";
  isFocused: boolean;
  small?: boolean;
}

const RetailDatePicker = ({
  type,
  isFocused,
  small = false,
  rules,
  ...pickerDateProps
}: RetailDatePickerProps) => {
  const { t, i18n } = useTranslation();

  const dateFormat = "DD/MM/YYYY";
  const disabledDate = (date: Moment) => date && date < moment().startOf("day");
  const disabledDateModal = (date: Moment) =>
    date && date < moment().startOf("day").add(1, "day");
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const close = () => setDatePickerOpen(false);

  const toggle = () => setDatePickerOpen((prev) => !prev);

  const switchDisabledDate = () => {
    switch (type) {
      case "modal":
        return disabledDateModal;
      case "campaigns":
        return disabledDate;
      case "reports":
        return undefined;
    }
  };

  const switchLabel = () => {
    switch (type) {
      case "modal":
        return t("pages.admin.coupons.label");
      case "campaigns":
      case "reports":
        return t("components.campaignForm.firstStep.startDateLabel");
    }
  };

  const helpText = () => {
    switch (type) {
      case "reports":
        return t("components.reportForm.startDateSub");
      case "campaigns":
        return "";
      default:
        return undefined;
    }
  };

  return (
    <RetailFormInput
      isFocused={isFocused}
      label={switchLabel()}
      help={helpText()}
      name="start_date"
      className={cc([
        type === "modal" ? "modal-floating floating" : "floating",
        type === "campaigns" ? "campaign-date-form" : "",
      ])}
      rules={rules}
    >
      <DatePicker
        className={`floating ${small ? "date-picker-small" : ""}`}
        onClick={toggle}
        onSelect={close}
        format={dateFormat}
        prevIcon={<LeftOutlined />}
        nextIcon={<RightOutlined />}
        onBlur={close}
        disabledDate={switchDisabledDate()}
        open={datePickerOpen}
        placeholder=""
        locale={datePickerLocale(i18n.language)}
        {...pickerDateProps}
      />
    </RetailFormInput>
  );
};

export default RetailDatePicker;
