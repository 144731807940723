import { numFormatter } from "../../../utils/helpers";

export interface RetailPercentageColumnProps {
  value: number;
}

const RetailPercentageColumn = ({ value }: RetailPercentageColumnProps) => {
  return <span>{`%${numFormatter(value)}`}</span>;
};

export default RetailPercentageColumn;
