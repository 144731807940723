import { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { UploadProps, Upload, Progress } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import axios from "axios";
import { ReactComponent as OnboardingCreativeUploadFilled } from "../../../../../assets/icons/onboardingCreativeUploadFilled.svg";
import RetailNotification from "../../../../../components/Notification";
import RetailText from "../../../../../components/Typography/RetailText";
import {
  CreativeContext,
  Creative,
} from "../../../../../context/CreativeProvider";
import { Img } from "../../../../../utils/types";

import cm from "./style.module.scss";

export interface OnboardingUploadContainerProps {
  advertiserID: number | undefined;
  onClick: () => void;
}

const OnboardingUploadContainer = ({
  advertiserID,
  onClick,
}: OnboardingUploadContainerProps) => {
  const { t } = useTranslation();

  const { setImg } = useContext(CreativeContext) as Creative;

  const [draggerStates, setDraggerStates] = useState<{
    isDragging: boolean;
    isLoading: boolean;
    name: string;
    percentage: number;
  }>({
    isDragging: false,
    isLoading: false,
    name: "",
    percentage: 0,
  });

  const dragCounter = useRef(0);

  const mainImgFile = useRef<File>();

  const draggerClass = draggerStates.isDragging ? cm.dragging : "";

  const isLoadingClass = draggerStates.isLoading ? cm.loading : "";

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;

    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    setDraggerStates((prevState) => ({
      ...prevState,
      name: file.current?.name!,
    }));

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name!);
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");
    formData.append("is_sample", "false");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        is_sample: formData.get("is_sample"),
        skip_size_validation: true,
      }),
    };

    try {
      setDraggerStates((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await axios
        .post(`api/advertisers/${advertiserID}/creatives`, config, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("auth_token") || ""
            )}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const img = new Image();

          img.src = response.data.image_url;

          img.onload = () => {
            setImg((prev: Img[]) => [
              ...prev,
              {
                url: img.src,
                size: `${img.width}x${img.height}`,
                creative_name: response.data.creative_name,
                uuid: response.data.id,
                size_valid: response.data.size_valid,
              },
            ]);
          };
        });
    } catch (err) {
      const maxFileSize = err?.request?.responseText?.includes(
        "file size is too large"
      );
      const errKey = maxFileSize ? "creativeSizeErr" : "generalErr";

      if (errKey) {
        return RetailNotification.showNotification(
          "error",
          "",
          t(`components.campaignForm.firstStep.errorStates.${errKey}`)
        );
      }
      console.error(err);
    } finally {
      onClick();
      setDraggerStates((prevState) => ({
        ...prevState,
        isDragging: false,
        isLoading: false,
        percentage: 0,
      }));
    }
  };

  const props: UploadProps = {
    name: "files",
    multiple: false,
    className: `dragger ${cm.uploadBanner}`,
    itemRender: () => <></>,
    beforeUpload: () => false,
    onChange: (f) => handleFileChange(f),
    accept: ".jpg, .jpeg, .png",
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    setDraggerStates((prevState) => ({
      ...prevState,
      isDragging: true,
    }));
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDraggerStates((prevState) => ({
        ...prevState,
        isDragging: false,
      }));
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggerStates((prevState) => ({
      ...prevState,
      isDragging: false,
    }));
    dragCounter.current = 0;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (draggerStates.isLoading) {
      interval = setInterval(() => {
        setDraggerStates((prevState) => ({
          ...prevState,
          percentage:
            prevState.percentage < 99
              ? prevState.percentage + 1
              : prevState.percentage,
        }));
      }, 30);
    } else {
      setDraggerStates((prevState) => ({
        ...prevState,
        percentage: 0,
      }));

      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [draggerStates.isLoading]);

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={draggerClass}
    >
      <div className={isLoadingClass}>
        <Upload.Dragger {...props}>
          <div className={`flex ${cm.innerUpload}`}>
            {draggerStates.isLoading ? (
              <Progress
                type="circle"
                trailColor="var(--secondary-3)"
                width={40}
                strokeColor={"var(--primary-4)"}
                format={() => (
                  <RetailText weight="bold" className={cm.percent}>
                    {draggerStates.percentage}%
                  </RetailText>
                )}
                percent={draggerStates.percentage}
              />
            ) : (
              <OnboardingCreativeUploadFilled className={cm.upload} />
            )}

            <article>
              <RetailText
                size="xs"
                weight={draggerStates.isLoading ? "bold" : "medium"}
                className={cm.uploadInnerTitle}
              >
                {draggerStates.isLoading ? (
                  draggerStates.name
                ) : (
                  <Trans i18nKey="pages.auth.onboarding.uploadInnerTitle" />
                )}
              </RetailText>

              <RetailText
                size="xxxs"
                weight="medium"
                className={cm.uploadInnerText}
              >
                {draggerStates.isLoading
                  ? t("pages.auth.onboarding.uploading")
                  : t("pages.auth.onboarding.uploadInnerText")}
              </RetailText>
            </article>
          </div>
        </Upload.Dragger>
      </div>
    </div>
  );
};

export default OnboardingUploadContainer;
