import { RefObject, useEffect } from "react";

const useSelectCloser = (
  ref: RefObject<HTMLElement>,
  visibility: (isVisible: boolean) => void
) => {
  //Close category select when click outside
  useEffect(() => {
    if (ref.current !== null) {
      let handler = (event: any) => {
        if (!ref.current?.contains(event.target)) visibility(false);
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  });
};

export default useSelectCloser;
