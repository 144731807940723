import Card, { CardProps } from "antd/lib/card";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseCircleOutlined } from "../../../../assets/icons/closeCircleOutlined.svg";
import { ReactComponent as PlusCircleOutlined } from "../../../../assets/icons/statsAddOutlined.svg";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailCampaignCardProps extends CardProps {
  title: string;
  text: any;
  selectedOptions: any;
  handleSelect(): void;
  handleDelete(title: string): void;
}

const RetailCampaignSelectableCard = ({
  title,
  text,
  selectedOptions,
  handleSelect,
  handleDelete,
}: RetailCampaignCardProps) => {
  const { t, i18n } = useTranslation();

  const isOnSelectedWidgets =
    selectedOptions && selectedOptions.find((opt: any) => opt[0] === title);

  const isMetricExist = (key: string) => i18n.exists(`common.table.${key}`);

  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`common.table.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  return (
    <Card
      className={cc([
        cm.cardContainer,
        isOnSelectedWidgets ? "" : cm.notSelected,
      ])}
    >
      <Card.Meta
        title={
          <div className={cm.meta}>
            <RetailText className={cm.title} size="xs">
              {renderMetric(title)}
            </RetailText>
            <div>
              {isOnSelectedWidgets !== undefined && selectedOptions !== null ? (
                <CloseCircleOutlined
                  onClick={() => handleDelete(title)}
                  className={cc([cm.icon, cm.delete])}
                />
              ) : (
                <PlusCircleOutlined
                  onClick={handleSelect}
                  className={cm.icon}
                />
              )}
            </div>
          </div>
        }
      />
      <RetailTitle className={cm.secondTitle}>{text}</RetailTitle>
      <RetailText type="secondary" size="xxs">
        {t("components.campaignCard.message")}
      </RetailText>
    </Card>
  );
};

export default RetailCampaignSelectableCard;
