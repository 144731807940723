import { useState } from "react";

const useSelectProducts = () => {
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  // Add products based on the selected rows
  const handleAdd = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    setSelectedProducts(selectedRows);
  };
  // Delete products based on the selected row keys
  const handleDelete = (selectedRowKeys: React.Key[], selectedRows: any[]) =>
    setSelectedProducts(
      selectedProducts.filter((product: any) =>
        selectedRowKeys.includes(product.id)
      )
    );
  // Delete all products
  const handleDeleteAllProducts = () => setSelectedProducts([]);

  return {
    selectedProducts,
    setSelectedProducts,
    handleAdd,
    handleDelete,
    handleDeleteAllProducts,
  };
};

export default useSelectProducts;
