import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import moment from "moment";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import useApi from "../../../api";
import { ReactComponent as CampaignSettings } from "../../../assets/icons/campaignSettings.svg";
import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkOutlined.svg";
import { ReactComponent as UpOutlined } from "../../../assets/icons/upOutlined.svg";
import useWidget from "../../../hooks/useWidget";
import {
	numFormatter,
	prefix,
} from "../../../utils/helpers";
import { Widgets } from "../../../utils/types";
import RetailLineChart from "../../Charts/RetailLineChart";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import RetailCampaignSelectableCard from "./RetailCampaignSelectableCard";
import RetailCampaignWidgetCard from "./RetailCampaignWidgetCard";
import cm from "./style.module.scss";

interface SelectedOption {
  [key: string]: any;
}

export interface RetailCampaignWidgetProps {
  page: Widgets;
}

const RetailCampaignWidget = ({ page }: RetailCampaignWidgetProps) => {
  const { t, i18n } = useTranslation();

  const { data, error, loading, selectedWidgets, changeValue, dataType } =
    useWidget(page);

  const [visible, setVisible] = useState(false);

  const [selectable, setSelectable] = useState(false);

  const [selected, setSelected] = useState<any>([]);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);

  const { roleForDashboard } = useApi();

  const isMetricExist = (key: string) => i18n.exists(`common.table.${key}`);

  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`common.table.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  const handleOpen = (title: string) => {
    const selected = selectedOptions.find(
      (widget: any) => widget[0] === title
    )!;
    setSelected([
      {
        id: renderMetric(selected[0]),
        data: selected[1].data.map((item: any) => ({
          x: moment(item.date).format("DD/MM/YYYY"),
          y: item.value,
        })),
      },
    ]);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setSelected([]);
  };

  const handleSelect = (title: string) => {
    if (selectedWidgets?.data[dataType()] === null) changeValue([title]);
    changeValue([...selectedWidgets?.data[dataType()], title]);
  };

  const handleDelete = (title: string) => {
    changeValue(
      selectedWidgets?.data[dataType()].filter((item: string) => item !== title)
    );
  };

  const handleClick = () => {
    setSelectable((prev) => !prev);
    handleClose();
  };

  useEffect(() => handleClose(), [i18n.language]);

  useEffect(() => {
    setSelectedOptions(
      data?.data &&
        selectedWidgets?.data[dataType()] &&
        Object.entries(data?.data.result).filter((item) => {
          return selectedWidgets?.data[dataType()].indexOf(item[0]) !== -1;
        })
    );
  }, [data?.data, selectedWidgets?.data, dataType()]);

  //Graph Component
  const graph = (
    <Row className={cm.container}>
      <Col span={24} className={cm.header}>
        <Col>
          <RetailTitle className={cm.graphTitle}>
            {selected.length > 0 ? selected[0].id : ""}
          </RetailTitle>
          <RetailText size="xs" className={cm.text}>
            {t("sider.user.campaign")}
          </RetailText>
        </Col>
        <UpOutlined className={cm.up} onClick={handleClose} />
      </Col>
      <Col span={24}>
        {selected && (
          <RetailLineChart height={116} data={selected} areaBaselineValue={0} />
        )}
      </Col>
    </Row>
  );

  const switchPrefix = (title: string, amount: string) => {
    switch (title) {
      case "sum_spend":
      case "sum_sale_amount":
      case "sum_direct_sale_amount":
      case "CPC":
      case "CPM":
        return `${prefix()}${amount}`;
      case "CTR":
        return `%${amount}`;
      default:
        return amount;
    }
  };

  const renderUI = !loading || !error;

  return (
    <>
      <Row
        className={cc([
          cm.row,
          roleForDashboard === "ADVERTISER" && page === "campaigns"
            ? cm.advertiserRow
            : "",
        ])}
        id="widgets"
      >
        {renderUI &&
          (selectable && data?.data.result
            ? Object.entries(data?.data.result).map(
                (widget: any, index: number) => (
                  <RetailCampaignSelectableCard
                    title={widget[0]}
                    text={
                      widget[1].amount !== undefined
                        ? switchPrefix(
                            widget[0],
                            numFormatter(
                              widget[1].amount,
                              widget[0] === "sum_spend"
                            )
                          )
                        : "0"
                    }
                    key={index}
                    handleSelect={() => handleSelect(widget[0])}
                    handleDelete={handleDelete}
                    selectedOptions={selectedOptions}
                  />
                )
              )
            : !loading &&
              selectedOptions?.map((widget: any, index: number) => (
                <RetailCampaignWidgetCard
                  widget={widget}
                  text={
                    widget[1].amount !== undefined
                      ? switchPrefix(
                          widget[0],
                          numFormatter(
                            widget[1].amount,
                            widget[0] === "sum_spend"
                          )
                        )
                      : "0"
                  }
                  selected={selected}
                  key={index}
                  handleOpen={() => handleOpen(widget[0])}
                  handleClose={handleClose}
                />
              )))}
        {renderUI && (
          <Card
            className={cc([cm.card, selectable ? cm.selectable : ""])}
            onClick={handleClick}
          >
            {selectable ? (
              <CheckOutlined className={cm.icon} />
            ) : (
              <CampaignSettings className={cm.icon} />
            )}
          </Card>
        )}
      </Row>
      {visible ? graph : null}
    </>
  );
};

export default RetailCampaignWidget;
