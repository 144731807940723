import { bg_mode } from "../../../utils/defaults";
import RetailAuthView from "./RetailAuthView";
import cm from "./style.module.scss";

export interface RetailAuthLayoutProps {
  children: React.ReactNode;
}

const RetailAuthLayout = ({ children }: RetailAuthLayoutProps) => {
  return (
    <section className={cm.container}>
      <RetailAuthView />
      {children}
      {bg_mode === "light" && <div className={cm.top}></div>}
    </section>
  );
};

export default RetailAuthLayout;
