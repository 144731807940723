import { useTranslation } from "react-i18next";

import { ReactComponent as PreVideoFilled } from "../../../assets/icons/preVideoFilled.svg";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

interface RetailPreVideoContainerProps {
  children?: React.ReactNode;
}

const RetailPreVideoContainer = ({
  children,
}: RetailPreVideoContainerProps) => {
  const { t } = useTranslation();
  return (
    <div className={cm.innerVideo}>
      <section className="flex">
        <div className={cm.innerVideoIcon}>
          <PreVideoFilled className={cm.videoIcon} />
        </div>
        <RetailTitle noMargin>
          {t("components.campaignForm.firstStep.videoInnerTitle")}
        </RetailTitle>
        <RetailText weight="medium">
          {t("components.campaignForm.firstStep.videoInnerText")}
        </RetailText>
      </section>
      {children}
    </div>
  );
};

export default RetailPreVideoContainer;
