import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "antd/lib/grid";
import { ReactComponent as DotFilled } from "../../../assets/icons/dotFilled.svg";
import { bg_mode } from "../../../utils/defaults";
import useAdvertiserBalance from "../../../hooks/useAdvertiserBalance";

import RetailMoneyColumn from "../../Column/RetailMoneyColumn";
import RetailText from "../../Typography/RetailText";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailBalanceContainerProps {
  className?: string;
}

const RetailBalanceContainer = ({ className }: RetailBalanceContainerProps) => {
  const { t } = useTranslation();

  const [isOnboardingUserValid, setIsOnboardingUserValid] = useState(false);

  useEffect(() => {
    let onboardingUser;

    try {
      onboardingUser = localStorage.getItem("onboarding_user");
    } catch (e) {
      console.error("Error accessing localStorage:", e);
      onboardingUser = null;
    }

    setIsOnboardingUserValid(
      onboardingUser !== null &&
        onboardingUser !== undefined &&
        typeof onboardingUser === "string"
    );
  }, []);

  const { data, error, isLoading } = useAdvertiserBalance(
    !isOnboardingUserValid
  );

  if (isLoading || error) return null;

  return (
    <Col
      className={cc([
        "flex",
        cm.balanceContainer,
        className || "",
        cm[bg_mode],
      ])}
    >
      <Col>
        <RetailText className={cm.balanceTitle} size="xxxs" weight="bold">
          {`${t("common.table.balance")}: `}
        </RetailText>
        <RetailText className={cm.balanceText} size="xxxs" weight="bold">
          <RetailMoneyColumn value={data?.data?.balance} isZero={true} />
        </RetailText>
      </Col>
      <DotFilled />
      <Col>
        <RetailText className={cm.balanceTitle} size="xxxs" weight="bold">
          {`${t("common.table.coupon")}: `}
        </RetailText>
        <RetailText className={cm.balanceText} size="xxxs" weight="bold">
          <RetailMoneyColumn value={data?.data?.coupons} isZero={true} />
        </RetailText>
      </Col>
    </Col>
  );
};

export default RetailBalanceContainer;
