import { createContext, useState } from "react";

type FilterValues = {
  selectedFilter: string;
  equality: string;
  selectedValue: string;
  id: number | null;
  tags: any[];
  setTags(tag: any): void;
  advertiserTags: any[];
  setAdvertiserTags(filter: any): void;
  handleChange(value: any, name: string): void;
};

const FilterContext = createContext<FilterValues>({
  selectedFilter: "",
  equality: "",
  selectedValue: "",
  id: null,
  tags: [],
  setTags: () => {},
  advertiserTags: [],
  setAdvertiserTags: () => {},
  handleChange: () => {},
});

export const FilterProvider: React.FC = ({ children }) => {
  const [filterValues, setFilterValues] = useState({
    selectedFilter: "",
    equality: "",
    selectedValue: "",
    id: 0,
  });

  const handleChange = (value: any, name: string) =>
    setFilterValues((prev) => ({
      ...prev,
      [name]: value,
      id: Math.floor(Math.random() * 10000) + 1,
    }));

  const [tags, setTags] = useState<any[]>([]);

  const [advertiserTags, setAdvertiserTags] = useState<any[]>([]);

  return (
    <FilterContext.Provider
      value={{
        ...filterValues,
        handleChange,
        tags,
        setTags,
        advertiserTags,
        setAdvertiserTags,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
