import {
	Input,
	Radio,
	RadioChangeEvent,
} from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import AntdInput from "antd/lib/input/Input";
import Upload from "antd/lib/upload";
import {
	UploadChangeParam,
	UploadFile,
	UploadProps,
} from "antd/lib/upload/interface";
import cc from "classcat";
import {
	useContext,
	useRef,
	useState,
} from "react";
import {
	TFunction,
	Trans,
	useTranslation,
} from "react-i18next";

import { DeleteOutlined } from "@ant-design/icons";

import useApi from "../../../../../api";
import { ReactComponent as CodeUploadFilled } from "../../../../../assets/icons/codeUploadFilled.svg";
import { ReactComponent as EditOutlined } from "../../../../../assets/icons/editWhiteOutlined.svg";
import { ReactComponent as EllipseOutlined } from "../../../../../assets/icons/ellipseOutlined.svg";
import { ReactComponent as VideoFilled } from "../../../../../assets/icons/videoFilled.svg";
import { ReactComponent as VideoUploadBackground } from "../../../../../assets/icons/videoUploadBackground.svg";
import { ReactComponent as VideoUploadFilled } from "../../../../../assets/icons/videoUploadFilled.svg";
import { ReactComponent as CreativeIcon } from "../../../../../assets/images/creatives.svg";
import {
	Creative,
	CreativeContext,
} from "../../../../../context/CreativeProvider";
import { Video } from "../../../../../utils/types";
import RetailMainButton from "../../../../Button/RetailMainButton";
import RetailPreVideoContainer from "../../../../Container/RetailPreVideoContainer";
import RetailDrawer from "../../../../Drawer/RetailDrawer";
import CreativePreviewModal from "../../../../Modal/CreativePreviewModal";
import RetailNotification from "../../../../Notification";
import VastPlayer from "../../../../Player/VastPlayer";
import RetailText from "../../../../Typography/RetailText";
import RetailTitle from "../../../../Typography/RetailTitle";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormInput from "../../../RetailFormInput";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

export interface SecondDrawerUploadInnerProps extends UploadProps {
  showVideo: boolean;
  video: Video[];
  changeShowVideo(): void;
  t: TFunction;
}

const SecondDrawerUploadInner = ({
  showVideo,
  video,
  changeShowVideo,
  t,
  ...props
}: SecondDrawerUploadInnerProps) => {
  return (
    <>
      {showVideo && video.length > 0 ? (
        <div className={cm.outerVideo}>
          <RetailPreVideoContainer>
            <div className={cm.footerVideo}>
              <RetailText weight="bold" className={cm.videoTitle}>
                {video[video.length - 1]?.creative_name}
              </RetailText>
              <RetailMainButton onClick={changeShowVideo}>
                {t("components.campaignForm.firstStep.changeVideo")}
              </RetailMainButton>
            </div>
          </RetailPreVideoContainer>
        </div>
      ) : (
        <Upload.Dragger {...props}>
          <div className={cm.uploadContainer}>
            <div className={cm.uploadInner}>
              <VideoUploadFilled
                className={`${cm.uploadIcon} ${cm.regularIcon}`}
              />
              <RetailText
                size="xs"
                weight="medium"
                className={cm.videoDraggerTitle}
              >
                <Trans>
                  {t("components.campaignForm.firstStep.videoDraggerTitle")}
                </Trans>
              </RetailText>
              <RetailText
                size="xxxs"
                weight="medium"
                className={cm.creativeText}
              >
                {t("components.campaignForm.firstStep.videoDraggerText")}
              </RetailText>
            </div>

            <div className={cm.infoList}>
              <div>
                <EllipseOutlined />
                <RetailText size="xxxs" weight="medium" className={cm.listItem}>
                  {t("components.campaignForm.firstStep.maxVideoSize")}
                </RetailText>
              </div>

              <div>
                <EllipseOutlined />
                <RetailText size="xxxs" weight="medium" className={cm.listItem}>
                  {t("components.campaignForm.firstStep.videoFormat")}
                </RetailText>
              </div>
            </div>
          </div>
        </Upload.Dragger>
      )}
    </>
  );
};

export interface SecondDrawerVastInnerProps {
  t: TFunction;
  onChange(e: any): void;
}

const SecondDrawerVastInner = ({ t, onChange }: SecondDrawerVastInnerProps) => {
  const [error, setError] = useState(false);

  const isValidVAST =
    /https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9&%_./~-]*)?/;

  const inputClassName = cc([
    "floating",
    cm.textarea,
    cm.vastTextArea,
    error ? cm.vastErrorInput : "",
  ]);

  const handleChange = (e: any) => {
    if (isValidVAST.test(e.target.value)) {
      setError(false);
      onChange(e);
    } else {
      setError(true);
    }
  };

  return (
    <>
      {error && (
        <article className={`flex ${cm.vastErrorContainer}`}>
          <RetailText size="xxxs" weight="medium">
            {t("components.campaignForm.firstStep.errorStates.vast")}
          </RetailText>
        </article>
      )}

      <Input.TextArea onChange={handleChange} className={inputClassName} />
    </>
  );
};

export interface CreativeNameFormProps {
  onFinish(): void;
  form: any;
  value: string;
  onChange(e: any): void;
  t: TFunction;
}

const CreativeNameForm = ({
  onFinish,
  form,
  value,
  onChange,
  t,
}: CreativeNameFormProps) => {
  return (
    <Form
      onFinish={onFinish}
      form={form}
      autoComplete="off"
      requiredMark={false}
      initialValues={{ video_name: value }}
      className={cm.creativeDrawerForm}
    >
      <RetailFormInput
        isFocused={value !== ""}
        label={t("components.campaignForm.firstStep.creativeVideoLabel")}
        className="floating"
        name="video_name"
        rules={[
          {
            required: true,
            message: t("components.campaignForm.firstStep.creativeError"),
          },
        ]}
      >
        <AntdInput
          className={cc(["floating", cm.creativeDrawerInput])}
          data-test="campaign-form-video-name"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={onChange}
          value={value}
        />
      </RetailFormInput>
    </Form>
  );
};

export interface VideoItemProps {
  singleVideo: Video;
  deleteVideo(uuid: string): void;
  openUpdateDrawer(uuid: string): void;
  openPreview(url: string): void;
  t: TFunction;
}

const VideoItem = ({
  singleVideo,
  deleteVideo,
  openUpdateDrawer,
  t,
}: VideoItemProps) => {
  const editVideoItem = () => {
    openUpdateDrawer(singleVideo.uuid);
  };

  const deleteVideoItem = () => {
    deleteVideo(singleVideo.uuid);
  };

  return (
    <div className={cc([cm.videoCard])} key={singleVideo.uuid}>
      <VastPlayer
        vastURL={singleVideo?.vast_tag || ""}
        type="form"
        id={singleVideo?.uuid}
      />

      <div className={cc(["flex", cm.videoCardMeta])}>
        <VideoFilled className={cm.videoIcon} />
        <RetailText
          weight="bold"
          className={cc(["flex", cm.videoCardMetaText])}
        >
          {t("components.campaignForm.firstStep.videoAd")}
        </RetailText>
      </div>
      <div className={cm.videoNameContainer}>
        <Trans>
          <RetailText size="xxxs" weight="medium" className={cm.creativeName}>
            {t("components.campaignForm.firstStep.creativeName", {
              value: singleVideo?.creative_name,
            })}
          </RetailText>
        </Trans>
      </div>
      <div className={cm.videoCardFooter}>
        <div className={cm.videoCardIcon} onClick={editVideoItem}>
          <EditOutlined className={cm.editIcon} />
        </div>
        <div className={cm.videoCardIcon} onClick={deleteVideoItem}>
          <DeleteOutlined className={cm.delete} />
        </div>
      </div>
    </div>
  );
};

export interface CreativeStepProps {
  targeting: string;
  error: boolean;
}

const VideoStep = ({ error, targeting }: CreativeStepProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { video, setVideo, deleteVideo } = useContext(
    CreativeContext
  ) as Creative;

  const [firstDrawerStates, setFirstDrawerStates] = useState({
    visible: false,
    selected: "",
  });

  const [secondDrawerStates, setSecondDrawerStates] = useState<{
    visible: boolean;
    video_name: string;
    showVideo: boolean;
    isFormSubmitted: boolean;
    isLoading: boolean;
    vastTag?: string;
  }>({
    visible: false,
    video_name: "",
    showVideo: false,
    isFormSubmitted: false,
    isLoading: false,
    vastTag: "",
  });

  const [updateDrawerStates, setUpdateDrawerStates] = useState({
    visible: false,
    uuid: "",
    video_name: "",
  });

  const [previewStates, setPreviewStates] = useState({
    visible: false,
    url: "",
  });

  const [form] = useForm();

  const open = (url: string) => setPreviewStates({ visible: true, url });

  const close = () => setPreviewStates({ visible: false, url: "" });

  const mainImgFile = useRef<File>();

  const props: UploadProps = {
    name: "files",
    multiple: false,
    disabled: secondDrawerStates.isLoading,
    className: cc(["dragger", cm.videoUploadDragger]),
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) => handleFileChange(f),
    accept: ".mp4",
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;
    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("video", file.current!);
    formData.append("name", file?.current?.name.replace(/\.[^/.]+$/, "") || "");
    formData.append("status", "ACTIVE");
    formData.append("format", "VIDEO");

    const config = {
      video: formData.get("video"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
      }),
    };
    try {
      setSecondDrawerStates({ ...secondDrawerStates, isLoading: true });
      await api
        .post("/creatives", config, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          setVideo((prev: Video[]) => [
            ...prev,
            {
              url: response.data.image_url,
              size: response.data.size,
              creative_name: response.data.creative_name,
              uuid: response.data.id,
            },
          ]);
          setSecondDrawerStates({
            ...secondDrawerStates,
            showVideo: true,
            isLoading: false,
          });
        });
    } catch (error) {
      console.log(error);
      setSecondDrawerStates({
        ...secondDrawerStates,
        isLoading: false,
      });
    }
  };

  const creativeSuccess = () => {
    return RetailNotification.showNotification(
      "success",
      "",
      t("components.notification.creativeUpload"),
      "bottomLeft"
    );
  };

  const postVideoWithVast = async () => {
    const formData = new FormData();
    formData.append("status", "ACTIVE");
    formData.append("format", "VIDEO");
    formData.append("name", secondDrawerStates.video_name);
    formData.append("vast_tag", secondDrawerStates.vastTag!);

    const config = {
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        vast_tag: formData.get("vast_tag"),
      }),
    };

    await api
      .post("/creatives", config, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const video = document.createElement("video");
        video.src = response.data.image_url;

        setVideo((prev: Video[]) => [
          ...prev,
          {
            url: response.data.image_url,
            size: response.data.size,
            creative_name: response.data.creative_name,
            uuid: response.data.id,
            size_valid: response.data.size_valid,
            vast_tag: response.data.vast_tag,
          },
        ]);
        completeUpload();
        creativeSuccess();
      });
  };

  const openFirstDrawer = () =>
    setFirstDrawerStates({ visible: true, selected: "" });

  const closeFirstDrawer = () => {
    setFirstDrawerStates({ visible: false, selected: "" });
  };

  const openSecondDrawer = () =>
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: true,
      video_name: "",
      showVideo: false,
    });

  const closeSecondDrawer = () => {
    closeFirstDrawer();
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: false,
      video_name: "",
      showVideo: false,
    });
  };

  const completeUpload = () => {
    closeFirstDrawer();
    setSecondDrawerStates({
      visible: false,
      video_name: "",
      showVideo: false,
      isLoading: false,
      isFormSubmitted: true,
    });
  };

  const completeUpdate = () => {
    setUpdateDrawerStates({
      visible: false,
      uuid: "",
      video_name: "",
    });
  };

  const changeVideoUploadType = ({ target }: RadioChangeEvent) => {
    setFirstDrawerStates({ ...firstDrawerStates, selected: target.value });
  };

  const openUpdateDrawer = (uuid: string) => {
    setUpdateDrawerStates({
      visible: true,
      uuid,
      video_name: video.find((v) => v.uuid === uuid)?.creative_name || "",
    });
  };

  const changeShowVideo = () => {
    deleteVideo(video[video.length - 1].uuid);
    setSecondDrawerStates({ ...secondDrawerStates, showVideo: false });
  };

  const updateCreativeName = async () => {
    const isSelectedVideo = updateDrawerStates.uuid !== "";

    const uploadedVideoID = video[video.length - 1]?.uuid;

    const currentVideoID = isSelectedVideo
      ? updateDrawerStates.uuid
      : uploadedVideoID;

    try {
      const response = await api
        .patch(`/creatives/${currentVideoID}`, {
          name: form.getFieldValue("video_name"),
        })
        .then((response) => {
          setVideo((allVideos: Video[]) =>
            allVideos.map((obj: Video) => {
              if (obj.uuid === currentVideoID) {
                return { ...obj, creative_name: response.data.creative_name };
              }
              return obj;
            })
          );
        });
      creativeSuccess();
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      isSelectedVideo ? completeUpdate() : completeUpload();
    }
  };

  return (
    <div className={cm.videoFromRow}>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: targeting === "AUTO_TARGETING" ? "6" : "7",
            title: t("components.campaignForm.firstStep.creativeTitle"),
            text: t("components.campaignForm.firstStep.videoText"),
          }}
        />
        <div
          className={cc([
            cm.creativeSideContainer,
            error && video.length === 0 ? cm.errorBorder : "",
          ])}
        >
          <VideoUploadBackground className={cm.videoBackgroundIcon} />
          <CreativeIcon className={cm.creativeIcon} />
          <RetailTitle level={5} className={cm.creativeSideTitle}>
            {t("components.campaignForm.firstStep.videoSideTitle")}
          </RetailTitle>
          <RetailText
            size="xxs"
            weight="medium"
            className={cm.creativeSideText}
          >
            {t("components.campaignForm.firstStep.videoSideText")}
          </RetailText>
          <RetailMainButton
            isPrimaryBorder={true}
            className={cm.creativeSideButton}
            onClick={openFirstDrawer}
          >
            {t("common.add")}
          </RetailMainButton>
        </div>
      </RetailFormRow>
      {secondDrawerStates.isFormSubmitted && video?.length > 0 && (
        <div className={cm.videoCardWrapper}>
          <section className={cm.videoCardContainer}>
            {video.map((v) => (
              <VideoItem
                singleVideo={v}
                deleteVideo={deleteVideo}
                openUpdateDrawer={openUpdateDrawer}
                openPreview={open}
                t={t}
              />
            ))}
          </section>
        </div>
      )}
      <RetailDrawer
        visible={firstDrawerStates.visible}
        onOk={openSecondDrawer}
        onClose={closeFirstDrawer}
        width={820}
        title={t("components.campaignForm.firstStep.firstDrawerTitle")}
        type="steps"
        disableOk={firstDrawerStates.selected === ""}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeTypeTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeTypeText")}
        </RetailText>
        <Radio.Group onChange={changeVideoUploadType}>
          <Radio value="UPLOAD" className={cc(["form-radio", cm.videoRadio])}>
            <div className={cm.videoRadioInner}>
              <VideoUploadFilled
                className={
                  firstDrawerStates.selected === "UPLOAD"
                    ? cm.selectedIcon
                    : cm.regularIcon
                }
              />
              <article>
                <RetailTitle
                  level={5}
                  noMargin
                  className="videoRadioInnerTitle"
                >
                  {t("components.campaignForm.firstStep.mp4Title")}
                </RetailTitle>
                <RetailText className={cm.drawerRadioText} size="xxxs">
                  {t("components.campaignForm.firstStep.mp4Text")}
                </RetailText>
              </article>
            </div>
          </Radio>
          <Radio value="VAST" className={cc(["form-radio", cm.videoRadio])}>
            <div className={cm.videoRadioInner}>
              <CodeUploadFilled
                className={
                  firstDrawerStates.selected === "VAST"
                    ? cm.selectedIcon
                    : cm.regularIcon
                }
              />
              <article>
                <RetailTitle
                  level={5}
                  noMargin
                  className="videoRadioInnerTitle"
                >
                  {t("components.campaignForm.firstStep.vastTitle")}
                </RetailTitle>
                <RetailText size="xxxs" className={cm.drawerRadioText}>
                  {t("components.campaignForm.firstStep.vastText")}
                </RetailText>
              </article>
            </div>
          </Radio>
        </Radio.Group>
      </RetailDrawer>

      <RetailDrawer
        title={t("components.campaignForm.firstStep.secondDrawerTitle")}
        visible={secondDrawerStates.visible}
        onOk={form.submit}
        onClose={closeSecondDrawer}
        width={772}
        type="add"
        disableOk={
          secondDrawerStates.video_name === "" || !secondDrawerStates.showVideo
        }
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeNameTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeNameText")}
        </RetailText>
        <CreativeNameForm
          onFinish={
            firstDrawerStates.selected === "VAST"
              ? postVideoWithVast
              : updateCreativeName
          }
          form={form}
          value={secondDrawerStates.video_name}
          onChange={(e) =>
            setSecondDrawerStates({
              ...secondDrawerStates,
              video_name: e.target.value,
            })
          }
          t={t}
        />
        <RetailTitle level={5} noMargin>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}`
          )}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}Drawer`
          )}
        </RetailText>
        {firstDrawerStates.selected === "UPLOAD" ? (
          <SecondDrawerUploadInner
            showVideo={secondDrawerStates.showVideo}
            video={video}
            changeShowVideo={changeShowVideo}
            t={t}
            {...props}
          />
        ) : (
          <SecondDrawerVastInner
            t={t}
            onChange={(e) =>
              setSecondDrawerStates({
                ...secondDrawerStates,
                vastTag: e.target.value,
                showVideo: e.target.value !== "",
              })
            }
          />
        )}
      </RetailDrawer>

      <RetailDrawer
        title={t("components.campaignForm.firstStep.updateDrawerTitle")}
        visible={updateDrawerStates.visible}
        onOk={form.submit}
        onClose={completeUpdate}
        mode="update"
        width={772}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeNameTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeNameText")}
        </RetailText>
        <CreativeNameForm
          onFinish={updateCreativeName}
          form={form}
          value={updateDrawerStates.video_name}
          onChange={(e) =>
            setUpdateDrawerStates({
              ...updateDrawerStates,
              video_name: e.target.value,
            })
          }
          t={t}
        />
      </RetailDrawer>

      <CreativePreviewModal
        type="video"
        url={previewStates.url}
        visible={previewStates.visible}
        onCancel={close}
      />
    </div>
  );
};

export default VideoStep;
