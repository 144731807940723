import {
	Checkbox,
	Input,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import cc from "classcat";
import {
	useContext,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import useApi from "../../../../../../api";
import {
	Creative,
	CreativeContext,
} from "../../../../../../context/CreativeProvider";
import { switchFlag } from "../../../../../../utils/helpers";
import { Img } from "../../../../../../utils/types";
import RetailSearchBar from "../../../../../Bar/RetailSearchBar";
import RetailDrawer from "../../../../../Drawer/RetailDrawer";
import CreativePreviewModal from "../../../../../Modal/CreativePreviewModal";
import RetailNotification from "../../../../../Notification";
import RetailText from "../../../../../Typography/RetailText";
import RetailFormInput from "../../../../RetailFormInput";
import CreativeActions from "../CreativeActions";
import cm from "./style.module.scss";

interface CreativeContainerProps {
  item: Img;
  languages: string[];
}

const CreativeContainer = ({ item, languages }: CreativeContainerProps) => {
  const { t } = useTranslation();

  const { size_valid, uuid, url, size, creative_name } = item;

  const { api } = useApi();

  const { setImg, deleteCreative } = useContext(CreativeContext) as Creative;

  const [form] = useForm();

  const [visible, setVisible] = useState(false);

  const [languageDrawerVisible, setLanguageDrawerVisible] = useState(false);

  const [selectedLanguages, setSelectedLanguages] = useState<
    CheckboxValueType[]
  >(item?.languages || []);

  const [indeterminate, setIndeterminate] = useState(false);

  const [checkAll, setCheckAll] = useState(
    selectedLanguages?.length === languages?.length
  );

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(target.value);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedLanguages(e.target.checked ? languages : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const creativeNameRef = useRef("");

  const uuidRef = useRef("");

  const openCreativePreview = () => setVisible(true);

  const closeCreativePreview = () => setVisible(false);

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedLanguages(checkedValues);
    setIndeterminate(
      !!checkedValues.length && checkedValues.length < languages?.length
    );
    setCheckAll(checkedValues.length === languages?.length);
  };

  const updateCreative = async (
    key: string,
    value: string | CheckboxValueType[]
  ) => {
    const response = await api
      .patch(`/creatives/${uuid}`, {
        [key]: value,
      })
      .then((response) => {
        setImg((current: Img[]) =>
          current.map((obj: Img) => {
            if (obj.uuid === uuid) {
              return {
                ...obj,
                name: response.data?.name,
                languages: response.data?.languages,
              };
            }
            return obj;
          })
        );
        setSelectedLanguages(response.data?.languages);
      });

    return response;
  };

  const closeDrawer = () => {
    setLanguageDrawerVisible(false);
    setCheckAll(selectedLanguages?.length === languages?.length);
    setIndeterminate(
      languages?.length > (item?.languages?.length || selectedLanguages?.length)
    );
    setSelectedLanguages(item?.languages || []);
  };

  const handleOk = async () => {
    try {
      await updateCreative("languages", selectedLanguages);
    } catch (error) {
      console.error(error);
      RetailNotification.showNotification(
        "error",
        "",
        t("components.campaignForm.firstStep.errorStates.generalErr")
      );
    } finally {
      setLanguageDrawerVisible(false);
    }
  };

  const filteredLanguages = languages?.filter((item) => {
    const localizedLanguage = t(`common.languages.${item?.toLowerCase()}`);
    return localizedLanguage.toLowerCase().includes(searchValue);
  });

  return (
    <div
      className={cc([
        "flex",
        size_valid ? "" : cm.validError,
        cm.creativeContainer,
      ])}
      key={uuid}
    >
      <div className="flex">
        <img src={url} alt={creative_name} />
      </div>
      <div>
        <Form
          onFinish={() => updateCreative("name", creativeNameRef.current)}
          form={form}
          autoComplete="off"
          requiredMark={false}
          initialValues={{ [`${uuid}_name`]: creative_name }}
        >
          <RetailFormInput
            isFocused={creative_name !== ""}
            label={t("components.campaignForm.firstStep.creativeLabel")}
            className="floating"
            name={`${uuid}_name`}
            rules={[
              {
                required: true,
                message: t("components.campaignForm.firstStep.creativeError"),
              },
            ]}
          >
            <Input
              className="floating"
              data-test="campaign-form-creative-name"
              onBlur={() => {
                uuidRef.current = uuid;
                creativeNameRef.current = form.getFieldValue(`${uuid}_name`);
                form.submit();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </RetailFormInput>
        </Form>

        <RetailText
          weight="medium"
          size="xs"
          className={cc(["flex", cm.creativeContainerText])}
        >
          <span>{t("components.campaignForm.firstStep.size")}</span>
          <span className={cm.size}>{size}</span>
        </RetailText>
        <CreativeActions
          item={item}
          isLanguageSelectable={languages?.length > 0}
          selectedLanguages={selectedLanguages}
          open={openCreativePreview}
          setLanguageDrawerVisible={setLanguageDrawerVisible}
          deleteCreative={deleteCreative}
        />
      </div>

      <CreativePreviewModal
        type="creative"
        url={url}
        visible={visible}
        onCancel={closeCreativePreview}
      />

      {languages?.length > 0 && (
        <RetailDrawer
          visible={languageDrawerVisible}
          title={t("components.campaignForm.firstStep.languageTitle")}
          onOk={handleOk}
          onClose={closeDrawer}
          disableOk={selectedLanguages?.length === 0}
          okText={t("common.apply")}
        >
          <RetailSearchBar
            placeholder={t("components.campaignForm.firstStep.languageSearch")}
            onChange={handleSearch}
            value={searchValue}
          />
          <div className={cm.languageCheckboxWrapper}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              value={checkAll}
              className={cm.creativeLanguageItem}
              defaultChecked={true}
            >
              <RetailText size="xs">{t("common.selectAll")}</RetailText>
            </Checkbox>
            <Checkbox.Group
              className={cm.creativeLanguages}
              defaultValue={selectedLanguages}
              value={selectedLanguages}
              onChange={handleChange}
            >
              {filteredLanguages?.map((item) => (
                <Checkbox
                  value={item}
                  key={item}
                  className={cm.creativeLanguageItem}
                >
                  <div className="flex">
                    <RetailText size="xs">
                      {t(`common.languages.${item?.toLowerCase()}`)}
                    </RetailText>
                    <RetailText size="xxs" weight="medium">
                      {switchFlag(item)}
                    </RetailText>
                  </div>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
        </RetailDrawer>
      )}
    </div>
  );
};

export default CreativeContainer;
