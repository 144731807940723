import { Img } from "../../../../../utils/types";
import { ReactComponent as UploadedIcon } from "../../../../../assets/icons/uploadedOutlined.svg";
import { DeleteOutlined } from "@ant-design/icons";

import RetailText from "../../../../../components/Typography/RetailText";
import cm from "./style.module.scss";

export interface OnboardingUploadedBannerProps {
  img: Img;
  onClick: () => void;
}

const OnboardingUploadedBanner = ({
  img,
  onClick,
}: OnboardingUploadedBannerProps) => {
  return (
    <section className={`flex ${cm.uploadedContainer}`}>
      <UploadedIcon />
      <article>
        <RetailText
          size="xs"
          weight="bold"
          className={cm.imgName}
        >{`${img?.creative_name}`}</RetailText>
        <RetailText size="xxxs" weight="medium" className={cm.imgSize}>
          {img?.size}
        </RetailText>
      </article>
      <div className={`flex ${cm.deleteContainer}`} onClick={onClick}>
        <DeleteOutlined />
      </div>
    </section>
  );
};

export default OnboardingUploadedBanner;
