import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import moment from "moment";
import { useTranslation } from "react-i18next";

import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import cm from "../style.module.scss";

export interface ReportDetailsInfoTabProps {
  data: any;
}

const ReportDetailsInfoTab = ({ data }: ReportDetailsInfoTabProps) => {
  const { t, i18n } = useTranslation();

  const selectedFilterExist = (key: string) =>
    i18n.exists(`common.filters.${key}`);

  const renderFormattedFilter = (key: string) =>
    selectedFilterExist(key)
      ? t(`common.filters.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  const isMetricExist = (key: string) =>
    i18n.exists(`components.reportForm.metric.${key}`);
    
  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`components.reportForm.metric.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  return (
    <Row className={cm.row}>
      <Col className={cm.col}>
        <section>
          <RetailTitle level={4} className={cm.mainTitle}>
            {t("components.reportForm.reportTypeTitle")}
          </RetailTitle>
          <RetailText family="poppins" weight="medium" size="xs">
            {data?.data.reportType &&
              t(
                `pages.acc.reportDetails.${data?.data.report_type.toLowerCase()}`
              )}
          </RetailText>
        </section>
        {data?.data.report_type === "STATIC" ? (
          <>
            <section>
              <RetailTitle level={5}>
                {t("pages.acc.reportDetails.startDate")}
              </RetailTitle>
              <RetailText
                family="poppins"
                weight="medium"
                size="xs"
                className={cm.secondaryText}
              >
                {moment(data?.data.report_start_date).format("DD/MM/YYYY")}
              </RetailText>
            </section>
            <section>
              <RetailTitle level={5}>
                {t("pages.acc.reportDetails.endDate")}
              </RetailTitle>
              <RetailText
                family="poppins"
                weight="medium"
                size="xs"
                className={cm.secondaryText}
              >
                {data?.data.report_end_date === "0001-01-01"
                  ? "-"
                  : moment(data?.data.report_end_date).format("DD/MM/YYYY")}
              </RetailText>
            </section>
          </>
        ) : (
          <>
            <section>
              <RetailTitle level={5}>
                {t("pages.acc.reportDetails.frequency")}
              </RetailTitle>
              <RetailText
                family="poppins"
                weight="medium"
                size="xs"
                className={cm.secondaryText}
              >
                {data?.data.report_delivery_frequency
                  ? t(
                      `components.reportForm.${data?.data.report_delivery_frequency.toLowerCase()}`
                    )
                  : "-"}
              </RetailText>
            </section>
            <section>
              <RetailTitle level={5}>
                {t("pages.acc.reportDetails.firstDate")}
              </RetailTitle>
              <RetailText
                family="poppins"
                weight="medium"
                size="xs"
                className={cm.secondaryText}
              >
                {data?.data.report_scheduling_start_date
                  ? moment(data?.data.report_scheduling_start_date).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </RetailText>
            </section>
            <section>
              <RetailTitle level={5}>
                {t("pages.acc.reportDetails.emails")}
              </RetailTitle>
              {data?.data.report_emails ? (
                data?.data.report_emails.map((item: string) => (
                  <RetailText
                    family="poppins"
                    weight="medium"
                    size="xs"
                    className={cm.secondaryText}
                    key={item}
                  >
                    {item}
                  </RetailText>
                ))
              ) : (
                <RetailText
                  family="poppins"
                  weight="medium"
                  size="xs"
                  className={cm.secondaryText}
                >
                  -
                </RetailText>
              )}
            </section>
            <section>
              <RetailTitle level={5}>
                {t("pages.acc.reportDetails.export")}
              </RetailTitle>

              <RetailText
                family="poppins"
                weight="medium"
                size="xs"
                className={cm.secondaryText}
              >
                {data?.data.report_export_format
                  ? `.${data?.data.report_export_format}`
                  : "-"}
              </RetailText>
            </section>
          </>
        )}
      </Col>
      <Col className={cm.col}>
        <section>
          <RetailTitle level={4} className={cm.mainTitle}>
            {t("pages.acc.reports.table.type")}
          </RetailTitle>
          <RetailText family="poppins" weight="medium" size="xs">
            {!data?.data.report_predefined_type
              ? t("components.reportForm.special")
              : t(`pages.acc.reportDetails.ready`)}
          </RetailText>
        </section>
        {data?.data.report_predefined_type && (
          <>
            <section>
              <RetailTitle level={5} className={cm.mainTitle}>
                {t("pages.acc.reportDetails.predefined")}
              </RetailTitle>
              <RetailText
                family="poppins"
                weight="medium"
                size="xs"
                className={cm.secondaryText}
              >
                {t(
                  `components.reportForm.${data?.data.report_predefined_type.toLowerCase()}`
                )}
              </RetailText>
            </section>
          </>
        )}
        {!data?.data.report_predefined_type && data?.data.report_dimensions && (
          <section>
            <RetailTitle level={5} className={cm.mainTitle}>
              {t("components.reportForm.breaks")}
            </RetailTitle>
            <div className="flex">
              {data?.data.report_dimensions.map((item: any) => (
                <RetailText family="poppins" weight="medium" size="xs">
                  {t(`components.reportForm.dimensions.${item}`)}
                </RetailText>
              ))}
            </div>
          </section>
        )}
        {!data?.data.report_predefined_type && data?.data.report_metrics && (
          <section>
            <RetailTitle level={5} className={cm.mainTitle}>
              {t("components.reportForm.metrics")}
            </RetailTitle>
            <div className="flex">
              {data?.data.report_metrics.map((item: any) => (
                <RetailText family="poppins" weight="medium" size="xs">
                  {renderMetric(item)}
                </RetailText>
              ))}
            </div>
          </section>
        )}
        {!data?.data.report_predefined_type && data?.data.report_filters && (
          <section>
            <RetailTitle level={5} className={cm.mainTitle}>
              {t("components.reportForm.filters")}
            </RetailTitle>
            <div className="flex">
              {data?.data.report_filters.map((tag: any) => (
                <RetailText
                  style={{ maxWidth: 200, flexWrap: "wrap" }}
                  family="poppins"
                  weight="medium"
                  size="xs"
                  className={cc(["flex"])}
                >
                  {`${renderFormattedFilter(tag.key)} /
                    ${t(`common.equalityValues.${tag.op}`)} /
                    ${
                      i18n.exists(`common.values.${tag.value}`)
                        ? t(`common.values.${tag.value}`)
                        : tag.key.includes("Date")
                        ? moment(tag.value).format("DD/MM/YY")
                        : tag.value
                    }`}
                </RetailText>
              ))}
            </div>
          </section>
        )}
        {data?.data.report_predefined_type && <section></section>}
      </Col>
    </Row>
  );
};

export default ReportDetailsInfoTab;
