import Switch from "antd/lib/switch";
import { useTranslation } from "react-i18next";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import { BidTypes } from "../../../../utils/types";

import cm from "../style.module.scss";

export interface BidContainerProps {
  type: BidTypes;
  formFields: any;
  setFormFields(item: any): void;
}

export const CampaignSettingsBidContainer = ({
  type,
  formFields,
  setFormFields,
}: BidContainerProps) => {
  const { t } = useTranslation();

  const handleBidChange = (value: boolean, type: BidTypes) => {
    if (value) {
      setFormFields((prev: any) => ({
        ...prev,
        available_bidding_types: [...formFields.available_bidding_types, type],
      }));
    } else {
      setFormFields((prev: any) => ({
        ...prev,
        available_bidding_types: formFields.available_bidding_types.filter(
          (item: string) => item !== type
        ),
      }));
    }
  };

  const isChecked = formFields.available_bidding_types?.indexOf(type) !== -1;

  const switchText = t(`common.table.${isChecked ? "open" : "closed"}`);

  return (
    <div className={cm.flex}>
      <div>
        <RetailTitle level={5} className={cm.title}>
          {t(`pages.admin.campaignSettings.${type.toLowerCase()}`)}
        </RetailTitle>
        <RetailText size="xxxs" family="poppins" className={cm.secondaryText}>
          {t(`pages.admin.campaignSettings.${type.toLowerCase()}Text`)}
        </RetailText>
      </div>
      <div className="flex">
        <Switch
          checked={isChecked}
          onChange={(value) => handleBidChange(value, type)}
        />
        <RetailText size="xs" family="poppins">
          {switchText}
        </RetailText>
      </div>
    </div>
  );
};
