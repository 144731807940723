import React from "react";
import Tag, { TagProps } from "antd/lib/tag";
import cc from "classcat";
import cm from "./style.module.scss";
import { ReactComponent as CloseTagOutlined } from "../../../assets/icons/closeTagOutlined.svg";
import { FilterTag } from "../../../utils/types";

export interface RetailInfoTagProps extends TagProps {
  type: FilterTag;
}

const RetailInfoTag = ({
  type,
  children,
  className,
  ...tagProps
}: RetailInfoTagProps) => {
  return (
    <Tag
      className={cc([cm.tag, cm[type.toLowerCase()], className || ""])}
      closable
      closeIcon={<CloseTagOutlined />}
      {...tagProps}
    >
      {children}
    </Tag>
  );
};

export default RetailInfoTag;
