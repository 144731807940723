const appier_kr = {
  marketplaceDependentSettings: {
    api_link: "",
    auto_impression_link: "",
    auction_link: "",
    product_link: "",
    stock_link: "",
    targeting_link: "",
    bidding_link: "",
  },
};

export default appier_kr;
