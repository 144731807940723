import axios, { AxiosInstance } from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Auth, AuthContext } from "../context/AuthProvider";
import { getLanguageCode } from "../utils/helpers";

const useApi = () => {
  const { i18n } = useTranslation();

  const { authToken, authInfo, logout, accountIndex, advValue } = useContext(
    AuthContext
  ) as Auth;

  const location = useLocation();

  const navigate = useNavigate();

  const role = authInfo !== null ? authInfo[accountIndex]?.account_type : "";

  const isLimitedUser =
    authInfo !== null
      ? authInfo[accountIndex]?.role === "MARKETPLACE_REPORTER"
      : false;

  const id = authInfo !== null ? authInfo[accountIndex]?.resource_id : "";

  const advertiser = location.state as { id: number };

  const admin =
    location.pathname.includes("admin") &&
    location.pathname !== "/admin/create-campaign";

  const adminInGeneral = location.pathname.includes("admin");

  const baseURL = () => {
    if (role === "" || role === undefined) return;
    if (role === "ADVERTISER" && !admin) return `/api/advertisers/${id}`;
    if (advertiser && advertiser.id !== undefined)
      return `/api/advertisers/${advertiser.id}`;
    if (role === "MARKETPLACE" && admin) return `/api/marketplaces/${id}`;
    if (role === "MARKETPLACE" && !admin && advValue)
      return `/api/advertisers/${advValue}`;
    else return `/api/advertisers/${advValue || id}`;
  };

  const roleForDashboard: "MARKETPLACE" | "ADVERTISER" = baseURL()?.includes(
    "marketplace"
  )
    ? "MARKETPLACE"
    : "ADVERTISER";

  const api: AxiosInstance = axios.create({
    baseURL: baseURL(),
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Accept-Language": getLanguageCode(i18n.language),
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 405) {
        logout();
        navigate("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );

  return {
    api,
    baseURL,
    adminInGeneral,
    id,
    roleForDashboard,
    role,
    isLimitedUser,
  };
};

export default useApi;
