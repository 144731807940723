import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getLanguageCode } from "../../utils/helpers";

const useOnboardingApi = () => {
  const { i18n } = useTranslation();

  const [credentials, setCredentials] = useState<{
    authToken: string | null;
    marketplaceID: string | null;
  }>({
    authToken: null,
    marketplaceID: null,
  });

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const id = localStorage.getItem("marketplace_id");

    setCredentials({
      authToken: token ? JSON.parse(token) : null,
      marketplaceID: id ? JSON.parse(id) : null,
    });
  }, []);

  const getOnboardingApi = useCallback(() => {
    const { authToken, marketplaceID } = credentials;
    if (!authToken || !marketplaceID) return null;

    return axios.create({
      baseURL: `api/onboarding/marketplaces/${marketplaceID}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Accept-Language": getLanguageCode(i18n.language),
      },
    });
  }, [credentials, i18n.language]);

  return { onboardingApi: getOnboardingApi() };
};

export default useOnboardingApi;
